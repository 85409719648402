import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App.tsx";
import { SnackbarProvider } from "notistack";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { ThemeProvider } from "@mui/material";
import { theme } from "@safelease/components";
import * as Sentry from "@sentry/react";
import { Amplify } from "aws-amplify";
import { appConfig } from "./config/app.config.ts";

/**
 * This is the main entry point for the application.
 * It sets up the Amplify configuration so that the app can use AWS cognito services to manage user authentication.
 * It also sets up the Sentry configuration for error tracking.
 */
Amplify.configure({
  Auth: {
    Cognito: {
      userPoolClientId: appConfig.aws.cognitoAppClientId,
      userPoolId: appConfig.aws.cognitoUserPoolId,
    },
  },
});

if (window.location.hostname == "tenant.safelease.com") {
  Sentry.init({
    dsn: "https://32c4c47eed1cb8789d1e722660515fdb@o1109446.ingest.us.sentry.io/4507426430648320",
    environment: appConfig.env,
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    replaysSessionSampleRate: 1.0, // Capture 100% of the sessions
    replaysOnErrorSampleRate: 1.0, // Capture 100% of the errors
  });
}

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <SnackbarProvider maxSnack={3} anchorOrigin={{ vertical: "top", horizontal: "right" }}>
        <BrowserRouter>
          <ThemeProvider theme={theme}>
            <App />
          </ThemeProvider>
        </BrowserRouter>
      </SnackbarProvider>
    </LocalizationProvider>
  </React.StrictMode>,
);
