import { Navigate, Route, Routes } from "react-router-dom";
import { MainDashboardRouter } from "./MainDashboardRouter";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import PageNotFound from "./MainDashboardRouter/PageNotFound";
import { AuthenticationPage } from "./AuthenticationPage";
import { useEffect, useState } from "react";
import { UserAttributes, useAuthStore } from "./hooks/useAuthStore";
import { fetchUserAttributes, getCurrentUser } from "aws-amplify/auth";
import { Hub } from "aws-amplify/utils";
import { Box, LinearProgress } from "@mui/material";

function App() {
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const queryClient = new QueryClient();
  const setUserAttributes = useAuthStore((state) => state.setUserAttributes);

  useEffect(() => {
    const setAuthStatus = async () => {
      setIsLoading(true);
      try {
        const { username } = await getCurrentUser(); // throws an error if not authenticated
        setIsAuthenticated(!!username);
        const userAttributes = await fetchUserAttributes();
        const sanitizedUserAttributes: any = {};
        for (const [key, val] of Object.entries(userAttributes)) {
          // any custom attributes are prefixed with "custom:" so we remove that prefix
          sanitizedUserAttributes[key.startsWith("custom:") ? key.substring(7) : key] = val as string;
        }
        setUserAttributes(sanitizedUserAttributes as UserAttributes);
      } catch (error) {
        setIsAuthenticated(false);
      }
      setIsLoading(false);
    };
    const hubListenerCancelToken = Hub.listen("auth", (data) => {
      if (data.payload.event === "signedIn" || data.payload.event === "signedOut") {
        if (data.payload.event === "signedOut") {
          setIsLoading(true);
        }
        setAuthStatus();
      }
    });

    setAuthStatus();
    return () => {
      hubListenerCancelToken();
    };
  }, []);

  if (isLoading) {
    return (
      <Box sx={{ width: "100vw", height: "100vh" }}>
        <LinearProgress />
      </Box>
    );
  }

  if (isAuthenticated) {
    return (
      <QueryClientProvider client={queryClient}>
        <Routes>
          <Route path="/404" element={<PageNotFound />} />
          <Route path="/*" element={<MainDashboardRouter />} />
        </Routes>
      </QueryClientProvider>
    );
  } else if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <Routes>
      <Route path="/" element={<AuthenticationPage />} />
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
}

export default App;
