import { Typography, Accordion, AccordionSummary, AccordionDetails, Stack } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useState } from "react";
import { FormQuestionDef_ClaimsDbModel } from "@safelease/service-utilities";

type ResponseDisplayProps = {
  question: FormQuestionDef_ClaimsDbModel;
};

function ResponseDisplay({ question }: ResponseDisplayProps) {
  const [expandedResponse, setExpandedResponse] = useState(false);

  const response = question.formQuestionResponses?.[0]?.response;
  if (!response) return null;

  if (response.length > 100) {
    return (
      <Accordion
        expanded={expandedResponse}
        onChange={(_, isExpanded) => setExpandedResponse(isExpanded)}
        sx={{ marginLeft: 5, marginTop: 1, border: "1px solid #d3d3d3" }}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ paddingLeft: 1 }}>
          <Stack direction="row" alignItems="center" marginLeft={0.5}>
            <Typography fontSize={12}>{window.innerWidth <= 600 ? response.slice(0, 50) : response.slice(0, 140)}...</Typography>
          </Stack>
        </AccordionSummary>

        <AccordionDetails sx={{ paddingLeft: 1 }}>
          <Typography fontSize={12}>{response}</Typography>
        </AccordionDetails>
      </Accordion>
    );
  }

  return <Typography sx={{ fontSize: ".8rem", ml: 6, mt: 0.5 }}>{response}</Typography>;
}

export { ResponseDisplay };
