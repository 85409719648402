import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import TodoItem from "./TodoItem";
import { Typography } from "@mui/material";
import { GetTenantActionsResponse } from "@safelease/service-utilities";

type TenantActionListViewerProps = {
  open: boolean;
  handleClose: () => void;
  tenantActions: GetTenantActionsResponse[];
};

function TenantActionListViewerModal({ open, handleClose, tenantActions }: TenantActionListViewerProps) {
  return (
    <Modal open={open} onClose={handleClose} sx={{ display: "flex", alignItems: "center", justifyContent: "center", padding: 2 }}>
      <Box
        sx={{
          fontWeight: 600,
          fontSize: 16,
          bgcolor: "white",
          padding: 2,
          borderRadius: 2,
          height: "60vh",
          minWidth: 340,
          overflow: "auto",
        }}
      >
        <Box>
          <Typography sx={{ fontWeight: 500, fontSize: 20, zIndex: 1 }}>Information Needed</Typography>
          {tenantActions.map((tenantAction) => {
            return <TodoItem key={tenantAction.id} tenantAction={tenantAction} />;
          })}
        </Box>
      </Box>
    </Modal>
  );
}

export { TenantActionListViewerModal };
