import { Box, Checkbox, FormControlLabel, Typography } from "@mui/material";

type BorderedCheckboxProps = {
  value: boolean;
  setValue: (value: boolean) => void;
  label: string;
  disabled?: boolean;
};

function BorderedCheckbox({ value, setValue, label, disabled }: BorderedCheckboxProps) {
  return (
    <Box
      sx={{
        width: "99%",
        border: "1px solid #1E2A38",
        borderRadius: 1,
        paddingLeft: 2,
        backgroundColor: value ? "#031E300D" : null,
      }}
    >
      <FormControlLabel
        disabled={disabled}
        sx={{ width: "99%" }}
        control={
          <Checkbox
            sx={{
              color: "navy.main",
              "&.Mui-checked": {
                color: "navy.main",
              },
            }}
            value={value}
            onChange={(e) => setValue(e.target.checked)}
          />
        }
        label={<Typography sx={{ fontSize: 14, fontWeight: 600 }}>{label}</Typography>}
      />
    </Box>
  );
}

export { BorderedCheckbox };
