import { Step, StepLabel, Stepper, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";

type VerticalLinearStepperProps = {
  activeStep: number | undefined;
};

function VerticalLinearStepper({ activeStep }: VerticalLinearStepperProps) {
  const steps = ["Eligibility check", "Claim form", "Finish"];
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Stepper activeStep={activeStep} orientation={isMobile ? "horizontal" : "vertical"}>
      {steps.map((step, index) => (
        <Step key={index}>
          <StepLabel
            componentsProps={{
              label: {
                style: {
                  color: "white",
                  fontSize: ".9rem",
                },
              },
            }}
          >
            {step}
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  );
}

export { VerticalLinearStepper };
