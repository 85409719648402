import { InsertDriveFileOutlined } from "@mui/icons-material";
import { Box, CircularProgress, Stack, SxProps, Theme, Typography } from "@mui/material";
import { useState } from "react";
import { Document, Page } from "react-pdf";

interface ImagePreviewProps {
  file: string;
  fileName: string;
  handleExpandImage: () => void;
  previewContainerSx?: SxProps;
}

/**
 * A thumbnail with a file name / size overlay, and the ability to expand to a full-screen PDF viewer
 */
export function ImagePreview({ file, fileName, handleExpandImage, previewContainerSx }: ImagePreviewProps) {
  // Grabs the file extension, removes any query parameters
  const fileExtension = file?.split(".")?.pop()?.split("?")[0] ?? "";
  const isVideo = ["mp4", "mov", "webm", "ogv"].includes(fileExtension.toLowerCase());
  const videoMimeTypes = {
    mp4: "video/mp4",
    mov: "video/mp4",
    webm: "video/webm",
    ogv: "video/ogg",
  };
  const videoMimeType = videoMimeTypes[fileExtension.toLowerCase() as keyof typeof videoMimeTypes];
  const isPDF = fileExtension.toLowerCase() === "pdf";

  const [isLoading, setIsLoading] = useState<boolean>(isPDF);
  const [loadFailed, setLoadFailed] = useState<boolean>(false);

  // When a document loads successfully, set the number of pages and stop loading
  const onDocumentLoadSuccess = () => {
    setIsLoading(false);
  };

  // When a document fails to load, stop loading
  const onDocumentLoadFailure = () => {
    setIsLoading(false);
    setLoadFailed(true);
  };

  return (
    <Box
      onClick={!isVideo ? handleExpandImage : () => {}}
      sx={{
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "space-between",
        flexDirection: "column",
        position: "relative",
        width: 200,
        height: 200,
        borderRadius: 1,
        overflow: "hidden",
        border: "1px solid",
        borderColor: (theme: Theme) => theme.palette.divider,
        ...(!isVideo && {
          cursor: "zoom-in",
          transition: "150ms ease-in-out",
          "&:hover": {
            boxShadow: "0 0 20px 0 rgba(0,0,0,0.05)",
            borderColor: (theme: Theme) => theme.palette.primary.main,
            filter: "brightness(0.95)",
          },
        }),
        ...previewContainerSx,
      }}
    >
      {!isVideo && <ImagePreviewFileDetailsOverlay fileName={fileName} />}

      {/* Loading state */}
      {isLoading && (
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress size={50} />
        </Box>
      )}

      {/* Display non-PDF */}
      {file && !isPDF && !isVideo && (
        <img
          src={file}
          style={{
            height: "100%",
            width: "100%",
            objectFit: "cover",
          }}
        />
      )}

      {/* Display video */}
      {file && isVideo && (
        <video
          style={{
            height: "100%",
            width: "100%",
          }}
          controls
          muted
        >
          <source src={file} type={videoMimeType} />
          Your browser does not support showing the video.
        </video>
      )}

      {/* Display PDF */}
      {file && isPDF && !loadFailed && (
        <Document
          file={file}
          onLoadSuccess={onDocumentLoadSuccess}
          onLoadError={(error) => {
            console.log({ error });
            onDocumentLoadFailure();
          }}
        >
          <Page pageNumber={1} />
        </Document>
      )}
    </Box>
  );
}

function ImagePreviewFileDetailsOverlay({ fileName }: { fileName: string }) {
  return (
    <Stack
      direction="row"
      alignItems="center"
      spacing={1}
      sx={{
        position: "absolute",
        p: 1,
        bottom: 0,
        left: 0,
        right: 0,
        height: 60,
        zIndex: 999,
        bgcolor: (theme: Theme) => theme.palette.blueGray.main,
        color: (theme: Theme) => theme.palette.navy.main,
      }}
    >
      <InsertDriveFileOutlined />
      <Box sx={{ flex: 1, minWidth: 0 }}>
        <Typography
          variant="body2"
          sx={{
            whiteSpace: "nowrap", // Prevent wrapping
            overflow: "hidden", // Hide overflow
            textOverflow: "ellipsis", // Show ellipsis for overflow
          }}
        >
          {fileName}
        </Typography>
        <Typography variant="caption" color="grey.A100">
          FILE_SIZE
        </Typography>
      </Box>
    </Stack>
  );
}
