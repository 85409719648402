import { Stack, Typography } from "@mui/material";
import TodoItem from "./TodoItem/TodoItem";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { TenantActionListViewerModal } from "./TodoItem/TenantActionListViewerModal";
import { useTodoItemListStore } from "./useTodoItemListStore";

type TodoItemListProps = {};

function TodoItemList({}: TodoItemListProps) {
  const { claimId } = useParams<{ claimId: string }>();
  if (!claimId) return null;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const { tenantActions, tenantActionLength } = useTodoItemListStore((state) => ({
    tenantActions: state.tenantActions,
    tenantActionLength: state.tenantActionLength,
  }));

  useEffect(() => {
    if (tenantActionLength === 0 && isModalOpen) {
      setIsModalOpen(false);
    }
  }, [tenantActionLength, isModalOpen]);

  return (
    <>
      <Stack spacing={0.5} width={"100%"}>
        {/* Renders the Tenant Action in the side Container when screen size is above 600px */}
        <Stack sx={{ display: { xs: "none", sm: "flex" } }}>
          {!!tenantActions?.length &&
            tenantActions.map((tenantAction) => {
              return <TodoItem key={tenantAction.id} tenantAction={tenantAction} />;
            })}
        </Stack>
        {/* Renders the tenant Actions as a number and has a view button when the screen size is less than 600px */}
        <Stack
          direction="row"
          sx={{
            display: { xs: "flex", sm: "none" },
            justifyContent: "space-between",
          }}
          onClick={() => setIsModalOpen(true)}
        >
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: 14,
            }}
          >
            👋🏼 You have {tenantActionLength} {tenantActionLength === 1 ? "request" : "requests"} from your adjuster.
          </Typography>
          {/* Opens modal to show list of todo items when button is clicked.  */}
          <ArrowForwardIosIcon fontSize="small" />
        </Stack>
        <TenantActionListViewerModal tenantActions={tenantActions || []} open={isModalOpen} handleClose={() => setIsModalOpen(false)} />
      </Stack>
    </>
  );
}

export { TodoItemList };
