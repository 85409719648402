import { Box, Stack, useMediaQuery, useTheme } from "@mui/material";
import { SafeleasePrivatePolicy } from "@safelease/service-utilities";
import { PrivatePolicyCard } from "./PrivatePolicyCard.tsx";

type PrivatePolicyListProps = {
  privatePolicies: SafeleasePrivatePolicy[];
};

function PrivatePolicyList({ privatePolicies }: PrivatePolicyListProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Stack
      direction={isMobile ? "column" : "row"}
      spacing={2}
      paddingY={2}
      overflow="auto"
      width={"100%"}
      alignItems={isMobile ? "center" : "flex-start"}
    >
      {privatePolicies.map((privatePolicy) => (
        <Box key={privatePolicy.id}>
          <PrivatePolicyCard privatePolicy={privatePolicy} />
        </Box>
      ))}
    </Stack>
  );
}

export { PrivatePolicyList };
