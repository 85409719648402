import { Box, Stack, useMediaQuery, useTheme } from "@mui/material";
import { ClaimDetailsCard } from "./ClaimDetailsCard";
import { TenantClaimListItem } from "@safelease/service-utilities";

type ClaimListProps = {
  claims: TenantClaimListItem[];
};

function ClaimList({ claims }: ClaimListProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Stack direction="row" spacing={2} paddingY={2} overflow="auto" flex={1} alignItems={isMobile ? "center" : "flex-start"}>
      {claims.map((claim) => (
        <Box key={claim.id}>
          <ClaimDetailsCard claim={claim} />
        </Box>
      ))}
    </Stack>
  );
}

export { ClaimList };
