import { Box, Stack, Tooltip, Typography } from "@mui/material";
import { SafeleasePrivatePolicy } from "@safelease/service-utilities";
import { Circle } from "@mui/icons-material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { getCalculatedPrivatePolicyStatus } from "../utils/helpers/privatePolicy.ts";

interface PrivatePolicyUnitProps {
  privatePolicy: SafeleasePrivatePolicy;
  fontSize?: number;
  hideTooltip?: boolean;
}

export function PrivatePolicyUnit({ privatePolicy, fontSize = 18, hideTooltip = false }: PrivatePolicyUnitProps) {
  const { statusGroup } = getCalculatedPrivatePolicyStatus(privatePolicy);

  return (
    <Stack direction="row" spacing={1} alignItems="center">
      {statusGroup === "active" && <Circle sx={{ color: "rgb(37, 155, 36)", fontSize: fontSize * 0.7 }} />}
      <Typography sx={{ fontWeight: 500, fontSize }}>Unit {privatePolicy.unitName}</Typography>
      {!hideTooltip && (
        <Tooltip title={statusGroup === "active" ? "The policy is verified and active" : "The policy has not been verified"}>
          <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            <ErrorOutlineIcon sx={{ color: "grey", cursor: "pointer", fontSize }} />
          </Box>
        </Tooltip>
      )}
    </Stack>
  );
}
