import { GetSafeleaseLocationForGooglePlaceIdResponse, SafeleaseLeaseMatchingData } from "@safelease/service-utilities";
import { create } from "zustand";

export enum UnitSelectionModalStep {
  FACILITY_LOCATION_SEARCH = "facilityLocationSearch",
  EMAIL_UNIT_LOOKUP = "emailUnitLookup",
  TENANT_NAME_LOOKUP = "tenantNameLookup",
  UNIT_SELECTION = "unitSelection",
}

type UnitSelectionModalStore = {
  currentStep: UnitSelectionModalStep;
  setCurrentStep: (currentStep: UnitSelectionModalStep) => void;
  progress: number;
  setProgress: (progress: number) => void;
  selectedLocation: GetSafeleaseLocationForGooglePlaceIdResponse | null;
  setSelectedLocation: (selectedLocation: GetSafeleaseLocationForGooglePlaceIdResponse | null) => void;
  userProvidedGooglePlaceId: string | null;
  setUserProvidedGooglePlaceId: (userProvidedGooglePlaceId: string | null) => void;
  leaseMatchingData: SafeleaseLeaseMatchingData[] | null;
  setLeaseMatchingData: (leaseMatchingData: SafeleaseLeaseMatchingData[]) => void;
  verifySkipModalOpen: boolean;
  setVerifySkipModalOpen: (verifySkipModalOpen: boolean) => void;
  resetStore: () => void;
};

export const useUnitSelectionModalStore = create<UnitSelectionModalStore>()((set) => ({
  currentStep: UnitSelectionModalStep.FACILITY_LOCATION_SEARCH,
  setCurrentStep: (currentStep) => {
    set({ currentStep });
  },
  progress: 0,
  setProgress: (progress) => {
    set({ progress });
  },
  selectedLocation: null,
  setSelectedLocation: (selectedLocation) => {
    set({ selectedLocation });
  },
  userProvidedGooglePlaceId: null,
  setUserProvidedGooglePlaceId: (userProvidedGooglePlaceId) => {
    set({ userProvidedGooglePlaceId });
  },
  leaseMatchingData: null,
  setLeaseMatchingData: (leaseMatchingData) => {
    set({ leaseMatchingData });
  },
  verifySkipModalOpen: false,
  setVerifySkipModalOpen: (verifySkipModalOpen) => {
    set({ verifySkipModalOpen });
  },
  resetStore: () => {
    set({
      currentStep: UnitSelectionModalStep.FACILITY_LOCATION_SEARCH,
      progress: 0,
      selectedLocation: null,
      userProvidedGooglePlaceId: null,
      leaseMatchingData: null,
      verifySkipModalOpen: false,
    });
  },
}));
