import { Stack } from "@mui/material";
import { SafeLeaseButton, SafeLeaseTextField } from "@safelease/components";
import { useTenantActionModalStore } from "../useTenantActionModalStore";
import { useState } from "react";

type TextResponseSubmissionSectionProps = {
  handleResponseSubmission: (answer: string) => Promise<void>;
  requestLoading: boolean;
};

function TextResponseSubmissionSection({ handleResponseSubmission, requestLoading }: TextResponseSubmissionSectionProps) {
  const resetStore = useTenantActionModalStore((state) => state.resetStore);
  const [userInputText, setUserInputText] = useState<string>("");

  return (
    <>
      <SafeLeaseTextField value={userInputText} onChange={(e) => setUserInputText(e.target.value)} multiline maxRows={6} />
      <Stack direction="row" justifyContent="flex-end" spacing={2}>
        <SafeLeaseButton variant="outlined" color="navy" onClick={() => resetStore()}>
          Cancel
        </SafeLeaseButton>
        <SafeLeaseButton
          loading={requestLoading}
          disabled={!userInputText.trim()}
          variant="contained"
          color="navy"
          onClick={() => handleResponseSubmission(userInputText)}
        >
          Submit
        </SafeLeaseButton>
      </Stack>
    </>
  );
}

export { TextResponseSubmissionSection };
