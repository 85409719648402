import { Box, Card, CircularProgress, Stack, Tooltip, Typography } from "@mui/material";
import { useTenantActionModalStore } from "../useTenantActionModalStore";
import { enqueueSnackbar } from "notistack";
import { putFileToS3PresignedUrl } from "../../../../../api";
import { ClaimsApi } from "../../../../../utils/apiInstances/ClaimsApiInstance";
import { FileUploadDropzone } from "../../../../../shared/FileUploadDropzone";
import { useState } from "react";
import { useChatBoxStore } from "../../ChatBox/useChatBoxStore";
import { useTodoItemListStore } from "../../ToDoItemsContainer/ToDoItemList/useTodoItemListStore";
import { useSubmittedClaimDetailPageStore } from "../../useSubmittedClaimDetailsPageStore";
import { SafeLeaseButton } from "@safelease/components";

type FileUploadModalContentProps = {
  requestMessage: string;
};

function FileUploadModalContent({ requestMessage }: FileUploadModalContentProps) {
  const { modalContentData, resetStore } = useTenantActionModalStore((state) => ({
    modalContentData: state.modalContentData,
    resetStore: state.resetStore,
  }));
  const [submissionInProgress, setSubmissionInProgress] = useState<boolean>(false);
  const refetchMessages = useChatBoxStore((state) => state.refetchMessages);
  const refetchTenantActions = useTodoItemListStore((state) => state.refetchTenantActions);
  const refetchClaim = useSubmittedClaimDetailPageStore((state) => state.refetchClaim);

  const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
  const submitButtonDisabled = !uploadedFiles.length || submissionInProgress;
  const handleNewFiles = (files: File[]) => {
    setUploadedFiles((currentFiles) => {
      const allFiles = [...currentFiles];
      for (const file of files) {
        allFiles.push(file);
      }
      return allFiles;
    });
  };

  const handleClose = () => {
    resetStore();
    setUploadedFiles([]);
  };

  const onSubmit = async () => {
    if (!modalContentData) return;
    setSubmissionInProgress(true);
    try {
      const attachmentIds = [];
      for (const file of uploadedFiles) {
        const response = await ClaimsApi.createAttachmentAndGenerateUploadUrl(file.name, file.type);
        const { attachment, presignedUrl } = response.data;
        await putFileToS3PresignedUrl(file, presignedUrl);
        attachmentIds.push(attachment.id);
      }
      await ClaimsApi.completeTenantAction(modalContentData.id, {
        attachmentIds,
      });
      // Refetch the messages and list of tenant actions to show the updated state of completed
      refetchMessages();
      refetchTenantActions();
      // Refetch the claim to show the updated state of the claim after the tenant action is completed
      // There is a possibility that the claim status will change after the tenant action is completed because of an afterUpdate hook on the TenantAction model
      refetchClaim();
      setSubmissionInProgress(false);
      handleClose();
    } catch (error) {
      console.error(error);
      enqueueSnackbar("Error uploading files, please try again.", {
        variant: "error",
      });
      setSubmissionInProgress(false);
    }
  };

  return (
    <Card sx={{ maxHeight: "90vh", maxWidth: "90%", padding: 2, display: "flex", flexDirection: "column", bgcolor: "#EBEFF7" }}>
      <Stack spacing={2} paddingTop={1} sx={{ flex: "1", overflow: "auto" }}>
        {modalContentData?.lineItem && (
          <Typography sx={{ fontSize: 16, fontWeight: 500 }}>Item: '{modalContentData?.lineItem.name}'</Typography>
        )}
        <Box sx={{ flex: "1", overflow: "auto", maxHeight: "50vh" }}>
          <Typography
            sx={{
              fontSize: 14,
              fontWeight: 500,
              whiteSpace: "pre-wrap", // make sure new lines are displayed
              overflowWrap: "break-word",
            }}
          >
            {requestMessage}
          </Typography>
        </Box>
        {submissionInProgress ? (
          <Stack sx={{ alignItems: "center" }} padding={2} spacing={1}>
            <CircularProgress />
            {uploadedFiles.length > 0 && (
              <Typography sx={{ fontSize: 12 }}>
                Uploading files... Large files or videos may take a minute please do not close this page
              </Typography>
            )}
          </Stack>
        ) : (
          <Box sx={{ flex: 1, overflow: "auto", maxHeight: "40vh" }}>
            <FileUploadDropzone
              handleNewFiles={handleNewFiles}
              uploadedFiles={uploadedFiles}
              containerProps={{ bgcolor: "white", border: "1px dashed black" }}
              uploadButtonProps={{
                variant: "contained",
                color: "navy",
              }}
            />
          </Box>
        )}
      </Stack>
      <Stack direction="row" justifyContent="flex-end" paddingTop={2} spacing={2}>
        <SafeLeaseButton disabled={submissionInProgress} variant="outlined" color="navy" onClick={handleClose}>
          Cancel
        </SafeLeaseButton>
        <Tooltip title={submitButtonDisabled ? "No response has been provided yet" : ""}>
          <Box>
            <SafeLeaseButton disabled={submitButtonDisabled} variant="contained" color="navy" onClick={onSubmit}>
              Submit
            </SafeLeaseButton>
          </Box>
        </Tooltip>
      </Stack>
    </Card>
  );
}

export { FileUploadModalContent };
