import { Card, Modal, Stack, Typography } from "@mui/material";
import { SafeLeaseButton } from "@safelease/components";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

type WithdrawClaimConfirmationModalProps = {
  modalOpen: boolean;
  setModalOpen: (modalOpen: boolean) => void;
  onWithdraw: () => Promise<void>;
};

function WithdrawClaimConfirmationModal({ modalOpen, setModalOpen, onWithdraw }: WithdrawClaimConfirmationModalProps) {
  const [requestLoading, setRequestLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  const withdrawClaimButtonPressed = async () => {
    setRequestLoading(true);
    await onWithdraw?.();
    setRequestLoading(false);
    navigate("/manage-claims");
    setModalOpen(false);
  };

  return (
    <Modal
      onClose={() => setModalOpen(false)}
      open={modalOpen}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Card>
        <Stack padding={3} spacing={2}>
          <Typography sx={{ fontWeight: 500 }}>Are you sure you want to withdraw this claim?</Typography>
          <SafeLeaseButton onClick={withdrawClaimButtonPressed} loading={requestLoading} variant="contained" color="error">
            Withdraw claim
          </SafeLeaseButton>
          <SafeLeaseButton onClick={() => setModalOpen(false)} variant="outlined" color="navy">
            Cancel
          </SafeLeaseButton>
        </Stack>
      </Card>
    </Modal>
  );
}

export { WithdrawClaimConfirmationModal };
