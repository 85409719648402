import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

import { IconButton, Stack } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { SafeLeaseButton } from "@safelease/components";

type SevereDelayModalProps = {
  open: boolean;
  onClose: () => void;
  onContinueAnyway: () => void;
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80vw",
  maxWidth: 800,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

function SevereDelayModal({ open, onClose, onContinueAnyway }: SevereDelayModalProps) {
  return (
    <Modal open={open} onClose={onClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
      <Box sx={style}>
        <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"} paddingBottom={2}>
          <Typography fontSize={18} fontWeight={600}>
            Are you sure? You have missing documents
          </Typography>
          <IconButton onClick={onClose}>X</IconButton>
        </Stack>
        <Stack direction="row" borderLeft={"4px solid red"} borderRadius={2} alignItems={"center"} spacing={1} width={"100%"} padding={1}>
          <InfoIcon color="error" fontSize="medium" sx={{ paddingLeft: 1 }} />
          <Typography fontSize={12} width="100%">
            <strong>This may delay the processing of your claim.</strong> Your adjuster may request this documentation from you upon
            reviewing your claim.
          </Typography>
        </Stack>
        <Box
          sx={{
            mt: 2,
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            justifyContent: "space-between",
            gap: { xs: 2, sm: 0 },
          }}
        >
          <SafeLeaseButton
            variant="outlined"
            sx={{ color: "black", border: "1px solid black", width: { xs: "100%", sm: "30%" } }}
            onClick={onClose}
          >
            Cancel
          </SafeLeaseButton>
          <SafeLeaseButton variant="contained" sx={{ width: { xs: "100%", sm: "30%" } }} color={"navy"} onClick={onContinueAnyway}>
            Yes, submit
          </SafeLeaseButton>
        </Box>
      </Box>
    </Modal>
  );
}

export { SevereDelayModal };
