import { EmailSubmitContent } from "./EmailSubmitContent";
import { NewPasswordSubmitContent } from "./NewPasswordSubmitContent";
import { ForgotPasswordStages, useForgotPasswordPageStore } from "./useForgotPasswordPageStore";
import AllDoneContent from "./AllDoneContent";

type ForgotPasswordPageProps = {};

function ForgotPasswordPages({}: ForgotPasswordPageProps) {
  const forgotPasswordStage = useForgotPasswordPageStore((state) => state.forgotPasswordStage);

  return (
    <>
      {forgotPasswordStage === ForgotPasswordStages.EMAIL_SUBMIT && <EmailSubmitContent />}
      {forgotPasswordStage === ForgotPasswordStages.NEW_PASSWORD_SUBMIT && <NewPasswordSubmitContent />}
      {forgotPasswordStage === ForgotPasswordStages.ALL_DONE && <AllDoneContent />}
    </>
  );
}

export { ForgotPasswordPages };
