import LoadingButton from "@mui/lab/LoadingButton";

type ContinueButtonProps = {
  loading?: boolean;
  disabled: boolean;
  onClick?: () => void;
  type?: "button" | "submit" | "reset" | undefined;
  label?: string;
};

function ContinueButton({ loading, disabled, onClick, type, label = "Continue" }: ContinueButtonProps) {
  return (
    <LoadingButton
      type={type}
      loading={loading}
      disabled={disabled}
      disableElevation
      variant="contained"
      sx={{
        backgroundColor: "primary.main",
        width: "150px",
      }}
      onClick={onClick}
    >
      <span>{label}</span>
    </LoadingButton>
  );
}

export { ContinueButton };
