import { Stack, Typography } from "@mui/material";
import { Attachment_ClaimsDbModel, Claim_ClaimsDbModel, TypeSpecificAttachmentDef_ClaimsDbModel } from "@safelease/service-utilities";
import { ClaimDocumentSection } from "./ClaimDocumentSection";
import { TypeSpecificAttachmentSummary } from "./TypeSpecificAttachmentSummary";

type UploadedFileSummaryProps = {
  claimData: Claim_ClaimsDbModel;
  typeSpecificAttachmentDefs: TypeSpecificAttachmentDef_ClaimsDbModel[];
};

export default function UploadedFileSummary({ claimData, typeSpecificAttachmentDefs }: UploadedFileSummaryProps) {
  const tenantProvidedProtectionPlan = claimData.protectionPlanAddendumDocs && claimData.protectionPlanAddendumDocs.length > 0;
  const tenantProvidedBillingLedger = claimData.billingLedgerDocs && claimData.billingLedgerDocs.length > 0;

  return (
    <Stack spacing={2}>
      {(tenantProvidedProtectionPlan || tenantProvidedBillingLedger) && (
        <Typography sx={{ fontSize: ".8rem", fontWeight: 600 }}>Provided documents</Typography>
      )}

      <Stack spacing={2}>
        <ClaimDocumentSection
          attachments={
            (claimData.billingLedgerDocs
              ?.map((doc) => doc.attachment)
              ?.filter((attachment) => attachment !== undefined && !attachment.deletedAt) as Attachment_ClaimsDbModel[]) || []
          }
          label="Billing ledger"
        />

        <ClaimDocumentSection
          attachments={
            (claimData.protectionPlanAddendumDocs
              ?.map((doc) => doc.attachment)
              ?.filter((attachment) => attachment !== undefined && !attachment.deletedAt) as Attachment_ClaimsDbModel[]) || []
          }
          label="Protection plan addendum"
        />
        <TypeSpecificAttachmentSummary typeSpecificAttachmentDefs={typeSpecificAttachmentDefs} />
      </Stack>
    </Stack>
  );
}
