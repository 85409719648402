export enum ClaimStatus {
  NotStarted = "Not Started",
  Incomplete = "Incomplete",
  NewClaim = "New Claim",
  NeedsReview = "Needs Review",
  TenantActionNeeded = "Tenant Action Needed",
  PendingSettlement = "Pending Settlement",
  ClosedSettled = "Closed Settled",
  ClosedDenied = "Closed Denied",
  ClosedAbandoned = "Closed Abandoned",
}

export enum ClaimTypes {
  buildingCollapseFailure = "Building Collapse/Failure",
  burglary = "Burglary",
  fire = "Fire",
  mold = "Mold",
  rodent = "Rodent",
  vandalism = "Vandalism",
  vehicleDamage = "Vehicle Damage",
  waterDamage = "Water Damage",
  windstorm = "Windstorm",
  other = "Other",
}
