import { SafeLeaseButton, SafeLeaseFormTextField } from "@safelease/components";
import { Box, Checkbox, FormControlLabel, Stack, Theme, Tooltip, Typography } from "@mui/material";
import { z } from "zod";
import { Controller, FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { forwardRef, useState } from "react";
import { enqueueSnackbar } from "notistack";
import { isPossiblePhoneNumber } from "react-phone-number-input";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { FormContentType, useAuthenticationPageStore } from "./useAuthenticationPageStore";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import { signIn, signUp } from "aws-amplify/auth";

export type SignUpFormValues = {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  password: string;
  passwordConfirmation: string;
  textNotifications: boolean;
  emailNotifications: boolean;
};

const validationSchema = z
  .object({
    email: z.string().trim().min(1, "Email is required.").email(), // adding the trim method to this field to remove any leading or trailing white spaces since we will be trimming this field when we send it to cognito
    password: z
      .string()
      .min(8, "Password must be at least 8 characters long.")
      .regex(/[A-Z]/, "Password must contain at least one uppercase letter")
      .regex(/[0-9]/, "Password must contain at least one number")
      .regex(/[^A-Za-z0-9]/, "Password must contain at least one special character"),
    firstName: z.string().trim().min(1, "First name is required."), // adding the trim method to this field to remove any leading or trailing white spaces since we will be trimming this field when we send it to cognito
    lastName: z.string().trim().min(1, "Last name is required."), // adding the trim method to this field to remove any leading or trailing white spaces since we will be trimming this field when we send it to cognito
    phoneNumber: z
      .string()
      .min(1, "Phone number is required.")
      .refine((data) => isPossiblePhoneNumber(data, "US"), "Invalid phone number."),
    passwordConfirmation: z.string().min(1, "Confirm password is required."),
    textNotifications: z.boolean(),
  })
  .refine((data) => data.password === data.passwordConfirmation, {
    message: "Passwords do not match",
    path: ["passwordConfirmation"], // This specifies which field the error is associated with
  });

type SignUpFormProps = {};

function SignUpForm({}: SignUpFormProps) {
  const setCurrentFormContextType = useAuthenticationPageStore((state) => state.setCurrentFormContextType);
  const [loading, setLoading] = useState<boolean>(false);

  const useFormMethods = useForm<SignUpFormValues>({
    defaultValues: {
      firstName: "",
      lastName: "",
      phoneNumber: "",
      email: "",
      password: "",
      passwordConfirmation: "",
      textNotifications: true,
    },
    resolver: zodResolver(validationSchema),
  });
  const { control, formState, handleSubmit } = useFormMethods;

  const onSignupButtonClicked: SubmitHandler<SignUpFormValues> = async (data) => {
    setLoading(true);

    const sanitizedData = {
      ...data,
      email: data.email.trim(), // removing all leading and trailing white spaces from the email
      phoneNumber: data.phoneNumber.replace(/\s/g, ""), // removing all white spaces from the phone number
      firstName: data.firstName.trim(), // removing all leading and trailing white spaces from the first name
      lastName: data.lastName.trim(), // removing all leading and trailing white spaces from the last name
    };

    try {
      await signUp({
        username: sanitizedData.email,
        password: sanitizedData.password,
        options: {
          userAttributes: {
            email: sanitizedData.email,
            phone_number: sanitizedData.phoneNumber,
            given_name: sanitizedData.firstName,
            family_name: sanitizedData.lastName,
            "custom:textCommsAccepted": sanitizedData.textNotifications.toString(),
            "custom:isPPEnabled": "true",
          },
        },
      });
      await signIn({ username: sanitizedData.email, password: sanitizedData.password });
    } catch (error: any) {
      console.log(error);
      enqueueSnackbar(error?.message ?? "Something went wrong. Please try again later.", { variant: "error" });
    } finally {
      setLoading(false);
    }
  };

  return (
    <FormProvider {...useFormMethods}>
      <form onSubmit={handleSubmit(onSignupButtonClicked)} style={{ height: "100%" }}>
        <Stack justifyContent="space-between" height="100%" padding={{ xs: 2, sm: 0 }}>
          <Stack spacing={{ xs: 1, sm: 2 }}>
            <Typography sx={{ fontSize: 20, fontWeight: 500 }}>Create an account</Typography>
            <Stack direction={{ xs: "column", sm: "row" }} spacing={{ xs: 1, sm: 2 }}>
              <StyledSafeLeaseFormTextField
                name="firstName"
                helperText={formState.errors.firstName?.message}
                error={!!formState.errors.firstName?.message}
                label="First name"
                placeholder="Ex: Alex"
              />
              <StyledSafeLeaseFormTextField
                name="lastName"
                helperText={formState.errors.lastName?.message}
                error={!!formState.errors.lastName?.message}
                label="Last name"
                placeholder="Ex: Johnson"
              />
            </Stack>
            <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
              <Box flex={1}>
                <StyledSafeLeaseFormTextField
                  name="email"
                  helperText={formState.errors.email?.message}
                  error={!!formState.errors.email?.message}
                  label="Email"
                  labelProps={{
                    sx: {
                      fontSize: 14,
                      fontWeight: 500,
                    },
                  }}
                  placeholder="Ex: Alex@gmail.com"
                />
              </Box>
              <Box flex={1}>
                <Controller
                  name="phoneNumber"
                  control={control}
                  render={({ field }) => (
                    <>
                      {/* Needed to add the extra typography at this level because the inputComponent only targets the textField to the right of the country flag icon which made the label and helper texts look weird */}
                      <Typography sx={{ fontSize: 14, fontWeight: 500, padding: 0 }} component="label">
                        Phone number
                      </Typography>
                      <PhoneInput
                        placeholder="Enter phone number"
                        defaultCountry="US"
                        international
                        inputComponent={StyledSafeLeaseFormTextField}
                        error={!!formState.errors.phoneNumber?.message}
                        helperText={formState.errors.phoneNumber?.message}
                        fullWidth
                        {...field}
                      />
                    </>
                  )}
                />
              </Box>
            </Stack>
            <Stack direction={{ xs: "column", sm: "row" }} spacing={{ xs: 1, sm: 2 }}>
              <StyledSafeLeaseFormTextField
                type="password"
                name="password"
                helperText={formState.errors.password?.message}
                error={!!formState.errors.password?.message}
                label="Password"
                placeholder="********"
              />
              <StyledSafeLeaseFormTextField
                type="password"
                name="passwordConfirmation"
                helperText={formState.errors.passwordConfirmation?.message}
                error={!!formState.errors.passwordConfirmation?.message}
                label="Confirm password"
                placeholder="********"
              />
            </Stack>
            <Stack direction="column">
              <Stack direction={"row"} alignItems={"center"}>
                <FormControlLabel
                  label="I want to receive text communication"
                  control={
                    <Controller
                      name="textNotifications"
                      control={control}
                      render={({ field }) => (
                        <Checkbox {...field} checked={field.value} onChange={(e) => field.onChange(e.target.checked)} />
                      )}
                    />
                  }
                />
                <Tooltip
                  title="Receive text communications on your claim and other important updates. Your personal information will not be shared or used for sales purposes."
                  componentsProps={{
                    tooltip: {
                      sx: {
                        position: "absolute",
                        fontSize: 16,
                        fontWeight: 500,
                        backgroundColor: { xs: "grey.300", sm: "transparent" },
                        color: "grey",
                        maxWidth: 625,
                        width: { xs: 400, sm: 625 },
                        left: -341,
                        top: -20,
                      },
                    },
                  }}
                >
                  <HelpOutlineOutlinedIcon sx={{ color: "grey.500", ml: -1 }} />
                </Tooltip>
              </Stack>
            </Stack>
          </Stack>
          <Stack spacing={2}>
            <SafeLeaseButton type="submit" variant="contained" color="navy" sx={{ width: "100%" }} loading={loading}>
              Create account
            </SafeLeaseButton>
            <Stack direction="row" spacing={0.5}>
              <Typography sx={{ color: (theme: Theme) => theme.palette.grey.A200, fontSize: 14, fontWeight: 500 }}>
                Already have an account?
              </Typography>
              <Typography
                id="e2e-login-link-from-signup-form"
                onClick={() => setCurrentFormContextType(FormContentType.LOGIN)}
                sx={{ color: "#031E30", cursor: "pointer", fontSize: 14, fontWeight: 500 }}
              >
                Login
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </form>
    </FormProvider>
  );
}

const StyledSafeLeaseFormTextField = forwardRef((props: any, ref) => {
  return (
    <SafeLeaseFormTextField
      inputRef={ref}
      labelProps={{
        sx: {
          fontSize: 14,
          fontWeight: 500,
        },
      }}
      {...props}
    />
  );
});

export { SignUpForm };
