import React from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { IconButton, Menu, MenuItem } from "@mui/material";

const muiMenuItem = {
  height: "30px",
  fontSize: "12px",
  color: "#4E4E4E",
  fontFamily: "Open Sans, Arial, sans-serif",
  fontWeight: 600,
  paddingLeft: "20px",
  "&:hover": {
    backgroundColor: "#2879FB",
    color: "white",
  },
};

type ActionMenuProps = {
  dropdownOptions: {
    label: string;
    cb: () => void;
  }[];
};

function ActionMenu(props: ActionMenuProps) {
  const { dropdownOptions } = props;

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        id="action-button"
        aria-controls={open ? "action-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="action-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "action-button",
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {dropdownOptions.map((option) => (
          <MenuItem
            key={option.label}
            onClick={() => {
              handleClose();
              option.cb();
            }}
            sx={muiMenuItem}
          >
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}

export { ActionMenu };
