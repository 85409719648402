import { Grid, Stack, Theme, Typography } from "@mui/material";
import { useNavigate } from "react-router";
import { SafeLeaseCard } from "@safelease/components";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import { useAuthStore } from "../hooks/useAuthStore";
import PolicyOutlinedIcon from "@mui/icons-material/PolicyOutlined";
import { useQuery } from "@tanstack/react-query";
import { ClaimsApi } from "../utils/apiInstances/ClaimsApiInstance.ts";
import { SafeleasePrivatePolicy } from "@safelease/service-utilities";

function HomePage({}) {
  const userAttributes = useAuthStore((state) => state.userAttributes);
  const navigate = useNavigate();

  const privatePoliciesQuery = useQuery<SafeleasePrivatePolicy[]>({
    queryKey: ["private-policies", userAttributes?.sub],
    queryFn: async () => {
      const response = await ClaimsApi.getPrivatePolicies();
      return response.data.privatePolicies;
    },
  });

  const claimsClicked = () => {
    navigate("/manage-claims");
  };

  const privatePolicyClicked = () => {
    if (privatePoliciesQuery.data && privatePoliciesQuery.data.length > 0) {
      navigate("/manage-private-policies");
    } else {
      navigate("/private-policy");
    }
  };

  const userActionCardStyles = {
    height: 259,
    display: "flex",
    boxShadow: 0,
  };

  return (
    <Grid container spacing={2} padding="30px">
      <Grid item xs={12}>
        <Typography variant="h4" sx={{ fontWeight: "bold" }}>
          Welcome, {userAttributes?.given_name}
        </Typography>
        <Typography>Get started with quick actions below</Typography>
      </Grid>
      <Grid item sm={userAttributes?.isPPEnabled === "true" ? 6 : 12} xs={12}>
        <SafeLeaseCard clickable sx={userActionCardStyles} onClick={claimsClicked}>
          <Stack flex={1} justifyContent="center" alignItems="center">
            <ArticleOutlinedIcon sx={{ fontSize: 32, color: "blue.main" }} />
            <Typography sx={{ fontSize: 18, fontWeight: 500 }} textAlign="center">
              My claim
            </Typography>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: 12,
                color: (theme: Theme) => theme.palette.grey.A200,
              }}
              textAlign="center"
            >
              Submit and manage claim
            </Typography>
          </Stack>
        </SafeLeaseCard>
      </Grid>
      {userAttributes?.isPPEnabled === "true" && (
        <Grid item sm={6} xs={12}>
          <SafeLeaseCard clickable sx={userActionCardStyles} onClick={privatePolicyClicked}>
            <Stack flex={1} justifyContent="center" alignItems="center">
              <PolicyOutlinedIcon sx={{ fontSize: 32, color: "blue.main" }} />
              <Typography sx={{ fontSize: 18, fontWeight: 500 }} textAlign="center">
                {privatePoliciesQuery.data && privatePoliciesQuery.data.length > 0 ? "My policies" : "Submit a private policy"}
              </Typography>
              <Typography
                sx={{
                  fontWeight: 500,
                  fontSize: 12,
                  color: (theme: Theme) => theme.palette.grey.A200,
                }}
                textAlign="center"
              >
                For private policy owners only
              </Typography>
            </Stack>
          </SafeLeaseCard>
        </Grid>
      )}
    </Grid>
  );
}

export { HomePage };
