import { Box, Card, IconButton, LinearProgress, Modal, Stack, Typography } from "@mui/material";
import { UnitSelectionModalStep, useUnitSelectionModalStore } from "./useUnitSelectionModalStore";
import { LocationSearchStep } from "./LocationSearchStep";
import { Close } from "@mui/icons-material";
import { EmailUnitLookupStep } from "./EmailUnitLookupStep";
import { UnitSelectionStep } from "./UnitSelectionStep";
import { useEffect } from "react";
import { TenantNameLookupStep } from "./TenantNameLookupStep";
import { VerifySkipUnitMatchingModal } from "./VerifySkipUnitMatchingModal";

type UnitSelectionModalProps = {
  setModalOpen: (modalOpen: boolean) => void;
};

function UnitSelectionModal({ setModalOpen }: UnitSelectionModalProps) {
  const { currentStep, progress, resetStore, verifySkipModalOpen } = useUnitSelectionModalStore((state) => ({
    currentStep: state.currentStep,
    progress: state.progress,
    resetStore: state.resetStore,
    verifySkipModalOpen: state.verifySkipModalOpen,
  }));

  const getCurrentModalContent = () => {
    switch (currentStep) {
      case UnitSelectionModalStep.FACILITY_LOCATION_SEARCH:
        return <LocationSearchStep />;
      case UnitSelectionModalStep.EMAIL_UNIT_LOOKUP:
        return <EmailUnitLookupStep />;
      case UnitSelectionModalStep.UNIT_SELECTION:
        return <UnitSelectionStep />;
      case UnitSelectionModalStep.TENANT_NAME_LOOKUP:
        return <TenantNameLookupStep />;
    }
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  useEffect(() => {
    return () => {
      // anytime the modal is closed it will become unmounted and this function will fire. This is to reset the store so that the next time the modal is opened it will be in the initial state
      resetStore();
    };
  }, [resetStore]);

  return (
    <Modal onClose={closeModal} open={true} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
      <Card>
        <Stack spacing={1} sx={{ width: { xs: "95vw", sm: 600 }, maxHeight: "95vh", overflow: "auto", padding: 2 }}>
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Typography sx={{ fontSize: 20, fontWeight: 500 }}>Connecting unit</Typography>
            <IconButton onClick={closeModal}>
              <Close sx={{ color: "black" }} />
            </IconButton>
          </Stack>
          <LinearProgress
            variant="determinate"
            value={progress}
            sx={{
              "& .MuiLinearProgress-bar": {
                backgroundColor: "navy.main",
              },
              backgroundColor: "#EBEFF7",
            }}
          />
          <Box sx={{ display: "flex", overflow: "auto", paddingY: 2, width: "100%" }}>{getCurrentModalContent()}</Box>
          <Box sx={{ display: "flex", justifyContent: "center", paddingBottom: 2 }}>
            <Typography sx={{ fontSize: 14, fontWeight: 500 }}>Email support@safelease.com</Typography>
          </Box>
        </Stack>
        {verifySkipModalOpen && <VerifySkipUnitMatchingModal />}
      </Card>
    </Modal>
  );
}

export { UnitSelectionModal };
