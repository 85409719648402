import { Box, Stack, Tooltip, Typography } from "@mui/material";
import { SafeLeaseButton } from "@safelease/components";
import { UnitSelectionModalStep, useUnitSelectionModalStore } from "../useUnitSelectionModalStore";
import { ChevronLeft, Search } from "@mui/icons-material";
import { StyledTextField } from "../../../shared/StyledTextField";
import { useState } from "react";
import { ClaimsApi } from "../../../utils/apiInstances/ClaimsApiInstance";
import * as Sentry from "@sentry/react";
import { enqueueSnackbar } from "notistack";
import { BorderedCheckbox } from "../../../shared/BorderedCheckbox";
import { useAuthStore } from "../../../hooks/useAuthStore";
import { OrDividingLine } from "../../../shared/OrDividingLine";

type TenantNameLookupStepProps = {};

function TenantNameLookupStep({}: TenantNameLookupStepProps) {
  const [defaultNameSelected, setDefaultNameSelected] = useState<boolean>(false);
  const [userProvidedName, setUserProvidedName] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [noUnitsFoundErrorMessage, setNoUnitsFoundErrorMessage] = useState<string>("");
  const userAttributes = useAuthStore((state) => state.userAttributes);
  const { setCurrentStep, selectedLocation, setLeaseMatchingData, setProgress, setVerifySkipModalOpen } = useUnitSelectionModalStore(
    (state) => ({
      setCurrentStep: state.setCurrentStep,
      selectedLocation: state.selectedLocation,
      setLeaseMatchingData: state.setLeaseMatchingData,
      setProgress: state.setProgress,
      setVerifySkipModalOpen: state.setVerifySkipModalOpen,
    }),
  );
  const currentUsersName = `${userAttributes?.given_name} ${userAttributes?.family_name}`;
  const disableContinueButton = !defaultNameSelected && !userProvidedName.trim();

  const onContinue = async () => {
    setIsLoading(true);
    const tenantName = defaultNameSelected ? currentUsersName : userProvidedName;
    try {
      if (!selectedLocation) throw new Error("Missing selected location data");
      const response = await ClaimsApi.getSafeleaseLeaseMatchingDataForLocationViaTenantName({
        locationId: selectedLocation.locationId,
        tenantName: tenantName,
      });
      if (response.data.length === 0) {
        setNoUnitsFoundErrorMessage(
          `We couldn't find you with '${tenantName}'. Please make sure you provide the name on your lease. If you are certain this is the correct name click the button below.`,
        );
      } else {
        setLeaseMatchingData(response.data);
        setCurrentStep(UnitSelectionModalStep.UNIT_SELECTION);
        setProgress(75);
      }
    } catch (error: any) {
      Sentry.captureException(error);
      enqueueSnackbar("There was an error searching for your unit. Please try again", { variant: "error" });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Stack width="100%" spacing={3}>
      <Stack spacing={0.5}>
        <Box>
          <SafeLeaseButton
            sx={{ color: "grey.A200" }}
            onClick={() => {
              setCurrentStep(UnitSelectionModalStep.EMAIL_UNIT_LOOKUP);
            }}
            startIcon={<ChevronLeft sx={{ color: "navy.main" }} />}
          >
            Go back
          </SafeLeaseButton>
        </Box>
        <Typography sx={{ fontSize: 18, fontWeight: 500 }}>{"Let's find you via your name"}</Typography>
        <Typography sx={{ fontSize: 14, fontWeight: 500, color: (theme) => theme.palette.grey.A200 }}>
          {"Enter your name as it appears on your lease"}
        </Typography>
      </Stack>
      <BorderedCheckbox value={defaultNameSelected} setValue={setDefaultNameSelected} label={currentUsersName} />
      <OrDividingLine />
      <StyledTextField
        value={userProvidedName}
        setValue={setUserProvidedName}
        disabled={defaultNameSelected}
        placeholder="Enter name"
        startIcon={<Search />}
      />
      {noUnitsFoundErrorMessage && (
        <Stack spacing={1}>
          <Typography sx={{ fontSize: 14, fontWeight: 500, color: "error.main" }}>{noUnitsFoundErrorMessage}</Typography>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <SafeLeaseButton onClick={() => setVerifySkipModalOpen(true)} variant="outlined" color="navy" size="small">
              Skip unit matching
            </SafeLeaseButton>
          </Box>
        </Stack>
      )}
      <Tooltip title={disableContinueButton ? "Please select/provide a name to continue" : ""} placement="top">
        <Box>
          <SafeLeaseButton
            onClick={onContinue}
            loading={isLoading}
            disabled={disableContinueButton}
            color="navy"
            variant="contained"
            fullWidth
          >
            Continue
          </SafeLeaseButton>
        </Box>
      </Tooltip>
    </Stack>
  );
}

export { TenantNameLookupStep };
