import { Stack, Typography } from "@mui/material";
import { SafeLeaseButton } from "@safelease/components";

type PageNotFoundProps = {};

export default function PageNotFound({}: PageNotFoundProps) {
  return (
    <Stack height="80vh" justifyContent="center" alignItems="center" spacing={2}>
      <Typography variant="h3">404</Typography>
      <Typography>Page not found</Typography>
      <SafeLeaseButton variant="contained" href="/">
        Go to home
      </SafeLeaseButton>
    </Stack>
  );
}
