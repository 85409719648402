import { GetTenantActionsResponse } from "@safelease/service-utilities";
import { QueryObserverResult, RefetchOptions } from "@tanstack/react-query";
import { create } from "zustand";

type TodoItemListStore = {
  // This is an easy way to access the messagesQuery.refetch from a child component that is deeply nested in the component tree from the messagesQuery
  // The UploadFileMessage component needs this to refetch the messages after the user uploads a file
  tenantActions: GetTenantActionsResponse[] | null;
  setTenantActions: (tenantActions: GetTenantActionsResponse[] | null) => void;
  refetchTenantActions: (options?: RefetchOptions | undefined) => Promise<QueryObserverResult<GetTenantActionsResponse[], Error> | void>;
  setRefetchTenantActions: (
    refetchTenantActions: (options?: RefetchOptions | undefined) => Promise<QueryObserverResult<GetTenantActionsResponse[], Error> | void>,
  ) => void;
  tenantActionLength: number;
};

export const useTodoItemListStore = create<TodoItemListStore>()((set) => ({
  tenantActions: null,
  setTenantActions: (tenantActions) => {
    const tenantActionLength = tenantActions?.length || 0;
    set({ tenantActions, tenantActionLength });
  },
  refetchTenantActions: async () => {},
  setRefetchTenantActions: (refetchTenantActions) => {
    set({ refetchTenantActions });
  },
  tenantActionLength: 0,
}));
