import { GetClaimMessageResponse } from "@safelease/service-utilities";
import { TenantActionTypes } from "@safelease/service-utilities/enums";
import { UploadFileMessage } from "./UploadFileMessage";
import { SettlementOfferMessage } from "./SettlementOfferMessage";
import { FormQuestionMessage } from "./FormQuestionMessage";
import { AdHocRequestMessage } from "./AdHocRequestMessage";

type TenantActionMessageProps = {
  messageData: GetClaimMessageResponse;
};

function TenantActionMessage({ messageData }: TenantActionMessageProps) {
  if (messageData.tenantAction?.type === TenantActionTypes.UPLOAD_BILLING_LEDGER) {
    return (
      <UploadFileMessage
        messageData={messageData}
        requestMessage={messageData.tenantAction.metadata?.[TenantActionTypes.UPLOAD_BILLING_LEDGER]?.requestMessage || ""}
      />
    );
  } else if (messageData.tenantAction?.type === TenantActionTypes.UPLOAD_PROTECTION_PLAN_ADDENDUM) {
    return (
      <UploadFileMessage
        messageData={messageData}
        requestMessage={messageData.tenantAction.metadata?.[TenantActionTypes.UPLOAD_PROTECTION_PLAN_ADDENDUM]?.requestMessage || ""}
      />
    );
  } else if (messageData.tenantAction?.type === TenantActionTypes.UPLOAD_PROOF_OF_OWNERSHIP_FILES) {
    return (
      <UploadFileMessage
        messageData={messageData}
        requestMessage={messageData.tenantAction.metadata?.[TenantActionTypes.UPLOAD_PROOF_OF_OWNERSHIP_FILES]?.requestMessage || ""}
      />
    );
  } else if (messageData.tenantAction?.type === TenantActionTypes.UPLOAD_PROOF_OF_PURCHASE_FILES) {
    return (
      <UploadFileMessage
        messageData={messageData}
        requestMessage={messageData.tenantAction.metadata?.[TenantActionTypes.UPLOAD_PROOF_OF_PURCHASE_FILES]?.requestMessage || ""}
      />
    );
  } else if (messageData.tenantAction?.type === TenantActionTypes.UPLOAD_TYPE_SPECIFIC_ATTACHMENT) {
    return (
      <UploadFileMessage
        messageData={messageData}
        requestMessage={messageData.tenantAction.metadata?.[TenantActionTypes.UPLOAD_TYPE_SPECIFIC_ATTACHMENT]?.requestMessage || ""}
      />
    );
  } else if (messageData.tenantAction?.type === TenantActionTypes.SETTLEMENT_OFFER) {
    return <SettlementOfferMessage messageData={messageData} />;
  } else if (messageData.tenantAction?.type === TenantActionTypes.FORM_QUESTION_ANSWER) {
    return <FormQuestionMessage messageData={messageData} />;
  } else if (messageData.tenantAction?.type === TenantActionTypes.AD_HOC_REQUEST) {
    return <AdHocRequestMessage messageData={messageData} />;
  }
  // Should never reach here
  return <></>;
}

export { TenantActionMessage };
