import { Divider, Stack, Theme, Typography } from "@mui/material";

type OrDividingLineProps = {};

function OrDividingLine({}: OrDividingLineProps) {
  return (
    <Stack direction="row" width="100%" spacing={1.5} alignItems="center">
      <Divider sx={{ flex: 1, borderColor: "#EBEFF7" }} />
      <Typography sx={{ fontSize: 12, fontWeight: 500, color: (theme: Theme) => theme.palette.grey.A200 }}>Or</Typography>
      <Divider sx={{ flex: 1, borderColor: "#EBEFF7" }} />
    </Stack>
  );
}

export { OrDividingLine };
