import { Box, Stack, Typography } from "@mui/material";
import { GetTenantActionsResponse } from "@safelease/service-utilities";
import { useTenantActionModalStore } from "../../../TenantActionModal/useTenantActionModalStore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { TenantActionTypes } from "@safelease/service-utilities/enums";

type AdHocRequestTodoItemProps = {
  tenantAction: GetTenantActionsResponse;
};

function AdHocRequestTodoItem({ tenantAction }: AdHocRequestTodoItemProps) {
  const { setTenantActionModalOpen, setModalContentData } = useTenantActionModalStore();

  return (
    <Stack
      onClick={() => {
        setModalContentData(tenantAction);
        setTenantActionModalOpen(true);
      }}
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      border={"1px solid #E5E5E5"}
      sx={{
        margin: 0.5,
        padding: 2,
        borderRadius: 2,
        "&:hover": {
          cursor: "pointer",
          backgroundColor: "rgba(0, 0, 0, 0.04)",
        },
      }}
    >
      <Stack direction="row" alignItems={"center"} spacing={1} width={"100%"}>
        {/* make sure the text has a certain width and lineHeight so that it does not cause horizontal scrolling in the todo tab */}
        <Box
          sx={{
            maxWidth: 300,
            maxHeight: 100,
            overflow: "hidden",
            display: "-webkit-box",
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: 5,
            borderLeft: "4px solid red",
          }}
        >
          <Typography
            sx={{
              fontSize: 14,
              fontWeight: 500,
              lineHeight: "1.4em", // Ensure consistent line spacing
              paddingLeft: 2,
            }}
          >
            Provide additional information for: {tenantAction.metadata?.[TenantActionTypes.AD_HOC_REQUEST]?.requestMessage}
          </Typography>
        </Box>
      </Stack>
      <ChevronRightIcon sx={{ color: "rgba(0, 0, 0, 0.56)", fontSize: 14 }} />
    </Stack>
  );
}

export { AdHocRequestTodoItem };
