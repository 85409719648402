import { Box, Card, CircularProgress, Stack, Tooltip, Typography } from "@mui/material";
import { useTenantActionModalStore } from "../useTenantActionModalStore";
import { TenantActionTypes } from "@safelease/service-utilities/enums";
import { SafeLeaseButton, SafeLeaseTextField } from "@safelease/components";
import { useState } from "react";
import { FileUploadDropzone } from "../../../../../shared/FileUploadDropzone";
import { enqueueSnackbar } from "notistack";
import { ClaimsApi } from "../../../../../utils/apiInstances/ClaimsApiInstance";
import { putFileToS3PresignedUrl } from "../../../../../api";
import { useChatBoxStore } from "../../ChatBox/useChatBoxStore";
import { useTodoItemListStore } from "../../ToDoItemsContainer/ToDoItemList/useTodoItemListStore";

type AdHocRequestModalContentProps = {};

function AdHocRequestModalContent({}: AdHocRequestModalContentProps) {
  const { modalContentData, resetStore } = useTenantActionModalStore();
  const refetchMessages = useChatBoxStore((state) => state.refetchMessages);
  const refetchTenantActions = useTodoItemListStore((state) => state.refetchTenantActions);
  const [requestLoading, setRequestLoading] = useState<boolean>(false);
  const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
  const [userInputText, setUserInputText] = useState<string>("");

  const submitButtonDisabled = (!uploadedFiles.length && !userInputText.trim()) || requestLoading; // the tenant must either upload a file or enter a response or both

  const handleNewFiles = (files: File[]) => {
    setUploadedFiles((currentFiles) => {
      const allFiles = [...currentFiles];
      for (const file of files) {
        allFiles.push(file);
      }
      return allFiles;
    });
  };

  const handleClose = () => {
    resetStore();
    setUploadedFiles([]);
  };

  const handleResponseSubmission = async () => {
    if (!modalContentData) return;
    setRequestLoading(true);
    try {
      const attachmentIds = [];
      for (const file of uploadedFiles) {
        const response = await ClaimsApi.createAttachmentAndGenerateUploadUrl(file.name, file.type);
        const { attachment, presignedUrl } = response.data;
        await putFileToS3PresignedUrl(file, presignedUrl);
        attachmentIds.push(attachment.id);
      }
      await ClaimsApi.completeTenantAction(modalContentData.id, {
        [TenantActionTypes.AD_HOC_REQUEST]: {
          attachmentIds,
          textResponse: userInputText,
        },
      });
      refetchMessages();
      refetchTenantActions();
      setRequestLoading(false);
      handleClose();
    } catch (error) {
      console.log(error);
      enqueueSnackbar("Failed to submit response, please try again.", { variant: "error" });
      setRequestLoading(false);
    }
  };

  return (
    <Card sx={{ maxHeight: "90vh", maxWidth: "90%", padding: 2, display: "flex", flexDirection: "column", bgcolor: "#EBEFF7" }}>
      <Stack spacing={2} paddingTop={1} sx={{ flex: "1 1 auto", overflow: "auto" }}>
        <Box sx={{ minHeight: { xs: 25, md: 250 }, maxHeight: { xs: 250, md: 500 }, overflow: "auto" }}>
          <Typography
            sx={{
              fontSize: 14,
              fontWeight: 500,
              whiteSpace: "pre-wrap", // make sure new lines are displayed
              overflowWrap: "break-word",
            }}
          >
            {modalContentData?.metadata?.[TenantActionTypes.AD_HOC_REQUEST]?.requestMessage}
          </Typography>
        </Box>
        {requestLoading ? (
          <Stack sx={{ alignItems: "center" }} padding={2} spacing={1}>
            <CircularProgress />
            {uploadedFiles.length > 0 && (
              <Typography sx={{ fontSize: 12 }}>
                Uploading files... Large files or videos may take a minute please do not close this page
              </Typography>
            )}
          </Stack>
        ) : (
          <>
            <Stack>
              <Typography sx={{ fontSize: 12 }}>Response:</Typography>
              <SafeLeaseTextField
                multiline
                minRows={2}
                maxRows={10}
                value={userInputText}
                onChange={(e) => setUserInputText(e.target.value)}
                sx={{ bgcolor: "white" }}
              />
            </Stack>
            <Box sx={{ maxHeight: 500, overflow: "auto" }}>
              <FileUploadDropzone
                containerProps={{ bgcolor: "white", border: "1px dashed black" }}
                uploadButtonProps={{
                  variant: "contained",
                  color: "navy",
                }}
                handleNewFiles={handleNewFiles}
                uploadedFiles={uploadedFiles}
              />
            </Box>
          </>
        )}
      </Stack>
      <Stack direction="row" justifyContent="flex-end" paddingTop={2} spacing={2} sx={{ flexShrink: 0 }}>
        <SafeLeaseButton disabled={requestLoading} variant="outlined" color="navy" onClick={handleClose}>
          Cancel
        </SafeLeaseButton>
        <Tooltip title={submitButtonDisabled ? "No response has been provided yet" : ""}>
          <Box>
            <SafeLeaseButton disabled={submitButtonDisabled} variant="contained" color="navy" onClick={handleResponseSubmission}>
              Submit
            </SafeLeaseButton>
          </Box>
        </Tooltip>
      </Stack>
    </Card>
  );
}

export { AdHocRequestModalContent };
