import { UploadAttachmentFormElement } from "./UploadAttachmentFormElement";
import { useClaimCreationStore } from "../useClaimCreationStore";
import { Attachment_ClaimsDbModel } from "@safelease/service-utilities";
import { ClaimsApi } from "../../../../utils/apiInstances/ClaimsApiInstance";

type TypeSpecificAttachmentFormElementProps = {
  title: string;
  subTitle: string;
  typeSpecificAttachmentDefId: string;
};

function TypeSpecificAttachmentFormElement({ title, subTitle, typeSpecificAttachmentDefId }: TypeSpecificAttachmentFormElementProps) {
  const { setTypeSpecificAttachments, typeSpecificAttachments, removeTypeSpecificAttachment } = useClaimCreationStore((state) => ({
    setTypeSpecificAttachments: state.setTypeSpecificAttachments,
    typeSpecificAttachments: state.typeSpecificAttachments,
    removeTypeSpecificAttachment: state.removeTypeSpecificAttachment,
  }));

  const handleFileInputChange = (files: File[]) => {
    setTypeSpecificAttachments(typeSpecificAttachmentDefId, files);
  };

  async function handleRemoveFile(file: File | Attachment_ClaimsDbModel) {
    if (!(file instanceof File)) {
      await ClaimsApi.archiveDocument(file.id);
    }
    removeTypeSpecificAttachment(typeSpecificAttachmentDefId, file);
  }

  return (
    <UploadAttachmentFormElement
      handleRemoveFile={handleRemoveFile}
      handleFileInputChange={handleFileInputChange}
      title={title}
      subTitle={subTitle}
      uploadedFiles={typeSpecificAttachments[typeSpecificAttachmentDefId] || []}
    />
  );
}

export { TypeSpecificAttachmentFormElement };
