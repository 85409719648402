import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router";
import { Claim_ClaimsDbModel } from "@safelease/service-utilities";
import { ClaimStatus } from "../../enums";
import { ClaimCreationForm } from "./ClaimCreationForm";
import { SubmittedClaimDetailsPage } from "./SubmittedClaimDetailsPage";
import { Box, CircularProgress } from "@mui/material";
import { useSubmittedClaimDetailPageStore } from "./SubmittedClaimDetailsPage/useSubmittedClaimDetailsPageStore";
import { useEffect } from "react";
import { ClaimsApi } from "../../utils/apiInstances/ClaimsApiInstance";
import { useTodoItemListStore } from "./SubmittedClaimDetailsPage/ToDoItemsContainer/ToDoItemList/useTodoItemListStore";

type SelectedClaimPageProps = {};

function SelectedClaimPage({}: SelectedClaimPageProps) {
  const { claimId } = useParams<{ claimId: string }>();
  if (!claimId) return null;
  const { setTenantActions, setRefetchTenantActions } = useTodoItemListStore((state) => ({
    setTenantActions: state.setTenantActions,
    setRefetchTenantActions: state.setRefetchTenantActions,
  }));

  const { setClaim, setRefetchClaim, setIsActionNeeded } = useSubmittedClaimDetailPageStore((state) => ({
    setClaim: state.setClaim,
    setRefetchClaim: state.setRefetchClaim,
    setIsActionNeeded: state.setIsActionNeeded,
  }));

  const claimQuery = useQuery<Claim_ClaimsDbModel>({
    queryKey: ["claim", claimId],
    queryFn: async () => await ClaimsApi.getClaimById(claimId),
    gcTime: 0, // do not cache this query
  });

  const tenantActionsQuery = useQuery({
    queryKey: ["getTenantActions", claimId],
    queryFn: async () => {
      const response = await ClaimsApi.getPendingTenantActionsForClaim(claimId);
      return response.data;
    },
  });

  // Initialize the refetch function and claim data for use in the SubmittedClaimDetailsPage component below.
  useEffect(() => {
    if (claimQuery.status === "success" && claimQuery.data && tenantActionsQuery.status === "success" && tenantActionsQuery.data) {
      setRefetchClaim(claimQuery.refetch);
      setClaim(claimQuery.data);
      setTenantActions(tenantActionsQuery.data);
      setRefetchTenantActions(tenantActionsQuery.refetch);
      setIsActionNeeded(claimQuery.data.status === ClaimStatus.TenantActionNeeded || tenantActionsQuery.data.length > 0);
    }
  }, [
    claimQuery.data,
    claimQuery.status,
    setClaim,
    setRefetchClaim,
    claimQuery.refetch,
    setTenantActions,
    tenantActionsQuery.data,
    setRefetchTenantActions,
    tenantActionsQuery.status,
    setIsActionNeeded,
  ]);

  if (claimQuery.isLoading || tenantActionsQuery.isLoading) {
    return (
      <Box
        sx={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (claimQuery.isError || !claimQuery.data || tenantActionsQuery.isError || !tenantActionsQuery.data) {
    return <div>Error... please try again later</div>;
  }

  if (ClaimStatus.Incomplete === claimQuery.data?.status) {
    return <ClaimCreationForm />;
  } else {
    return <SubmittedClaimDetailsPage />;
  }
}

export { SelectedClaimPage };
