import { Avatar, Stack, Typography } from "@mui/material";
import AvatarIcon from "../../../assets/avatar.jpeg";

type AdjusterAvatarHeaderProps = {
  helpText: string | React.ReactNode;
};

function AdjusterAvatarHeader({ helpText }: AdjusterAvatarHeaderProps) {
  return (
    <Stack direction="row" sx={{ alignItems: "center", ml: 2, zIndex: 5 }}>
      <Avatar alt="Adjuster Image" src={AvatarIcon} sx={{ width: 40, height: 40, mr: 2 }} />
      <Stack>
        <Typography variant="h6">Claims Assistant</Typography>
        <Typography sx={{ color: "grey", fontSize: ".8rem" }}>{helpText}</Typography>
      </Stack>
    </Stack>
  );
}

export { AdjusterAvatarHeader };
