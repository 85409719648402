import React, { useState } from "react";
import { useForm, Controller, ControllerRenderProps } from "react-hook-form";
import {
  Container,
  TextField,
  Button,
  Grid,
  Typography,
  Box,
  Divider,
  styled,
  Stack,
  IconButton,
  Card,
  CircularProgress,
  Alert,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { NavbarWrapper } from "../MainDashboardRouter/NavbarWrapper.tsx";
import { SafeLeaseBreadcrumbs, SafeLeaseButton, SafeLeaseFileDropzone } from "@safelease/components";
import { useNavigate } from "react-router";
import { LocationAndUnitMatchingComponent } from "./LocationAndUnitMatchingComponent.tsx";
import { useAuthStore } from "../hooks/useAuthStore.ts";
import RestartIcon from "@mui/icons-material/RestartAlt";
import { FilePreviews } from "../shared/FileUploadDropzone/FilePreviews.tsx";
import { validateFileType } from "../utils/FileValidator.ts";
import { ClaimsApi } from "../utils/apiInstances/ClaimsApiInstance.ts";
import { NumericFormat } from "react-number-format";
import { SuccessMessage } from "../shared/SuccessMessage.tsx";
import PrivatePolicyNotCovered from "../assets/PrivatePolicyNotCovered.svg";
import PrivatePolicyProtectionPlan from "../assets/PrivatePolicyProtectionPlan.svg";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { AlertBanner } from "../shared/AlertBanner.tsx";
import { PrivatePolicyProtectionPlanSelection } from "../ManagePrivatePolicies/PrivatePolicyProtectionPlanSelection.tsx";
import dayjs from "dayjs";

interface PrivatePolicyFormInput {
  tenantName: string;
  email: string;
  locationId: string | null;
  address: string;
  city: string;
  state: string;
  policyNumber: string;
  expiration: Date | null;
  unitName: string | null;
  moveInDate: Date | null;
  attachment: File;
  insuranceCompany: string;
  policyDeductible: string;
  policyExpirationRemindersEnabled: boolean;
}

export type LocationAndUnitMatchingData = {
  locationId: number | null;
  address: string | null;
  city: string | null;
  state: string | null;
  matchType: "unitName" | "moveInDate";
  unitName: string | null;
  moveInDate: Date | null;
  tenantPolicyExpirationRemindersEnabled: boolean;
};

type PrivatePolicyFormProps = {};

function PrivatePolicyForm({}: PrivatePolicyFormProps) {
  const userAttributes = useAuthStore((state) => state.userAttributes);
  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
    setError,
    clearErrors,
  } = useForm<PrivatePolicyFormInput>();
  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(false);
  const [alert, setAlert] = useState<string | null>(null);
  const [formStep, setFormStep] = useState<"form" | "warning" | "plans" | "success">("form");
  const [locationId, setLocationId] = useState<number | null>(null);
  const [address, setAddress] = useState<string | null>(null);
  const [city, setCity] = useState<string | null>(null);
  const [state, setState] = useState<string | null>(null);
  const [matchType, setMatchType] = useState<"unitName" | "moveInDate">("unitName");
  const [unitName, setUnitName] = useState<string | null>(null);
  const [moveInDate, setMoveInDate] = useState<Date | null>(null);
  const [showPolicyExpirationReminderCheckbox, setShowPolicyExpirationReminderCheckbox] = useState<boolean>(false);
  const [privatePolicyId, setPrivatePolicyId] = useState<number | null>(null);

  const onSubmit = async (data: PrivatePolicyFormInput) => {
    setAlert(null);
    setLoading(true);
    try {
      const policyDeductible = Number(data.policyDeductible.replace(/\D/g, ""));
      const privatePolicyData: any = {
        ...data,
        policyDeductible,
        ...(locationId ? { locationId } : { locationId: undefined }),
        address,
        city,
        state,
        ...(matchType === "unitName"
          ? { unitName, moveInDate: undefined }
          : matchType === "moveInDate"
            ? { unitName: undefined, moveInDate }
            : { unitName: undefined, moveInDate: undefined }),
      };

      if (data.attachment) {
        // Validate file type before uploading
        let isValidAttachment = await validateFileType(data.attachment);
        if (isValidAttachment) {
          privatePolicyData.attachment_filename = await ClaimsApi.directPrivatePolicyFileUpload({ file: data.attachment });
        }

        privatePolicyData.attachment_desired_filename = data.attachment.name;
        privatePolicyData.attachment_mime_type = data.attachment.type;
        privatePolicyData.isValidFile = isValidAttachment.toString();
      }
      const response = await ClaimsApi.savePrivatePolicy(privatePolicyData);
      if (response.data.errors) {
        for (const errorKey of Object.keys(response.data.errors)) {
          setError(errorKey as keyof PrivatePolicyFormInput, { type: "manual", message: response.data.errors[errorKey].msg });
        }
      } else {
        // Technically more than one private policy can be created, but in practice, there should only be one private policy created with this form
        setPrivatePolicyId((response.data.privatePolicyIds || [])[0] || null);
        if (policyDeductible >= 200000) setFormStep("warning");
        else onPrivatePolicySuccess();
      }
    } catch (error) {
      setAlert("Error submitting form. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const onPrivatePolicySuccess = async () => {
    setFormStep("success");
    setTimeout(() => {
      navigate("/manage-private-policies");
    }, 2500);
  };

  const onNewFiles = (field: ControllerRenderProps<PrivatePolicyFormInput, "attachment">, files: File[]) => {
    if (files.length === 0) {
      setError("attachment", { type: "manual", message: "File is required" });
    } else if (files.length > 1) {
      setError("attachment", { type: "manual", message: "Only one file can be uploaded" });
    } else {
      field.onChange(files[0]);
    }
  };

  const onDeleteFile = (field: ControllerRenderProps<PrivatePolicyFormInput, "attachment">) => {
    field.onChange(null);
  };

  const resetLocationAndUnitMatching = () => {
    setLocationId(null);
    setAddress(null);
    setCity(null);
    setState(null);
    setMatchType("unitName");
    setUnitName(null);
    setMoveInDate(null);
  };

  const onCompleteLocationAndUnitMatching = (data: LocationAndUnitMatchingData) => {
    clearErrors(["address", "city", "state", "unitName", "moveInDate"]);

    if (!data.locationId || !data.address || !data.city || !data.state) {
      throw new Error("Address selection required for location match");
    }

    if (data.matchType === "unitName" && !data.unitName) {
      throw new Error("Unit selection is required");
    }

    if (data.matchType === "moveInDate" && !data.moveInDate) {
      throw new Error("Move-in date is required");
    }

    setLocationId(data.locationId);
    setAddress(data.address);
    setCity(data.city);
    setState(data.state);
    setMatchType(data.matchType);
    setUnitName(data.unitName);
    setMoveInDate(data.moveInDate);
    setShowPolicyExpirationReminderCheckbox(data.tenantPolicyExpirationRemindersEnabled);
  };

  const locationAndUnitMatched = (locationId || (address && city && state)) && (unitName || moveInDate);

  return (
    <NavbarWrapper>
      <SafeLeaseBreadcrumbs
        containerProps={{
          sx: {
            marginBottom: 2,
          },
        }}
        navigate={navigate}
        path={[
          { part: "Home", href: "/" },
          { part: "My Policies", href: "/manage-private-policies" },
          { part: "New", href: "/private-policy" },
        ]}
      />
      {formStep === "warning" || formStep === "plans" ? (
        <Stack
          direction="column"
          alignItems="center"
          justifyContent="center"
          alignSelf="center"
          spacing={1}
          sx={{
            height: "100%",
            maxWidth: {
              xs: 300,
              sm: 650,
            },
          }}
        >
          {formStep === "warning" ? (
            <>
              <img style={{ width: "100px" }} src={PrivatePolicyNotCovered} alt="private policy not covered icon" />
              <Typography variant="h5" fontWeight="bold">
                Warning! You're not covered
              </Typography>
              <AlertBanner
                type={"warning"}
                message={
                  "Your current deductible is too high, therefore not covering your belongings. Supplement your existing private insurance by covering your deductible with a SafeLease plan."
                }
                sx={{
                  cursor: "unset",
                  "&:hover": {
                    boxShadow: "unset",
                  },
                  marginY: "25px !important",
                }}
              />
              <Button onClick={() => setFormStep("plans")} fullWidth color="navy" variant="contained">
                Get supplemental coverage
              </Button>
              <Typography variant="subtitle2" fontWeight="bold">
                Or
              </Typography>
              <Button onClick={onPrivatePolicySuccess} fullWidth color="navy" variant="outlined">
                Continue to submit private policy
              </Button>
            </>
          ) : (
            <>
              <Stack width="100%" alignItems={"start"}>
                <SafeLeaseButton
                  onClick={() => setFormStep("warning")}
                  variant="outlined"
                  startIcon={<KeyboardArrowLeftIcon />}
                  sx={{
                    color: "navy.main",
                    border: "none",
                    "&:hover": {
                      border: "none",
                    },
                  }}
                >
                  Go back
                </SafeLeaseButton>
              </Stack>
              <img style={{ width: "100px" }} src={PrivatePolicyProtectionPlan} alt="private policy protection plan icon" />
              <Typography variant="h5" fontWeight="bold">
                $0 deductible plans available
              </Typography>
              <Typography variant="subtitle2">
                Select a plan offered by your facility. By enrolling today, we will waive your deductible!
              </Typography>
              {!!privatePolicyId && (
                <PrivatePolicyProtectionPlanSelection privatePolicyId={privatePolicyId} onEnrollSuccessful={onPrivatePolicySuccess} />
              )}
            </>
          )}
        </Stack>
      ) : formStep === "success" ? (
        <SuccessMessage
          title={`Congratulations, your belongings in Unit ${unitName} are now protected!`}
          subtitle={`If you have any questions about your protection, contact your facility.`}
        />
      ) : (
        <Container maxWidth="md" sx={{ p: { xs: 1, sm: 4 }, color: "#031E30", borderRadius: 2 }}>
          <Typography variant="h3" gutterBottom align="center" color="#031E30" sx={{ fontWeight: "bold" }}>
            Private policy submission
          </Typography>
          <p>
            Your self-storage facility requires proof of insurance to opt out of its Facility Protection Plan or Tenant Insurance Program.
            Please complete the following form to prevent automatic enrollment in the minimum coverage plan.
          </p>
          <p>Form Instructions:</p>
          <ol>
            <li>Verify your contact information including your full name as listed on the lease and your email address.</li>
            <li>Match the facility address information where you rent and your unit/space numbers.</li>
            <li>
              Enter your insurance information including insurance company, policy deductible, policy number, and expiration date. Note: The
              expiration date may not be more than three (3) years in the future.
            </li>
            <li>
              Upload a copy of the Declarations Page provided by your insurance carrier. Accepted formats include PDF, JPG, DOC, DOCX, PNG,
              and GIF.
            </li>
            <li>Click Submit.</li>
          </ol>

          <Box
            component="form"
            onSubmit={handleSubmit(onSubmit)}
            sx={{ mt: 3, p: { xs: 2, sm: 4 }, backgroundColor: "#152744", color: "white", borderRadius: 1 }}
          >
            <SectionHeader title="Tenant Information" number={1} />
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <Controller
                  name="tenantName"
                  control={control}
                  defaultValue={
                    userAttributes?.given_name && userAttributes?.family_name
                      ? `${userAttributes.given_name} ${userAttributes.family_name}`
                      : ""
                  }
                  rules={{ required: "Tenant Name is required" }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Tenant Name"
                      fullWidth
                      error={!!errors.tenantName}
                      helperText={errors.tenantName ? errors.tenantName.message : ""}
                      variant="filled"
                      InputLabelProps={{ style: { color: "white" } }}
                      InputProps={{
                        disableUnderline: true,
                        style: { backgroundColor: "#152744", color: "white" },
                        sx: { border: "1px solid white", borderRadius: 1 },
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  name="email"
                  control={control}
                  defaultValue={userAttributes?.email || ""}
                  rules={{ required: "Email is required" }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Email"
                      fullWidth
                      error={!!errors.email}
                      helperText={errors.email ? errors.email.message : ""}
                      variant="filled"
                      InputLabelProps={{ style: { color: "white" } }}
                      InputProps={{
                        disableUnderline: true,
                        style: { backgroundColor: "#152744", color: "white" },
                        sx: { border: "1px solid white", borderRadius: 1 },
                      }}
                    />
                  )}
                />
              </Grid>
            </Grid>
            <SectionDivider />
            <SectionHeader
              title="Facility and Unit Information"
              number={2}
              actionIcon={
                locationAndUnitMatched && (
                  <IconButton onClick={resetLocationAndUnitMatching}>
                    <RestartIcon color={"primary"} />
                  </IconButton>
                )
              }
            />
            <Grid container spacing={3}>
              <Grid item xs={12}>
                {!locationAndUnitMatched ? (
                  <LocationAndUnitMatchingComponent onCompleteLocationAndUnitMatching={onCompleteLocationAndUnitMatching} />
                ) : (
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                      <Typography variant="h6" color="white">
                        Facility Address
                      </Typography>
                      <Typography variant="body1" color="white">
                        {address}, {city}, {state}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Typography variant="h6" color="white">
                        {matchType === "unitName" ? "Unit Name" : "Move-in Date"}
                      </Typography>
                      <Typography variant="body1" color="white">
                        {matchType === "unitName" ? unitName : dayjs(moveInDate).format("MM/DD/YY")}
                      </Typography>
                    </Grid>
                  </Grid>
                )}
                {(errors.address || errors.city || errors.state || errors.unitName || errors.moveInDate || errors.locationId) && (
                  <Typography variant="caption" color="error" ml={2}>
                    {errors.address?.message ||
                      errors.city?.message ||
                      errors.state?.message ||
                      errors.unitName?.message ||
                      errors.moveInDate?.message ||
                      errors.locationId?.message}
                  </Typography>
                )}
              </Grid>
            </Grid>
            <SectionDivider />
            <SectionHeader title="Insurance Information" number={3} />
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <Controller
                  name="insuranceCompany"
                  control={control}
                  defaultValue=""
                  rules={{ required: "Insurance Company is required" }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Insurance Company"
                      fullWidth
                      error={!!errors.insuranceCompany}
                      helperText={errors.insuranceCompany ? errors.insuranceCompany.message : ""}
                      variant="filled"
                      InputLabelProps={{ style: { color: "white" } }}
                      InputProps={{
                        disableUnderline: true,
                        style: { backgroundColor: "#152744", color: "white" },
                        sx: { border: "1px solid white", borderRadius: 1 },
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  name="policyNumber"
                  control={control}
                  defaultValue=""
                  rules={{ required: "Policy Number is required" }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Policy Number"
                      fullWidth
                      error={!!errors.policyNumber}
                      helperText={errors.policyNumber ? errors.policyNumber.message : ""}
                      variant="filled"
                      InputLabelProps={{ style: { color: "white" } }}
                      InputProps={{
                        disableUnderline: true,
                        style: { backgroundColor: "#152744", color: "white" },
                        sx: { border: "1px solid white", borderRadius: 1 },
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  name="policyDeductible"
                  control={control}
                  defaultValue=""
                  rules={{ required: "Policy Deductible is required" }}
                  render={({ field }) => (
                    <NumericFormat
                      {...field}
                      allowNegative={false}
                      fixedDecimalScale
                      thousandSeparator=","
                      prefix="$"
                      decimalScale={2}
                      fullWidth
                      sx={{
                        backgroundColor: "#152744",
                        border: "1px solid white",
                        borderRadius: 1,
                        "& .MuiInputBase-root": {
                          color: "white",
                        },
                        "& .MuiInputLabel-root": {
                          color: "white",
                        },
                        "& .MuiFilledInput-underline": {
                          "&:before": {
                            borderBottom: "none",
                          },
                          "&:after": {
                            borderBottom: "none",
                          },
                        },
                      }}
                      label="Policy Deductible"
                      error={!!errors.policyDeductible}
                      variant="filled"
                      customInput={TextField}
                    />
                  )}
                />
                {errors.policyDeductible && (
                  <Typography variant="caption" color="error" ml={2} sx={{ fontFamily: '"Roboto","Helvetica","Arial",sans-serif' }}>
                    {errors.policyDeductible.message}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  name="expiration"
                  control={control}
                  defaultValue={null}
                  rules={{ required: "Expiration Date is required" }}
                  render={({ field }) => (
                    <DatePicker
                      label="Expiration Date"
                      value={field.value}
                      onChange={(date) => field.onChange(date)}
                      slotProps={{
                        textField: {
                          fullWidth: true,
                          error: !!errors.expiration,
                          helperText: errors.expiration ? errors.expiration.message : "",
                          variant: "filled",
                          InputLabelProps: { style: { color: "white" } },
                          InputProps: {
                            disableUnderline: true,
                            style: { backgroundColor: "#152744", color: "white" },
                            sx: { border: "1px solid white", borderRadius: 1, svg: { color: "white" } },
                          },
                        },
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6" color="white">
                  Declarations Page
                </Typography>
                <Controller
                  name="attachment"
                  control={control}
                  rules={{ required: "Declarations Page is required" }}
                  render={({ field }) => (
                    <Card sx={{ borderRadius: 1 }}>
                      {watch("attachment") ? (
                        <FilePreviews files={[watch("attachment")]} deleteFile={() => onDeleteFile(field)} />
                      ) : (
                        <SafeLeaseFileDropzone
                          containerProps={{
                            sx: {
                              border: "1px solid white",
                              borderRadius: 1,
                              backgroundColor: "white",
                              color: "white",
                            },
                          }}
                          accept={{
                            "application/pdf": [".pdf"],
                            "image/jpeg": [".jpeg", ".jpg"],
                            "image/png": [".png"],
                            "application/msword": [".doc", ".docx"],
                            "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [".docx"],
                          }}
                          handleNewFiles={(files) => onNewFiles(field, files)}
                        />
                      )}
                    </Card>
                  )}
                />
                {errors.attachment && (
                  <Typography variant="caption" color="error" ml={2} sx={{ fontFamily: '"Roboto","Helvetica","Arial",sans-serif' }}>
                    {errors.attachment.message}
                  </Typography>
                )}
              </Grid>
              {showPolicyExpirationReminderCheckbox && (
                <Grid item xs={12}>
                  <Controller
                    name="policyExpirationRemindersEnabled"
                    control={control}
                    render={({ field }) => (
                      <FormControlLabel
                        label="Remind me 30 days before my policy will expire via email"
                        control={
                          <Checkbox
                            {...field}
                            checked={field.value}
                            sx={{ color: "white", textDecorationColor: "white" }}
                            onChange={(e) => field.onChange(e.target.checked)}
                          />
                        }
                        slotProps={{
                          typography: {
                            color: "white",
                          },
                        }}
                      />
                    )}
                  />
                </Grid>
              )}
              <Grid container item xs={12} justifyContent="center">
                <Grid item xs={12} md={6}>
                  <Button type="submit" disabled={loading} variant="contained" fullWidth sx={{ my: 2, backgroundColor: "#1976d2" }}>
                    {loading ? <CircularProgress size={24} sx={{ display: "inline-block", ml: 1 }} /> : "Submit"}
                  </Button>
                </Grid>
                {!!alert && (
                  <Grid item xs={12}>
                    <Alert severity="warning" color="error">
                      {alert}
                    </Alert>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Box>
          <Typography variant="body2" align="center" sx={{ mt: 3 }}>
            "Important Disclosure: By submitting proof of coverage through www.privatepolicy.com, you give permission for SafeLease
            Insurance Services LLC or its agents to verify that (1) the name on the policy or other proof of coverage matches the tenant
            name associated with the self-storage unit you are renting at the applicable facility, (2) the policy number provided matches
            the policy number on your declarations page, and (3) the policy has not expired, as evidenced by the policy term and expiration
            date. Acceptance or rejection of the proof of coverage submitted will be determined exclusively by SafeLease's review of the
            foregoing three factors."
          </Typography>
          <Typography variant="body2" align="center" sx={{ mt: 3 }}>
            <a href="https://info.safelease.com/safelease-privacy-policy-2" target="_blank" rel="noopener noreferrer">
              Privacy Policy
            </a>
          </Typography>
        </Container>
      )}
    </NavbarWrapper>
  );
}

const SectionHeader = ({ title, number, actionIcon = undefined }: { title: string; number: number; actionIcon?: any }) => {
  return (
    <Stack alignItems="center" direction="row" spacing={1.5} sx={{ height: "30px", marginBottom: "35px" }}>
      <Typography
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "25px",
          height: "25px",
          borderRadius: "50%",
          background: "#1976d2",
        }}
        color="white"
      >
        {number}
      </Typography>
      <Typography variant="h5" color="white">
        {title}
      </Typography>
      {actionIcon}
    </Stack>
  );
};

const SectionDivider = styled(Divider)({
  color: "white",
  borderBottomWidth: 2,
  marginTop: "30px",
  marginBottom: "30px",
});

export { PrivatePolicyForm };
