import { Modal } from "@mui/material";
import { useTenantActionModalStore } from "./useTenantActionModalStore";
import { TenantActionTypes } from "@safelease/service-utilities/enums";
import FormQuestionResponseModalContent from "./FormQuestionResponseModalContent/FormQuestionResponseModalContent";
import { AdHocRequestModalContent } from "./AdHocRequestModalContent";
import { FileUploadModalContent } from "./FileUploadModalContent";

type TenantActionModalProps = {};
/**
 * This modal is very important
 * It is going to be shared amongst all the messages and tenant actions that require a modal
 * The content will dynamically change based on values in the store
 * different buttons will configure the modal to show different content
 */
function TenantActionModal({}: TenantActionModalProps) {
  const { tenantActionModalOpen, modalContentData, setTenantActionModalOpen } = useTenantActionModalStore((state) => ({
    tenantActionModalOpen: state.tenantActionModalOpen,
    modalContentData: state.modalContentData,
    setTenantActionModalOpen: state.setTenantActionModalOpen,
  }));

  return (
    <Modal
      open={tenantActionModalOpen}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      onClose={() => setTenantActionModalOpen(false)}
    >
      <>
        {modalContentData?.type === TenantActionTypes.FORM_QUESTION_ANSWER && <FormQuestionResponseModalContent />}
        {modalContentData?.type === TenantActionTypes.AD_HOC_REQUEST && <AdHocRequestModalContent />}
        {modalContentData?.type === TenantActionTypes.UPLOAD_BILLING_LEDGER && (
          <FileUploadModalContent
            requestMessage={modalContentData.metadata?.[TenantActionTypes.UPLOAD_BILLING_LEDGER]?.requestMessage || ""}
          />
        )}
        {modalContentData?.type === TenantActionTypes.UPLOAD_PROTECTION_PLAN_ADDENDUM && (
          <FileUploadModalContent
            requestMessage={modalContentData.metadata?.[TenantActionTypes.UPLOAD_PROTECTION_PLAN_ADDENDUM]?.requestMessage || ""}
          />
        )}
        {modalContentData?.type === TenantActionTypes.UPLOAD_PROOF_OF_OWNERSHIP_FILES && (
          <FileUploadModalContent
            requestMessage={modalContentData.metadata?.[TenantActionTypes.UPLOAD_PROOF_OF_OWNERSHIP_FILES]?.requestMessage || ""}
          />
        )}
        {modalContentData?.type === TenantActionTypes.UPLOAD_PROOF_OF_PURCHASE_FILES && (
          <FileUploadModalContent
            requestMessage={modalContentData.metadata?.[TenantActionTypes.UPLOAD_PROOF_OF_PURCHASE_FILES]?.requestMessage || ""}
          />
        )}
        {modalContentData?.type === TenantActionTypes.UPLOAD_TYPE_SPECIFIC_ATTACHMENT && (
          <FileUploadModalContent
            requestMessage={modalContentData.metadata?.[TenantActionTypes.UPLOAD_TYPE_SPECIFIC_ATTACHMENT]?.requestMessage || ""}
          />
        )}
      </>
    </Modal>
  );
}

export { TenantActionModal };
