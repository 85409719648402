import { GetTenantActionsResponse } from "@safelease/service-utilities";
import { TenantActionTypes } from "@safelease/service-utilities/enums";
import { FileUploadRequestTodoItem } from "./FileUploadRequestTodoItem";
import { SettlementOfferTodoItem } from "./SettlementOfferTodoItem";
import { FormQuestionAnswerTodoItem } from "./FormQuestionAnswerTodoItem";
import { AdHocRequestTodoItem } from "./AdHocRequestTodoItem";

/**
 * The idea for this component is to behave like a controller for which todo item component to render based on the tenant action type
 */
type TodoItemProps = {
  tenantAction: GetTenantActionsResponse;
};

function TodoItem({ tenantAction }: TodoItemProps) {
  if (tenantAction.type === TenantActionTypes.UPLOAD_BILLING_LEDGER) {
    return (
      <FileUploadRequestTodoItem
        tenantAction={tenantAction}
        requestMessage={tenantAction.metadata?.[TenantActionTypes.UPLOAD_BILLING_LEDGER]?.requestMessage!}
      />
    );
  } else if (tenantAction.type === TenantActionTypes.UPLOAD_PROTECTION_PLAN_ADDENDUM) {
    return (
      <FileUploadRequestTodoItem
        tenantAction={tenantAction}
        requestMessage={tenantAction.metadata?.[TenantActionTypes.UPLOAD_PROTECTION_PLAN_ADDENDUM]?.requestMessage!}
      />
    );
  } else if (tenantAction.type === TenantActionTypes.UPLOAD_PROOF_OF_OWNERSHIP_FILES) {
    return (
      <FileUploadRequestTodoItem
        tenantAction={tenantAction}
        requestMessage={tenantAction.metadata?.[TenantActionTypes.UPLOAD_PROOF_OF_OWNERSHIP_FILES]?.requestMessage!}
      />
    );
  } else if (tenantAction.type === TenantActionTypes.UPLOAD_PROOF_OF_PURCHASE_FILES) {
    return (
      <FileUploadRequestTodoItem
        tenantAction={tenantAction}
        requestMessage={tenantAction.metadata?.[TenantActionTypes.UPLOAD_PROOF_OF_PURCHASE_FILES]?.requestMessage!}
      />
    );
  } else if (tenantAction.type === TenantActionTypes.UPLOAD_TYPE_SPECIFIC_ATTACHMENT) {
    return (
      <FileUploadRequestTodoItem
        tenantAction={tenantAction}
        requestMessage={tenantAction.metadata?.[TenantActionTypes.UPLOAD_TYPE_SPECIFIC_ATTACHMENT]?.requestMessage!}
      />
    );
  } else if (tenantAction.type === TenantActionTypes.SETTLEMENT_OFFER) {
    return <SettlementOfferTodoItem tenantAction={tenantAction} />;
  } else if (tenantAction.type === TenantActionTypes.FORM_QUESTION_ANSWER) {
    return <FormQuestionAnswerTodoItem tenantAction={tenantAction} />;
  } else if (tenantAction.type === TenantActionTypes.AD_HOC_REQUEST) {
    return <AdHocRequestTodoItem tenantAction={tenantAction} />;
  }
  //Should never reach here
  return <></>;
}

export default TodoItem;
