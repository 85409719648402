import dayjs from "dayjs";

export function daysSince(date: string) {
  // This will count the number of calendar days since the given date compared to today.
  // if the given date is 2024-08-10 23:59 and today is 2024-08-12 00:01, the difference is 2 days because they fall on different calendar days (10th to 12th).
  const today = dayjs();
  const dateToCompare = dayjs(date);

  return today.diff(dateToCompare, "day");
}
