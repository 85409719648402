import { Box, Stack, Typography } from "@mui/material";
import { SafeLeaseBreadcrumbs, SafeLeaseButton } from "@safelease/components";
import { useNavigate } from "react-router-dom";
import { ClaimStatus } from "@safelease/service-utilities/enums";
import { ChatBox } from "./ChatBox/ChatBox";
import ToDoItemsContainer from "./ToDoItemsContainer/ToDoItemsContainer";
import { useSubmittedClaimDetailPageStore } from "./useSubmittedClaimDetailsPageStore";
import { shortUuid } from "@safelease/components/src/utils/formatters";
import { TenantActionModal } from "./TenantActionModal";
import { AvatarDropdown } from "../../../shared/AvatarDropdown";
import { useState } from "react";
import { ClaimsApi } from "../../../utils/apiInstances/ClaimsApiInstance";
import { enqueueSnackbar } from "notistack";
import { WithdrawClaimConfirmationModal } from "./ToDoItemsContainer/WithdrawClaimConfirmationModal";
import { ChevronProgressBar } from "./ChevronProgressBar";
import { penniesToDollars } from "../../../utils/formatters";
import _ from "lodash";
import { daysSince } from "../../../utils/dateHelpers";

type SubmittedClaimDetailsPageProps = {};

function SubmittedClaimDetailsPage({}: SubmittedClaimDetailsPageProps) {
  const navigate = useNavigate();
  const claim = useSubmittedClaimDetailPageStore((state) => state.claim);
  const [withdrawClaimModalOpen, setWithdrawClaimModalOpen] = useState<boolean>(false);
  const isClosed = Boolean(claim?.closedAt);
  const isActionNeeded = useSubmittedClaimDetailPageStore((state) => state.isActionNeeded);

  const claimedLoss = penniesToDollars(_.sumBy(claim?.lineItems, "requestedAmount"));
  const daysSinceClaimClosed = claim?.closedAt ? daysSince(claim.closedAt) : null;

  const withdrawClaimButtonPressed = async () => {
    try {
      if (claim) {
        await ClaimsApi.updateClaim(claim.id, {
          status: ClaimStatus.ClosedByTenant,
        });
        enqueueSnackbar("Claim closed successfully.", { variant: "success" });
      }
    } catch (error) {
      console.log(error);
      enqueueSnackbar("Error closing claim, please try again.", { variant: "error" });
    }
  };

  return (
    <Stack flex={1} overflow="auto" padding={2} spacing={2}>
      <Stack direction="row" justifyContent="space-between" marginBottom={2}>
        <SafeLeaseBreadcrumbs navigate={navigate} path={[{ part: "Back to claims", href: "/manage-claims" }]} />
        <AvatarDropdown dropdownOptions={[{ label: "Logout", cb: () => navigate("/logout") }]} />
      </Stack>
      {/* Only shows in desktop view */}
      <Stack
        direction="row"
        spacing={2}
        sx={{
          marginBottom: { xs: 2, sm: 4 },
          justifyContent: "space-between",
          display: { xs: "none", sm: "flex" },
        }}
      >
        {/* Box used for stacking typography vertically and spacing buttons */}
        <Box>
          <Typography sx={{ fontWeight: 600, fontSize: 14 }}>
            {claimedLoss} Claim #{shortUuid(claim?.id || "")}
          </Typography>
          <Typography
            sx={{ fontWeight: 200, fontSize: 14 }}
          >{`${claim?.claimType?.name} claim for unit ${claim?.unit?.safeleaseLocationDetail?.address}`}</Typography>
        </Box>
        {!isClosed && (
          <Box>
            <WithdrawClaimConfirmationModal
              onWithdraw={withdrawClaimButtonPressed}
              modalOpen={withdrawClaimModalOpen}
              setModalOpen={setWithdrawClaimModalOpen}
            />
            <SafeLeaseButton onClick={() => setWithdrawClaimModalOpen(true)} variant="contained" color="navy" sx={{ height: "75%" }}>
              Withdraw claim
            </SafeLeaseButton>
            {/* This is part of the design but we need to create before adding in, commenting out a shell for now */}
            {/* <Button variant="outlined" color="navy" onClick={() => navigate("")}>
                Edit Claim
              </Button> */}
          </Box>
        )}
      </Stack>
      {/* Displays in both views */}
      <Stack direction="row" alignItems={"center"} justifyContent={{ xs: "space-around", sm: "flex-start" }}>
        <Typography variant="h6">
          {isClosed ? "This claim is now closed" : isActionNeeded ? "Action required for your claim" : "We are working on your claim"}
        </Typography>
      </Stack>
      {claim && <ChevronProgressBar claim={claim} isActionNeeded={isActionNeeded} />}
      {/* if the claim is closed- this will display a link for support below the chevron bar. */}
      {!!daysSinceClaimClosed && daysSinceClaimClosed > 30 && (
        <Stack direction="row" justifyContent="center" marginTop={4}>
          <Typography>
            This claim has been closed for more than 30 days. please contact <a href="mailto:support@safelease.com">support here</a> if you
            require further assistance.
          </Typography>
        </Stack>
      )}
      <Stack direction="row" flex={1} overflow="auto">
        {!isClosed && <ToDoItemsContainer />}
        <ChatBox />
      </Stack>
      {/* This modal will be shared by messages and tenant actions so that we only render one modal and the content can just change based on how it was triggered to open */}
      <TenantActionModal />
    </Stack>
  );
}

export { SubmittedClaimDetailsPage };
