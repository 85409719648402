import { UnitSelectionModalStep, useUnitSelectionModalStore } from "../useUnitSelectionModalStore";
import { BorderedCheckbox } from "../../../shared/BorderedCheckbox";
import { useState } from "react";
import { Box, Stack, Theme, Typography } from "@mui/material";
import { SafeLeaseButton } from "@safelease/components";
import { ChevronLeft } from "@mui/icons-material";
import { ClaimsApi } from "../../../utils/apiInstances/ClaimsApiInstance";
import * as Sentry from "@sentry/react";
import { useNavigate } from "react-router-dom";
import { enqueueSnackbar } from "notistack";
import { GetSafeleaseLocationForGooglePlaceIdResponse, SafeleaseLeaseMatchingData } from "@safelease/service-utilities";

export type UnitSelectionVerificationData = {
  leaseMatchingData: SafeleaseLeaseMatchingData[] | null;
  selectedLocation: GetSafeleaseLocationForGooglePlaceIdResponse | null;
  selectedUnitId: number;
};

type UnitSelectionStepProps = {
  onCompleteVerificationButtonClickedOverride?: (data: UnitSelectionVerificationData) => void;
  allowSkipUnitSelection?: boolean;
};

function UnitSelectionStep({ onCompleteVerificationButtonClickedOverride, allowSkipUnitSelection = false }: UnitSelectionStepProps) {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { leaseMatchingData, setCurrentStep, setProgress, selectedLocation, userProvidedGooglePlaceId, setVerifySkipModalOpen } =
    useUnitSelectionModalStore((state) => ({
      leaseMatchingData: state.leaseMatchingData,
      setCurrentStep: state.setCurrentStep,
      setProgress: state.setProgress,
      selectedLocation: state.selectedLocation,
      userProvidedGooglePlaceId: state.userProvidedGooglePlaceId,
      setVerifySkipModalOpen: state.setVerifySkipModalOpen,
    }));
  const [selectedUnitId, setSelectedUnitId] = useState<number | null>(null);

  const onCompleteVerificationButtonClicked = async () => {
    setIsLoading(true);

    try {
      const selectedLeaseData = leaseMatchingData?.find((leaseData) => leaseData.Unit.id === selectedUnitId);
      if (!selectedLeaseData) throw new Error("Selected lease data not found");
      if (!selectedLocation) throw new Error("Missing selected location data");
      if (!userProvidedGooglePlaceId) throw new Error("Missing user provided google place id");
      const newUnitResponse = await ClaimsApi.createUnit({
        name: selectedLeaseData.Unit.name,
        userProvidedGooglePlaceId,
        matchedAllSafeleaseData: true,
        safeleaseMatchData: {
          safeleaseLeaseId: selectedLeaseData.id,
          safeleaseUnitId: selectedLeaseData.Unit.id,
          safeleasePolicies: selectedLeaseData.Policies,
          safeleaseLocationDetails: selectedLocation,
        },
      });
      const newUnit = newUnitResponse.data;
      const claimResponse = await ClaimsApi.createClaim(newUnit.id);
      navigate(`/claim/${claimResponse.data.id}`);
    } catch (error) {
      Sentry.captureException(error);
      enqueueSnackbar("There was an error completing the verification. Please try again", { variant: "error" });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Stack spacing={1} width="100%">
      <Box>
        <SafeLeaseButton
          sx={{ color: "grey.A200" }}
          onClick={() => {
            setCurrentStep(UnitSelectionModalStep.EMAIL_UNIT_LOOKUP);
            setProgress(50);
          }}
          startIcon={<ChevronLeft sx={{ color: "navy.main" }} />}
        >
          Go back
        </SafeLeaseButton>
      </Box>
      <Typography sx={{ fontSize: 14, fontWeight: 500 }}>👋🏼 We found these units linked to the email provided</Typography>
      <Stack spacing={1.5} sx={{ overflow: "auto", paddingY: 1 }}>
        {leaseMatchingData?.map((leaseData) => (
          <BorderedCheckbox
            disabled={!!selectedUnitId && selectedUnitId !== leaseData.Unit.id}
            key={leaseData.id}
            value={selectedUnitId === leaseData.Unit.id}
            label={leaseData.Unit.name}
            setValue={(value) => {
              if (value) {
                setSelectedUnitId(leaseData.Unit.id);
              } else {
                setSelectedUnitId(null);
              }
            }}
          />
        ))}
        {allowSkipUnitSelection && (
          <Typography
            onClick={() => setVerifySkipModalOpen(true)}
            sx={{
              color: (theme: Theme) => theme.palette.grey.A200,
              cursor: "pointer",
              fontSize: 14,
              fontWeight: 500,
              alignSelf: "center",
            }}
          >
            I don't see my unit
          </Typography>
        )}
      </Stack>
      {selectedUnitId && (
        <SafeLeaseButton
          loading={isLoading}
          onClick={() => {
            if (onCompleteVerificationButtonClickedOverride) {
              onCompleteVerificationButtonClickedOverride({
                leaseMatchingData,
                selectedLocation,
                selectedUnitId,
              });
            } else {
              onCompleteVerificationButtonClicked();
            }
          }}
          variant="contained"
          color="navy"
        >
          Complete verification
        </SafeLeaseButton>
      )}
    </Stack>
  );
}

export { UnitSelectionStep };
