import { pdfjs } from "react-pdf";
import { useState } from "react";
import { ExpandedPDFModal } from "./ExpandedPDFModal";
import { ImagePreview } from "./ImagePreview";
import { SxProps } from "@mui/material";

// Do not remove, this is the script that loads PDFs
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

interface ImageViewerProps {
  file: string;
  fileName: string;
  previewContainerSx?: SxProps;
}

const isMobileScreen = () => window.innerWidth <= 600;
/* Display a PDF, with the ability to scroll, zoom, and navigate pages */
export function ImageViewer({ file, previewContainerSx, fileName }: ImageViewerProps) {
  const [displayExpandedModal, setDisplayExpandedModal] = useState<boolean>(false);

  const handleImageClick = () => {
    if (isMobileScreen()) {
      // Trigger download function if the screen size is less than 600px
      window.open(file, "_blank");
    } else {
      // Open the expanded modal if the screen size is larger than 600px
      setDisplayExpandedModal(true);
    }
  };

  return (
    <>
      <ImagePreview fileName={fileName} previewContainerSx={previewContainerSx} file={file} handleExpandImage={() => handleImageClick()} />
      <ExpandedPDFModal open={displayExpandedModal} handleClose={() => setDisplayExpandedModal(false)} file={file} fileName={fileName} />
    </>
  );
}
