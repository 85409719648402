import { Box, FormControlLabel, Radio, RadioGroup, TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import { useState } from "react";

type QuestionProps = {
  initialValue: string | null;
  questionType: string;
  setFormQuestionResponses: (key: string, value: string | null) => void;
  formQuestionDefId: string;
};

function Question({ initialValue, questionType, setFormQuestionResponses, formQuestionDefId }: QuestionProps) {
  const [value, setValue] = useState<string | null>(initialValue);
  const [date, setDate] = useState<Dayjs | null>(initialValue ? dayjs(initialValue) : null); // for datepicker
  const [open, setOpen] = useState(false); // for datepicker
  return (
    <>
      {questionType === "date" && (
        <DatePicker
          value={date}
          onChange={(newDate: Dayjs | null) => {
            const formattedStringData = newDate?.format("MM/DD/YYYY") || null;
            setDate(newDate);
            setFormQuestionResponses(formQuestionDefId, formattedStringData);
          }}
          open={open}
          onClose={() => setOpen(false)}
          slotProps={{
            textField: {
              onClick: () => setOpen(true),
            },
          }}
        />
      )}
      {questionType === "string" && (
        <TextField
          onChange={(e) => {
            setValue(e.target.value);
            setFormQuestionResponses(formQuestionDefId, e.target.value);
          }}
          value={value}
          sx={{ mt: "1rem" }}
        />
      )}
      {questionType === "boolean" && (
        <Box>
          <RadioGroup
            row
            onChange={(e) => {
              setValue(e.target.value);
              setFormQuestionResponses(formQuestionDefId, e.target.value);
            }}
            value={value}
            sx={{
              mt: "0.5rem",
              ml: "0.75rem",
            }}
          >
            <FormControlLabel
              value="Yes"
              control={<Radio />}
              sx={{ border: "1px solid black", borderRadius: "7px", width: "6em", height: 40 }}
              label="Yes"
            />
            <FormControlLabel
              value="No"
              control={<Radio />}
              sx={{ border: "1px solid black", borderRadius: "7px", width: "6em", height: 40, ml: "0.5rem" }}
              label="No"
            />
          </RadioGroup>
        </Box>
      )}
    </>
  );
}

export { Question };
