import { GetClaimMessageResponse } from "@safelease/service-utilities";
import { QueryObserverResult, RefetchOptions } from "@tanstack/react-query";
import { create } from "zustand";

type ChatBoxStore = {
  // This is an easy way to access the messagesQuery.refetch from a child component that is deeply nested in the component tree from the messagesQuery
  // The UploadFileMessage component needs this to refetch the messages after the user uploads a file
  refetchMessages: (options?: RefetchOptions | undefined) => Promise<QueryObserverResult<GetClaimMessageResponse[], Error> | void>;
  setRefetchMessages: (
    refetchMessages: (options?: RefetchOptions | undefined) => Promise<QueryObserverResult<GetClaimMessageResponse[], Error> | void>,
  ) => void;
};

export const useChatBoxStore = create<ChatBoxStore>()((set) => ({
  refetchMessages: async () => {},
  setRefetchMessages: (refetchMessages) => {
    set({ refetchMessages });
  },
}));
