import React from "react";
import { Divider, Stack, Typography } from "@mui/material";
import { NavbarWrapper } from "../MainDashboardRouter/NavbarWrapper.tsx";
import { SafeLeaseBreadcrumbs, SafeLeaseButton } from "@safelease/components";
import { useNavigate } from "react-router";
import { useQuery } from "@tanstack/react-query";
import { SafeleasePrivatePolicy } from "@safelease/service-utilities";
import { ClaimsApi } from "../utils/apiInstances/ClaimsApiInstance.ts";
import AddIcon from "@mui/icons-material/Add";
import { PrivatePolicyList } from "./PrivatePolicyList.tsx";
import { getCalculatedPrivatePolicyStatus } from "../utils/helpers/privatePolicy.ts";
import { AlertBanner } from "../shared/AlertBanner.tsx";

type PrivatePolicyFormProps = {};

function ManagePrivatePolicies({}: PrivatePolicyFormProps) {
  const navigate = useNavigate();

  const privatePoliciesQuery = useQuery<SafeleasePrivatePolicy[]>({
    queryKey: ["private-policies"],
    queryFn: async () => {
      const response = await ClaimsApi.getPrivatePolicies();
      return response.data.privatePolicies;
    },
  });

  const activePolicies = privatePoliciesQuery.data?.filter((policy) => getCalculatedPrivatePolicyStatus(policy).statusGroup === "active");
  const submittedPolicies = privatePoliciesQuery.data?.filter(
    (policy) => getCalculatedPrivatePolicyStatus(policy).statusGroup === "submitted",
  );
  const expiredPolicies = privatePoliciesQuery.data?.filter((policy) => getCalculatedPrivatePolicyStatus(policy).statusGroup === "expired");
  const enrolledPolicies = privatePoliciesQuery.data?.filter(
    (policy) => getCalculatedPrivatePolicyStatus(policy).statusGroup === "tenantEnrollOverride",
  );

  // Private policies that have been rejected in the last 14 days
  const newlyRejectedPolicies = privatePoliciesQuery.data?.filter(
    (policy: SafeleasePrivatePolicy) => getCalculatedPrivatePolicyStatus(policy).isRecentlyRejected,
  );

  return (
    <NavbarWrapper>
      <Stack sx={{ flexDirection: "row" }} justifyContent="space-between" alignItems="center">
        <SafeLeaseBreadcrumbs
          containerProps={{
            sx: {
              marginBottom: 2,
            },
          }}
          navigate={navigate}
          path={[
            { part: "Home", href: "/" },
            { part: "My Policies", href: "/manage-private-policies" },
          ]}
        />
        <SafeLeaseButton
          onClick={() => navigate("/private-policy")}
          variant="outlined"
          startIcon={<AddIcon />}
          sx={{
            color: "navy.main",
            border: "none",
            "&:hover": {
              border: "none",
            },
          }}
        >
          Add policy
        </SafeLeaseButton>
      </Stack>
      {newlyRejectedPolicies?.map((policy) => (
        <Stack key={policy.id} sx={{ flexDirection: "row" }} justifyContent="space-between" alignItems="center">
          <AlertBanner
            type={"error"}
            message={`Your private policy for Unit ${policy.unitName} got rejected. Please re-submit your policy to avoid automatic enrollment in your facility’s insurance plans.`}
            onClick={() => navigate("/private-policy")}
          />
        </Stack>
      ))}
      {privatePoliciesQuery.data && privatePoliciesQuery.data.length > 0 && (
        <Stack spacing={2} divider={<Divider />}>
          {!!activePolicies?.length && (
            <Stack>
              <Typography variant="subtitle2">Active policies</Typography>
              <PrivatePolicyList
                /* sort by expiration date ascending */
                privatePolicies={activePolicies.sort((a, b) => new Date(a.expiration).getTime() - new Date(b.expiration).getTime())}
              />
            </Stack>
          )}
          {!!submittedPolicies?.length && (
            <Stack>
              <Typography variant="subtitle2">Submitted policies</Typography>
              <PrivatePolicyList
                /* sort by rejected policies and then date submitted desc */
                privatePolicies={submittedPolicies.sort((a, b) => {
                  const { reviewStatus: aReviewStatus } = getCalculatedPrivatePolicyStatus(a);
                  const { reviewStatus: bReviewStatus } = getCalculatedPrivatePolicyStatus(b);
                  if (aReviewStatus === "rejected" && bReviewStatus !== "rejected") return -1;
                  if (aReviewStatus !== "rejected" && bReviewStatus === "rejected") return 1;
                  return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
                })}
              />
            </Stack>
          )}
          {!!expiredPolicies?.length && (
            <Stack>
              <Typography variant="subtitle2">Expired policies</Typography>
              <PrivatePolicyList
                /* sort by expiration date descending */
                privatePolicies={expiredPolicies.sort((a, b) => new Date(b.expiration).getTime() - new Date(a.expiration).getTime())}
              />
            </Stack>
          )}
          {!!enrolledPolicies?.length && (
            <Stack>
              <Typography variant="subtitle2">Enrolled policies</Typography>
              <PrivatePolicyList
                /* sort by archived date descending */
                privatePolicies={enrolledPolicies.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())}
              />
            </Stack>
          )}
        </Stack>
      )}
    </NavbarWrapper>
  );
}

export { ManagePrivatePolicies };
