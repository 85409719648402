import { Alert, Box, Card, Modal, Stack, Tooltip, Typography } from "@mui/material";
import { SafeLeaseButton } from "@safelease/components";
import { useUnitSelectionModalStore } from "../useUnitSelectionModalStore";
import { StyledTextField } from "../../../shared/StyledTextField";
import { useState } from "react";
import { ClaimsApi } from "../../../utils/apiInstances/ClaimsApiInstance";
import { useNavigate } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { enqueueSnackbar } from "notistack";

type VerifySkipUnitMatchingModalProps = {};

function VerifySkipUnitMatchingModal({}: VerifySkipUnitMatchingModalProps) {
  const navigate = useNavigate();
  const [userProvidedUnitNumber, setUserProvidedUnitNumber] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);
  const { setVerifySkipModalOpen, userProvidedGooglePlaceId, selectedLocation } = useUnitSelectionModalStore((state) => ({
    setVerifySkipModalOpen: state.setVerifySkipModalOpen,
    userProvidedGooglePlaceId: state.userProvidedGooglePlaceId,
    selectedLocation: state.selectedLocation,
  }));
  const closeModal = () => {
    setVerifySkipModalOpen(false);
  };

  const disableSkipButton = !userProvidedUnitNumber.trim();

  const onSkipButtonClicked = async () => {
    setIsLoading(true);
    try {
      if (!userProvidedGooglePlaceId) throw new Error("Missing user provided google place id");
      const newUnitResponse = await ClaimsApi.createUnit({
        name: userProvidedUnitNumber,
        userProvidedGooglePlaceId,
        matchedAllSafeleaseData: false,
        ...(selectedLocation && {
          // if we were able to match a safelease location include it
          safeleaseMatchData: {
            safeleaseLocationDetails: selectedLocation,
          },
        }),
      });
      const newUnit = newUnitResponse.data;
      const claimResponse = await ClaimsApi.createClaim(newUnit.id);
      navigate(`/claim/${claimResponse.data.id}`);
    } catch (error) {
      Sentry.captureException(error);
      enqueueSnackbar("There was an error skipping unit matching. Please try again", { variant: "error" });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal open={true} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }} onClose={closeModal}>
      <Card>
        <Stack padding={2} alignItems="center" spacing={2} sx={{ maxWidth: "95vw" }}>
          <Typography sx={{ fontSize: 24, fontWeight: 500 }}>Skip unit matching?</Typography>
          <Stack spacing={0.5} alignItems="center">
            <Typography sx={{ fontSize: 14, fontWeight: 500 }}> Are you sure you want to proceed without matching your unit?</Typography>
            <Typography sx={{ fontSize: 14, fontWeight: 500 }}>
              If you believe there has been a mistake we strongly urge you to email support for assistance.
            </Typography>
            <Typography sx={{ fontSize: 16, fontWeight: 500 }}>
              <a style={{ color: "black" }} href="mailto:support@safelease.com">
                support@safelease.com
              </a>
            </Typography>
          </Stack>
          <Alert severity="warning" color="error">
            Submitting a claim without properly matching your unit can severely delay the processing of your claim.
          </Alert>
          <Stack width="100%">
            <Typography sx={{ fontSize: 10 }}>* required</Typography>
            <StyledTextField
              value={userProvidedUnitNumber}
              setValue={setUserProvidedUnitNumber}
              placeholder="Manually enter unit/space number"
            />
          </Stack>
          <Stack direction="row" spacing={1}>
            <SafeLeaseButton onClick={closeModal} variant="outlined" color="navy" sx={{ width: 70 }}>
              Cancel
            </SafeLeaseButton>
            <Tooltip title={disableSkipButton ? "Please provide a unit/space number to continue" : ""} placement="top">
              <Box>
                <SafeLeaseButton
                  onClick={onSkipButtonClicked}
                  loading={isLoading}
                  disabled={disableSkipButton || isLoading}
                  variant="contained"
                  color="error"
                  sx={{ width: 70 }}
                >
                  Skip
                </SafeLeaseButton>
              </Box>
            </Tooltip>
          </Stack>
        </Stack>
      </Card>
    </Modal>
  );
}

export { VerifySkipUnitMatchingModal };
