import { Alert, Box, Card, Grid, Modal, Stack, TextField, Theme, Tooltip, Typography } from "@mui/material";
import { SafeLeaseButton } from "@safelease/components";
import React, { useState } from "react";
import { useUnitSelectionModalStore } from "../claim-portal/UnitSelectionModal/useUnitSelectionModalStore.ts";
import { DatePicker } from "@mui/x-date-pickers";
import { LocationAndUnitMatchingData } from "./PrivatePolicyForm.tsx";

type SkipLocationAndUnitMatchingModalProps = {
  onCompleteLocationAndUnitMatching: (data: LocationAndUnitMatchingData) => void;
};

function SkipLocationAndUnitMatchingModal({ onCompleteLocationAndUnitMatching }: SkipLocationAndUnitMatchingModalProps) {
  const [onSkipError, setOnSkipError] = useState<string>("");
  const [userProvidedUnitNumber, setUserProvidedUnitNumber] = useState<string>("");
  const [userProvidedMoveInDate, setUserProvidedMoveInDate] = useState<Date | null>(null);
  const [unitMatchingMethod, setUnitMatchingMethod] = useState<"unitName" | "moveInDate">("unitName");

  const { setVerifySkipModalOpen, selectedLocation } = useUnitSelectionModalStore((state) => ({
    setVerifySkipModalOpen: state.setVerifySkipModalOpen,
    selectedLocation: state.selectedLocation,
  }));

  const closeModal = () => {
    setVerifySkipModalOpen(false);
  };

  const disableSkipButton =
    (unitMatchingMethod === "unitName" && !userProvidedUnitNumber.trim()) ||
    (unitMatchingMethod === "moveInDate" && !userProvidedMoveInDate);

  const onSkipButtonClicked = async () => {
    if (disableSkipButton) {
      setOnSkipError("Please provide all required fields to continue");
      return;
    }

    onCompleteLocationAndUnitMatching({
      locationId: selectedLocation?.locationId || null,
      address: selectedLocation?.address || null,
      city: selectedLocation?.city || null,
      state: selectedLocation?.state || null,
      matchType: unitMatchingMethod,
      unitName: userProvidedUnitNumber || null,
      moveInDate: userProvidedMoveInDate || null,
      tenantPolicyExpirationRemindersEnabled: selectedLocation?.tenantPolicyExpirationRemindersEnabled || false,
    });
  };

  return (
    <Modal open={true} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }} onClose={closeModal}>
      <Card>
        <Stack padding={2} alignItems="center" spacing={2} sx={{ maxWidth: "95vw" }}>
          <Typography sx={{ fontSize: 24, fontWeight: 500 }}>Skip unit matching?</Typography>
          <Stack spacing={0.5} alignItems="center">
            <Typography sx={{ fontSize: 14, fontWeight: 500 }}>Are you sure you want to proceed without matching your unit?</Typography>
            <Typography sx={{ fontSize: 14, fontWeight: 500 }}>
              If you believe there has been a mistake we strongly urge you to email support for assistance.
            </Typography>
            <Typography sx={{ fontSize: 16, fontWeight: 500 }}>
              <a style={{ color: "black" }} href="mailto:support@safelease.com">
                support@safelease.com
              </a>
            </Typography>
          </Stack>
          <Alert severity="warning" color="error">
            Submitting a private policy without properly matching your unit can affect the processing of your policy.
          </Alert>
          <Stack width="100%">
            <Grid container spacing={1}>
              <Grid item xs={12}>
                {unitMatchingMethod === "unitName" ? (
                  <TextField
                    value={userProvidedUnitNumber}
                    onChange={(e) => setUserProvidedUnitNumber(e.target.value)}
                    helperText={"Separate multiple units with commas (i.e. 310, 311, 312)"}
                    fullWidth
                    placeholder="Unit/space number(s)"
                    size="small"
                    sx={{
                      "& .MuiInputBase-root": {
                        color: "black", // Custom text color
                        padding: "1px 12px", // custom padding making it appear less tall
                        fontSize: 15,
                      },
                      "& .MuiOutlinedInput-root": {
                        "&.Mui-focused fieldset": {
                          borderColor: "#1E2A38", // Custom border color when focused
                        },
                      },
                    }}
                  />
                ) : (
                  <DatePicker
                    label="Move-in Date"
                    value={userProvidedMoveInDate}
                    onChange={(date) => setUserProvidedMoveInDate(date)}
                    slotProps={{
                      textField: {
                        fullWidth: true,
                        variant: "filled",
                        size: "small",
                        InputLabelProps: { style: { fontSize: 15, color: "#a1a1a1", paddingLeft: 12, marginTop: -3 } },
                        InputProps: {
                          disableUnderline: true,
                          style: { backgroundColor: "white", height: 41 },
                          sx: { border: "1px solid #cbcbcb", borderRadius: 1, svg: { color: "#cbcbcb", padding: 0 } },
                        },
                        sx: {
                          "& .MuiInputBase-root": {
                            color: "black", // Custom text color
                            padding: "1px 12px", // custom padding making it appear less tall
                            fontSize: 15,
                          },
                          "& .MuiOutlinedInput-root": {
                            "&.Mui-focused fieldset": {
                              borderColor: "#1E2A38", // Custom border color when focused
                            },
                          },
                        },
                      },
                    }}
                  />
                )}
              </Grid>
              <Grid item xs={12} pt={1}>
                <Typography
                  onClick={() => setUnitMatchingMethod(unitMatchingMethod === "unitName" ? "moveInDate" : "unitName")}
                  sx={{
                    color: (theme: Theme) => theme.palette.grey.A200,
                    cursor: "pointer",
                    fontSize: 14,
                    fontWeight: 500,
                    alignSelf: "center",
                    textAlign: "center",
                  }}
                >
                  {unitMatchingMethod === "unitName" ? "I don't know my unit/space number(s)" : "I know my unit/space number(s)"}
                </Typography>
              </Grid>
            </Grid>
          </Stack>
          {onSkipError && (
            <Stack>
              <Alert severity="warning" color="error">
                {onSkipError}
              </Alert>
            </Stack>
          )}
          <Stack direction="row" spacing={1}>
            <SafeLeaseButton onClick={closeModal} variant="outlined" color="navy" sx={{ width: 70 }}>
              Cancel
            </SafeLeaseButton>
            <Tooltip
              title={
                disableSkipButton
                  ? `Please provide ${unitMatchingMethod === "unitName" ? "unit/space numbers" : "move-in date"} to continue`
                  : ""
              }
              placement="top"
            >
              <Box>
                <SafeLeaseButton
                  onClick={onSkipButtonClicked}
                  disabled={disableSkipButton}
                  variant="contained"
                  color="error"
                  sx={{ width: 70 }}
                >
                  Skip
                </SafeLeaseButton>
              </Box>
            </Tooltip>
          </Stack>
        </Stack>
      </Card>
    </Modal>
  );
}

export { SkipLocationAndUnitMatchingModal };
