import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import { Stack, Typography } from "@mui/material";

type DocumentCardProps = {
  docTitle: string | undefined;
  isProvided: boolean;
};

function DocumentCard({ docTitle, isProvided }: DocumentCardProps) {
  return (
    <Stack
      direction="row"
      alignItems="center"
      sx={{
        borderRadius: 1,
        border: "1px solid #d3d3d3",
        height: "50px",
        width: 300,
        mr: 1,
        mt: 1,
      }}
    >
      <InsertDriveFileOutlinedIcon sx={{ m: 1, color: isProvided ? "green.main" : "red.main" }} />

      <Typography
        sx={{
          fontSize: ".9rem",
          fontWeight: 600,
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          paddingRight: 1,
        }}
      >
        {docTitle}
      </Typography>
    </Stack>
  );
}

export { DocumentCard };
