import { Button } from "@mui/material";

type BackButtonProps = {
  onClick: () => void;
  disabled?: boolean;
};

function BackButton({ onClick, disabled }: BackButtonProps) {
  return (
    <Button
      disabled={disabled}
      variant="outlined"
      disableElevation
      onClick={onClick}
      sx={{
        backgroundColor: "transparent",
        color: "black",
        border: "1px solid black",
        width: "150px",
      }}
    >
      Back
    </Button>
  );
}

export { BackButton };
