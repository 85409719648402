import React from "react";
import PersonIcon from "@mui/icons-material/Person";
import { Avatar, IconButton, Menu, MenuItem } from "@mui/material";

const muiAvatar = (payload: { [index: string]: any }) => {
  const styles = {
    backgroundColor: "rgba(21,39,68,0.1)",
    border: "4px solid white",
    paddingTop: "5px",
    "&:hover": {
      border: "4px solid #FFFFFF",
      backgroundColor: "rgba(21,39,68,0.2)",
    },
  };

  if (payload.active) {
    // delete styles['&:hover'];
    styles.backgroundColor = "#FFFFFF";
    styles.border = "4px solid #2879FB";
  }

  return styles;
};

const muiDefaultAvatarIcon = (payload: { [index: string]: any }) => {
  const styles = {
    color: "#6E82A9",
    width: "40px",
    height: "40px",
  };

  if (payload.active) styles.color = "#2879FB";

  return styles;
};

const muiMenu = {
  width: "161px",
  paddingTop: "6px",
  boxShadow: "0px 7px 10px 0 rgba(0,0,0,0.09)",
};

const muiMenuItem = {
  height: "30px",
  fontSize: "12px",
  color: "#4E4E4E",
  fontFamily: "Open Sans, Arial, sans-serif",
  fontWeight: 600,
  paddingLeft: "20px",
  "&:hover": {
    backgroundColor: "#2879FB",
    color: "white",
  },
};

type AvatarDropdownProps = {
  dropdownOptions: {
    label: string;
    cb: () => void;
  }[];
};
function AvatarDropdown(props: AvatarDropdownProps) {
  const { dropdownOptions } = props;

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Avatar sx={muiAvatar({ active: open })}>
        <IconButton
          id="avatar-button"
          aria-controls={open ? "avatar-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
        >
          <PersonIcon sx={muiDefaultAvatarIcon({ active: open })} />
        </IconButton>
      </Avatar>
      <Menu
        id="avatar-menu"
        PaperProps={{ style: muiMenu }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "avatar-button",
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {dropdownOptions.map((option) => (
          <MenuItem
            key={option.label}
            onClick={() => {
              handleClose();
              option.cb();
            }}
            sx={muiMenuItem}
          >
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}

export { AvatarDropdown };
