import {
  Download,
  FirstPage,
  LastPage,
  NavigateBefore,
  NavigateNext,
  Rotate90DegreesCcw,
  Rotate90DegreesCw,
  ZoomIn,
  ZoomOut,
} from "@mui/icons-material";
import { Box, IconButton, Stack, TextField, Theme, Typography } from "@mui/material";

interface ImageViewerControlPanelProps {
  file: string;
  pageNumber: number;
  numPages: number;
  setPageNumber: (pageNumber: number) => void;
  scale: number;
  setScale: (scale: number) => void;
  showZoomControls?: boolean;
  rotation: number;
  setRotation: (rotation: number) => void;
}

export function ControlPanel({
  file,
  pageNumber,
  numPages,
  setPageNumber,
  scale,
  setScale,
  showZoomControls = true,
  rotation,
  setRotation,
}: ImageViewerControlPanelProps) {
  // Grabs the file extension, removes any query parameters
  const fileExtension = file?.split(".")?.pop()?.split("?")[0] ?? "";
  const isPDF = fileExtension.toLowerCase() === "pdf";

  const isMinZoom = scale < 0.6;
  const isMaxZoom = scale >= 2.0;

  const zoomOut = () => {
    if (!isMinZoom) setScale(scale - 0.1);
  };

  const zoomIn = () => {
    if (!isMaxZoom) setScale(scale + 0.1);
  };

  return (
    <Stack
      direction="row"
      justifyContent="center"
      sx={{
        borderBottom: "1px solid",
        borderColor: (theme: Theme) => theme.palette.divider,
        width: "100%",
      }}
    >
      <Box
        sx={{
          m: 2,
          borderRadius: 2,
          justifyContent: "space-between",
          display: "flex",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {isPDF && <PDFControls numPages={numPages} pageNumber={pageNumber} setPageNumber={setPageNumber} />}
          {showZoomControls && (
            <>
              <IconButton onClick={zoomOut} sx={{ ml: 2 }}>
                <ZoomOut />
              </IconButton>
              <span>{(scale * 100).toFixed()}%</span>
              <IconButton onClick={zoomIn}>
                <ZoomIn />
              </IconButton>
            </>
          )}
          <IconButton onClick={() => setRotation(rotation - 90)} sx={{ ml: 2 }}>
            <Rotate90DegreesCcw />
          </IconButton>
          <IconButton onClick={() => setRotation(rotation + 90)} sx={{ mr: 2 }}>
            <Rotate90DegreesCw />
          </IconButton>
          <IconButton href={file} sx={{ ml: 2 }} target="_blank">
            <Download />
          </IconButton>
        </Box>
      </Box>
    </Stack>
  );
}

interface PDFControlsProps {
  numPages: number;
  pageNumber: number;
  setPageNumber: (pageNumber: number) => void;
}

function PDFControls({ numPages, pageNumber, setPageNumber }: PDFControlsProps) {
  const isFirstPage = pageNumber === 1;
  const isLastPage = pageNumber === numPages;

  // Prevent the user from going to a page "0" or past the last page
  const onPageChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { value } = e.target;

    // clamp the page number to max or 1
    const newPageNumber = Math.max(1, Math.min(Number(value), numPages));
    setPageNumber(Number(newPageNumber));
  };

  // Pagination
  const goToFirstPage = () => {
    if (!isFirstPage) setPageNumber(1);
  };
  const goToPreviousPage = () => {
    if (!isFirstPage) setPageNumber(pageNumber - 1);
  };
  const goToNextPage = () => {
    if (!isLastPage) setPageNumber(pageNumber + 1);
  };
  const goToLastPage = () => {
    if (!isLastPage) setPageNumber(numPages);
  };

  return (
    <Stack direction="row" justifyContent="center" alignItems="center">
      <IconButton onClick={goToFirstPage} disabled={pageNumber === 1}>
        <FirstPage />
      </IconButton>
      <IconButton onClick={goToPreviousPage} disabled={pageNumber === 1}>
        <NavigateBefore />
      </IconButton>
      Page <TextField size="small" type="number" sx={{ pl: 1, mx: 2, width: 60 }} onChange={onPageChange} value={pageNumber} />
      <Typography sx={{ minWidth: 40 }}> of {numPages ?? 1}</Typography>
      <IconButton onClick={goToNextPage} disabled={!numPages || pageNumber === numPages}>
        <NavigateNext />
      </IconButton>
      <IconButton onClick={goToLastPage} disabled={!numPages || pageNumber === numPages}>
        <LastPage />
      </IconButton>
    </Stack>
  );
}
