import { Box, Tooltip, useMediaQuery, useTheme } from "@mui/material";
import { BackButton } from "./BackButton";
import { ContinueButton } from "./ContinueButton";
import { useClaimCreationStore } from "../useClaimCreationStore";
import { WithdrawClaimConfirmationModal } from "../../SubmittedClaimDetailsPage/ToDoItemsContainer/WithdrawClaimConfirmationModal";
import { useState } from "react";
import { SafeLeaseButton } from "@safelease/components";
import { ClaimsApi } from "../../../../utils/apiInstances/ClaimsApiInstance";
import { enqueueSnackbar } from "notistack";

type ActionButtonsFooterProps = {
  noBackButton?: boolean;
  disabled?: boolean;
  backButtonDisabled?: boolean;
  disabledTooltip?: string;
  onContinueButtonClick?: () => void;
  onBackButtonClick?: () => void;
  type?: "button" | "submit" | "reset" | undefined;
  label?: string;
};

function ActionButtonsFooter({
  noBackButton,
  disabled = false,
  backButtonDisabled = false,
  disabledTooltip,
  onContinueButtonClick,
  onBackButtonClick,
  type,
  label = "Continue",
}: ActionButtonsFooterProps) {
  const setCreationStep = useClaimCreationStore((state) => state.setCreationStep);
  const creationStep = useClaimCreationStore((state) => state.creationStep);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [withdrawClaimModalOpen, setWithdrawClaimModalOpen] = useState<boolean>(false);
  const claim = useClaimCreationStore((state) => state.claimId);

  const defaultHandleBackButtonClicked = () => {
    if (creationStep) setCreationStep(creationStep - 1);
  };

  const handleDeleteClaim = async () => {
    try {
      if (claim) {
        await ClaimsApi.deleteClaim(claim);
        enqueueSnackbar("Claim archived successfully.", { variant: "success" });
      }
    } catch (err) {
      enqueueSnackbar("Error archiving claim, please try again.", { variant: "error" });
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        m: "2rem",
        ml: "1rem",
      }}
    >
      {!noBackButton ? (
        <BackButton onClick={onBackButtonClick ?? defaultHandleBackButtonClicked} disabled={backButtonDisabled} />
      ) : (
        <Box sx={{ width: "100px" }} />
      )}
      <Tooltip placement="top" title={disabled && disabledTooltip ? disabledTooltip : ""}>
        <Box sx={{ display: "flex", gap: "10px" }}>
          <SafeLeaseButton
            sx={{ display: isMobile ? "none" : "flex" }}
            onClick={() => setWithdrawClaimModalOpen(true)}
            variant="outlined"
            color="navy"
          >
            Discard claim
          </SafeLeaseButton>
          <WithdrawClaimConfirmationModal
            modalOpen={withdrawClaimModalOpen}
            setModalOpen={setWithdrawClaimModalOpen}
            onWithdraw={handleDeleteClaim}
          />
          <ContinueButton type={type} label={label} onClick={onContinueButtonClick} disabled={disabled} />
        </Box>
      </Tooltip>
    </Box>
  );
}

export { ActionButtonsFooter };
