import { Box, Divider } from "@mui/material";
import { Navbar } from "./Navbar";

type NavbarWrapperProps = {
  children?: React.ReactNode;
};

/**
 * wraps any child components in the Navbar layout so that the Navbar is displayed on top of the child components
 * mainly used in the Route components to wrap the main content of the page
 */

function NavbarWrapper({ children }: NavbarWrapperProps) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100vh",
        padding: "15px",
      }}
    >
      <Navbar />
      <Divider sx={{ marginY: 1 }} />
      {children}
    </Box>
  );
}

export { NavbarWrapper };
