import { Box, Stack, Typography } from "@mui/material";
import { SafeLeaseButton } from "@safelease/components";
import { GetClaimMessageResponse } from "@safelease/service-utilities";
import { useTenantActionModalStore } from "../../../../TenantActionModal/useTenantActionModalStore";

type FormQuestionMessageProps = {
  messageData: GetClaimMessageResponse;
};

const MessageContent = ({ question }: { question: string }) => (
  <Typography sx={{ fontSize: 14, fontWeight: 500, whiteSpace: "pre-wrap" }}>{question}</Typography>
);

const RespondButton = ({ onClick }: { onClick: () => void }) => (
  <SafeLeaseButton variant="contained" size="small" sx={{ bgcolor: "#031E30", width: 200 }} onClick={onClick}>
    Respond
  </SafeLeaseButton>
);

const ResponseBox = ({ response }: { response: string }) => (
  <Box sx={{ borderRadius: 2, bgcolor: "white", padding: 2 }}>
    <Typography sx={{ fontSize: 14, fontWeight: 500, whiteSpace: "pre-wrap" }}>{response}</Typography>
  </Box>
);

function FormQuestionMessage({ messageData }: FormQuestionMessageProps) {
  const { setTenantActionModalOpen, setModalContentData } = useTenantActionModalStore();

  const handleRespondClick = () => {
    setModalContentData(messageData.tenantAction);
    setTenantActionModalOpen(true);
  };

  return (
    <Stack spacing={1} sx={{ bgcolor: "#EBEFF7", borderRadius: 3, padding: 2, maxWidth: { xs: "80vw", sm: 800 } }}>
      <MessageContent question={messageData.tenantAction?.formQuestionDef?.question ?? ""} />
      {messageData.tenantAction?.completed ? (
        <ResponseBox response={messageData.tenantAction.formQuestionDef?.response ?? ""} />
      ) : (
        <RespondButton onClick={handleRespondClick} />
      )}
    </Stack>
  );
}

export { FormQuestionMessage };
