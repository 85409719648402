import { Stack, Typography } from "@mui/material";
import { GetClaimMessageResponse } from "@safelease/service-utilities";
import { ListOfUploadedFiles } from "./ListOfUploadedFiles";
import { SafeLeaseButton } from "@safelease/components";
import { useTenantActionModalStore } from "../../../../TenantActionModal/useTenantActionModalStore";

type UploadFileMessageProps = {
  messageData: GetClaimMessageResponse;
  requestMessage: string;
};

const MessageContent = ({ requestMessage, lineItemDetails }: { requestMessage: string; lineItemDetails?: any }) => (
  <>
    {lineItemDetails && <Typography sx={{ fontSize: 16, fontWeight: 500 }}>Item: '{lineItemDetails.name}'</Typography>}
    <Typography
      sx={{
        fontSize: 14,
        fontWeight: 500,
        whiteSpace: "pre-wrap",
        color: "black",
        padding: 0.5,
        borderRadius: 2,
      }}
    >
      {requestMessage}
    </Typography>
  </>
);

const RespondButton = ({ onClick }: { onClick: () => void }) => (
  <SafeLeaseButton variant="contained" size="small" sx={{ bgcolor: "#031E30", width: 200 }} onClick={onClick}>
    Respond
  </SafeLeaseButton>
);

function UploadFileMessage({ messageData, requestMessage }: UploadFileMessageProps) {
  const { setTenantActionModalOpen, setModalContentData } = useTenantActionModalStore();
  const handleRespondClick = () => {
    setModalContentData(messageData.tenantAction);
    setTenantActionModalOpen(true);
  };

  const lineItemDetails = messageData.tenantAction?.lineItem;

  return (
    <Stack spacing={1} sx={{ bgcolor: "#EBEFF7", borderRadius: 3, padding: 2, maxWidth: { xs: "80vw", sm: 800 } }}>
      <MessageContent requestMessage={requestMessage} lineItemDetails={lineItemDetails} />
      {messageData.tenantAction?.completed ? (
        <ListOfUploadedFiles messageData={messageData} />
      ) : (
        <RespondButton onClick={handleRespondClick} />
      )}
    </Stack>
  );
}

export { UploadFileMessage };
