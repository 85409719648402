import { Card, Modal, Typography, Stack, Button } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useNavigate } from "react-router";

type SuccessfulSubmissionModalProps = {
  modalOpen: boolean;
};

function SuccessfulSubmissionModal({ modalOpen }: SuccessfulSubmissionModalProps) {
  const navigate = useNavigate();
  return (
    <Modal open={modalOpen} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
      <Card
        sx={{
          padding: "20px",
        }}
      >
        <Stack alignItems="center" spacing={1}>
          <CheckCircleIcon sx={{ color: "green.main" }} />
          <Typography>Your claim has been submitted successfully.</Typography>
          <Typography>It is being reviewed by our claims department and you should expect to hear back shortly.</Typography>
          <Button variant="contained" disableElevation sx={{ textTransform: "none" }} onClick={() => navigate("/manage-claims")}>
            Back to portal
          </Button>
        </Stack>
      </Card>
    </Modal>
  );
}

export { SuccessfulSubmissionModal };
