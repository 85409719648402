import { Box } from "@mui/material";
import { Navigate, Route, Routes } from "react-router-dom";
import { HomePage } from "../HomePage";
import { ManageClaims } from "../claim-portal/ManageClaims";
import { SelectedClaimPage } from "../claim-portal/SelectedClaimPage";
import { PrivatePolicyForm } from "../PrivatePolicyForm";
import { NavbarWrapper } from "./NavbarWrapper";
import { ManagePrivatePolicies } from "../ManagePrivatePolicies";
import { useAuthStore } from "../hooks/useAuthStore.ts";

type MainDashboardRouterProps = {};

function MainDashboardRouter({}: MainDashboardRouterProps) {
  const userAttributes = useAuthStore((state) => state.userAttributes);
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100vh",
      }}
    >
      <Routes>
        <Route
          path="/"
          element={
            <NavbarWrapper>
              <HomePage />
            </NavbarWrapper>
          }
        />
        <Route
          path="/manage-claims"
          element={
            <NavbarWrapper>
              <ManageClaims />
            </NavbarWrapper>
          }
        />
        <Route path="/claim/:claimId" element={<SelectedClaimPage />} />
        {userAttributes?.isPPEnabled === "true" && (
          <>
            <Route path="/manage-private-policies" element={<ManagePrivatePolicies />} />
            <Route path="/private-policy" element={<PrivatePolicyForm />} />
          </>
        )}
        <Route path="*" element={<Navigate to="/404" replace />} />
      </Routes>
    </Box>
  );
}

export { MainDashboardRouter };
