import { Box, CircularProgress, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { ActionButtonsFooter } from "./ActionButtonsFooter";
import { FileUploadDropzone } from "../../../shared/FileUploadDropzone";
import { HelpIcon } from "./HelpIcon";
import { Attachment_ClaimsDbModel } from "@safelease/service-utilities";
import { ClaimsApi } from "../../../utils/apiInstances/ClaimsApiInstance";
import { enqueueSnackbar } from "notistack";

type SingleFileUploadFormComponentProps = {
  onContinue: (setLoading: React.Dispatch<React.SetStateAction<boolean>>, files: File[]) => Promise<void>;
  title: string;
  subTitle?: string;
  strongTitle?: string;
  helperText?: string;
  existingFiles?: Attachment_ClaimsDbModel[];
};

function SingleFileUploadFormComponent({
  onContinue,
  title,
  subTitle,
  strongTitle,
  helperText,
  existingFiles = [],
}: SingleFileUploadFormComponentProps) {
  const [loading, setLoading] = useState(false);
  const [combinedFiles, setCombinedFiles] = useState<(File | Attachment_ClaimsDbModel)[]>([...existingFiles]);

  async function handleRemoveFile(file: File | Attachment_ClaimsDbModel) {
    if (!(file instanceof File)) {
      try {
        await ClaimsApi.archiveDocument(file.id);
        setCombinedFiles((prevFiles) => prevFiles.filter((f) => f instanceof File || f.id !== (file as Attachment_ClaimsDbModel).id));
      } catch (error) {
        enqueueSnackbar("Something went wrong when removing the file, please try again.", {
          variant: "error",
          autoHideDuration: 1000,
        });
      }
    } else {
      setCombinedFiles((files) =>
        files.filter((f) => !(f instanceof File) || (f.name !== file.name && f.lastModified !== file.lastModified)),
      );
    }
  }

  return (
    <>
      <Stack
        sx={{
          paddingX: 3,
          paddingY: 1,
          flex: 1,
          overflow: "auto",
        }}
      >
        <Stack direction="row">
          <Typography sx={{ fontWeight: 400, fontSize: "1.25rem" }}>
            {title} <span style={{ fontWeight: 600 }}>{strongTitle}</span>
          </Typography>
          <HelpIcon helpText={helperText} />
        </Stack>
        <Typography sx={{ fontWeight: 400, fontSize: ".8rem", color: "grey", mt: "1rem" }}>{subTitle}</Typography>
        <Box sx={{ flex: 1, overflow: "auto" }}>
          {loading ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <Box sx={{ marginTop: 5, overflow: "auto" }}>
              <FileUploadDropzone
                deleteFile={handleRemoveFile}
                uploadedFiles={combinedFiles}
                handleNewFiles={(files) => {
                  setCombinedFiles((prevFiles) => [...prevFiles, ...files]);
                }}
                containerProps={{
                  border: "1px dashed rgba(3, 30, 48, 0.2)",
                  borderRadius: "5px",
                  bgcolor: "white",
                  height: "200px",
                }}
              />
            </Box>
          )}
        </Box>
      </Stack>
      <ActionButtonsFooter
        disabled={loading}
        backButtonDisabled={loading}
        onContinueButtonClick={() => {
          onContinue(setLoading, combinedFiles.filter((f) => f instanceof File) as File[]);
        }}
      />
    </>
  );
}

export { SingleFileUploadFormComponent };
