import { Attachment_ClaimsDbModel, TypeSpecificAttachmentDef_ClaimsDbModel } from "@safelease/service-utilities";
import { ClaimDocumentSection } from "./ClaimDocumentSection";
import { Stack } from "@mui/material";

type TypeSpecificAttachmentSummaryProps = {
  typeSpecificAttachmentDefs: TypeSpecificAttachmentDef_ClaimsDbModel[];
};

function TypeSpecificAttachmentSummary({ typeSpecificAttachmentDefs }: TypeSpecificAttachmentSummaryProps) {
  return (
    <Stack spacing={2}>
      {typeSpecificAttachmentDefs.map((def) => (
        <ClaimDocumentSection
          key={def.id}
          attachments={
            (def.typeSpecificAttachments
              ?.map((tsa) => tsa?.attachment)
              ?.filter(
                (attachment) => attachment !== undefined && attachment !== null && !attachment.deletedAt,
              ) as Attachment_ClaimsDbModel[]) || []
          }
          label={def.name}
        />
      ))}
    </Stack>
  );
}

export { TypeSpecificAttachmentSummary };
