import { Stack, Typography } from "@mui/material";
import React from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

interface SuccessMessageProps {
  title: string;
  subtitle?: string;
}

function SuccessMessage({ title, subtitle = "" }: SuccessMessageProps) {
  return (
    <Stack
      direction="column"
      alignItems="center"
      justifyContent="center"
      alignSelf="center"
      spacing={1}
      sx={{
        height: "100%",
      }}
    >
      <CheckCircleIcon color={"success"} sx={{ fontSize: "60px" }} />
      <Typography variant="h6">{title}</Typography>
      {!!subtitle && (
        <Typography
          variant="subtitle2"
          sx={{
            color: "#929292",
          }}
        >
          {subtitle}
        </Typography>
      )}
    </Stack>
  );
}

export { SuccessMessage };
