import { useEffect, useState } from "react";
import { useClaimCreationStore } from "./useClaimCreationStore";
import { Autocomplete, Box, CircularProgress, Stack, TextField, Typography } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { useQuery } from "@tanstack/react-query";
import { ActionButtonsFooter } from "./ActionButtonsFooter";
import { ClaimsApi } from "../../../utils/apiInstances/ClaimsApiInstance";
import { DatePicker } from "@mui/x-date-pickers";
import { Dayjs } from "dayjs";
import dayjs from "dayjs";
import { HelpIcon } from "./HelpIcon";

type ClaimDescriptionProps = {};

function ClaimDescription({}: ClaimDescriptionProps) {
  const claimType = useClaimCreationStore((state) => state.claimType);
  const setClaimType = useClaimCreationStore((state) => state.setClaimType);
  const lossDescription = useClaimCreationStore((state) => state.lossDescription);
  const setLossDescription = useClaimCreationStore((state) => state.setLossDescription);
  const claimId = useClaimCreationStore((state) => state.claimId);
  const creationStep = useClaimCreationStore((state) => state.creationStep);
  const setCreationStep = useClaimCreationStore((state) => state.setCreationStep);
  const [continueRequestPending, setContinueRequestPending] = useState<boolean>(false);
  const [date, setDate] = useState<Dayjs | null>(null);

  const claimQuery = useQuery({
    queryKey: [`claim-${claimId}`],
    queryFn: async () => await ClaimsApi.getClaimById(claimId as string),
    gcTime: 0,
  });

  const exisitngClaimType = claimQuery.data?.claimType?.name;
  const existingClaimTypeId = claimQuery.data?.claimTypeId;
  const existingClaimId = claimQuery.data?.id;
  const existingDate = claimQuery.data?.lossDate;
  const existingLossDescription = claimQuery.data?.lossDescription;
  useEffect(() => {
    if (existingClaimId) {
      if (exisitngClaimType && existingClaimTypeId) {
        setClaimType({ label: exisitngClaimType, value: existingClaimTypeId });
      }
      if (existingDate) {
        setDate(dayjs(existingDate));
      }
      if (existingLossDescription) {
        setLossDescription(existingLossDescription);
      }
    }
  }, [existingClaimId, exisitngClaimType, existingDate, existingLossDescription]);
  const {
    isLoading: claimTypeOptionsLoading,
    data,
    error,
  } = useQuery<{ id: string; name: string }[]>({
    queryKey: ["claim-type-options"],
    queryFn: async () => await ClaimsApi.getClaimTypes(),
  });

  if (error) {
    return <div>Something went wrong, please try again later.</div>;
  }

  const claimTypeOptions: ClaimTypeAutocompleteOption[] =
    data?.map((claimType) => ({
      label: claimType.name,
      value: claimType.id,
    })) || [];

  const continueToNextStep = async () => {
    if (!claimType || !claimId || !creationStep) {
      return;
    }
    setContinueRequestPending(true);
    try {
      await ClaimsApi.updateClaim(claimId, {
        claimTypeId: claimType.value,
        lossDescription,
        lossDate: date?.format("YYYY-MM-DD"),
        creationStep: creationStep + 1,
      });
      setContinueRequestPending(false);
      setCreationStep(creationStep + 1);
    } catch (err) {
      enqueueSnackbar("There was an issue submitting your responses, please try again.", { variant: "error" });
      setContinueRequestPending(false);
    }
  };
  return (
    <>
      <Stack
        spacing={2}
        sx={{
          paddingX: 3,
          paddingY: 1,
          flex: 1,
          overflow: "auto",
        }}
      >
        {continueRequestPending || claimTypeOptionsLoading || claimQuery.isLoading ? (
          <Box display="flex" justifyContent="center" alignItems="center" height="100%">
            <CircularProgress />
          </Box>
        ) : (
          <>
            <Stack direction={"row"}>
              <Typography sx={{ fontWeight: 400, fontSize: "1.25rem" }}>Select your claim type</Typography>
              <HelpIcon helpText="Please select the type of claim and provide us with information about your claim. No need to describe individual items lost or damages, just the general nature of the claim." />
            </Stack>
            <Autocomplete
              value={claimType}
              onChange={(_, newValue: ClaimTypeAutocompleteOption | null) => {
                setClaimType(newValue);
              }}
              isOptionEqualToValue={(option, value) => option.value === value.value}
              id="combo-box-demo"
              options={claimTypeOptions}
              sx={{ width: "100%" }}
              renderInput={(params) => <TextField {...params} label="Claim type" fullWidth />}
            />
            <Typography sx={{ fontWeight: 400, fontSize: "1.25rem" }}>Please provide a description of your claim</Typography>
            <TextField rows={5} value={lossDescription} onChange={(e) => setLossDescription(e.target.value)} fullWidth multiline />
            <Typography sx={{ fontWeight: 400, fontSize: "1.25rem" }}>What is the date of loss for the items you are claiming?</Typography>
            <DatePicker
              value={date}
              onChange={(newDate: Dayjs | null) => {
                setDate(newDate);
              }}
              sx={{ width: "100%" }}
            />
          </>
        )}
      </Stack>
      <ActionButtonsFooter
        onContinueButtonClick={continueToNextStep}
        disabled={!claimType || !date || !lossDescription?.trim()}
        disabledTooltip="Please fill out all fields before continuing."
      />
    </>
  );
}
export { ClaimDescription };
