import { Stack, Typography } from "@mui/material";
import { GetClaimMessageResponse } from "@safelease/service-utilities";
import TaskOutlinedIcon from "@mui/icons-material/TaskOutlined";

type ListOfUploadedFilesProps = {
  messageData: GetClaimMessageResponse;
};

function ListOfUploadedFiles({ messageData }: ListOfUploadedFilesProps) {
  return (
    <Stack spacing={1}>
      {messageData.tenantAction?.uploadedAttachments?.map((attachment) => (
        <UploadedFileIndicator key={attachment.id} fileName={attachment.fileName} fileSize={attachment.fileSize} />
      ))}
    </Stack>
  );
}

const UploadedFileIndicator = ({ fileName, fileSize }: { fileName: string; fileSize?: number }) => {
  return (
    <Stack direction="row" alignItems="center" justifyContent="start" padding={2} bgcolor={"white"} borderRadius={2}>
      <Stack
        spacing={1}
        alignItems="center"
        direction="row"
        sx={{
          backgroundColor: "#EBEFF7",
          padding: 1,
          borderRadius: 2,
          width: "100%",
        }}
      >
        <TaskOutlinedIcon sx={{ color: "rgba(37, 155, 36, 1)" }} />
        <Stack sx={{ overflow: "hidden" }}>
          <Typography sx={{ fontSize: 14, fontWeight: 500, whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
            {fileName}
          </Typography>
          {fileSize !== undefined && <Typography fontSize={12}>{`Size: ${(fileSize / 1024).toFixed(2)} KB`}</Typography>}
        </Stack>
      </Stack>
    </Stack>
  );
};

export { ListOfUploadedFiles };
