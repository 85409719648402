import { Box, Stack, Typography } from "@mui/material";
import safeleaseLogo from "../assets/safelease_logoicon_rgb_whiteonnavyblue.svg";
import topLeftLoginBackgroundImage from "../assets/topLeftLoginBackgroundImage.png";
import bottomRightBackgroundImage from "../assets/bottomRightBackgroundImage.png";
import blueWaveBackground from "../assets/blueWaveBackground.png";
import { FormContentType, useAuthenticationPageStore } from "./useAuthenticationPageStore";
import { SignUpForm } from "./SignUpForm";
import { LoginForm } from "./LoginForm";
import { ForgotPasswordPages } from "./ForgotPasswordPages";

/**
 * This component is the main component that renders when there is no current authenticated user
 * It is the parent component to all three sets of forms that can be rendered
 * The three different states are SignUpForm, LoginForm, and ForgotPasswordPages
 */
type AuthenticationPageProps = {};

function AuthenticationPage({}: AuthenticationPageProps) {
  const currentFormContentType = useAuthenticationPageStore((state) => state.currentFormContentType);

  return (
    <Box
      sx={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        bgcolor: { xs: "grey.900", sm: "#152744" },
      }}
    >
      <Box sx={{ display: { xs: "none", sm: "block" } }}>
        <img style={{ top: 0, left: 0, position: "absolute" }} src={topLeftLoginBackgroundImage} />
        <img style={{ bottom: 0, right: 0, position: "absolute" }} src={bottomRightBackgroundImage} />
      </Box>
      <Stack
        direction={{ xs: "column", sm: "row" }}
        sx={{
          width: { xs: "100%", sm: "95%" },
          maxWidth: 1000,
          minWidth: 300,
          height: { xs: "100%", sm: 543 },
          bgcolor: "rgba(255, 255, 255, 1)",
          borderRadius: { xs: 0, sm: 3 },
          padding: { xs: 0, sm: 3 },
          zIndex: 4,
        }}
        spacing={{ xs: 0, sm: 3 }}
      >
        <Stack
          padding={{ xs: 1, sm: 3 }}
          justifyContent="space-between"
          sx={{
            width: { xs: "100%", sm: 500 },
            height: { xs: 250, sm: "100%" },
            bgcolor: "rgba(21, 39, 68, 1)",
            borderRadius: { xs: 0, sm: 3 },
            backgroundImage: `url(${blueWaveBackground})`,
            backgroundSize: { xs: "cover", sm: "100% 100%" },
            backgroundPosition: { xs: "center", sm: "center" },
          }}
        >
          <Stack
            spacing={1}
            sx={{
              margin: { xs: 1, sm: 0 },
              marginTop: { xs: 4, sm: 0 },
            }}
          >
            <Typography sx={{ fontWeight: 700, fontSize: 20, color: "rgba(255, 255, 255, 1)" }}>
              Need to file a claim or submit a private policy?
            </Typography>
            <Typography sx={{ fontSize: 12, color: "rgba(255, 255, 255, 1)" }}>
              You're in the right spot. Create an account to file and manage your claims and policies.
            </Typography>
          </Stack>

          <Stack direction="row" alignItems="center" spacing={1} margin={{ xs: 1, sm: 0 }} paddingBottom={{ xs: 2, sm: 0 }}>
            <Box sx={{ width: 25, height: 25 }}>
              <img src={safeleaseLogo} />
            </Box>
            <Typography sx={{ fontSize: 14, fontWeight: 500, color: "white" }}>SafeLease Tenant Portal</Typography>
          </Stack>
        </Stack>

        <Box sx={{ width: "100%", height: "100%", borderRadius: 3 }}>
          {currentFormContentType === FormContentType.SIGN_UP && <SignUpForm />}
          {currentFormContentType === FormContentType.LOGIN && <LoginForm />}
          {currentFormContentType === FormContentType.FORGOT_PASSWORD && <ForgotPasswordPages />}
        </Box>
      </Stack>
    </Box>
  );
}

export { AuthenticationPage };
