import { Attachment_ClaimsDbModel, FormQuestionDef_ClaimsDbModel, FormQuestionResponse_ClaimsDbModel } from "@safelease/service-utilities";
import { create } from "zustand";

type ClaimCreationStore = {
  claimId: string | undefined;
  setClaimId: (claimId: string) => void;
  activeStep: number | undefined;
  setActiveStep: (activeStep: number) => void;
  creationStep: number | null;
  setCreationStep: (creationStep: number) => void;
  claimType: ClaimTypeAutocompleteOption | null;
  setClaimType: (claimType: ClaimTypeAutocompleteOption | null) => void;
  lossDescription: string;
  setLossDescription: (lossDescription: string) => void;
  formQuestionResponses: { [key: string]: string | null };
  setFormQuestionResponses: (key: string, value: string | null) => void;
  setInitialFormQuestionResponses: (
    formQuestionDefs: FormQuestionDef_ClaimsDbModel[],
    formQuestionResponses: FormQuestionResponse_ClaimsDbModel[],
  ) => void;
  typeSpecificAttachments: { [key: string]: (File | Attachment_ClaimsDbModel)[] | undefined };
  setTypeSpecificAttachments: (key: string, value: (File | Attachment_ClaimsDbModel)[]) => void;
  removeTypeSpecificAttachment: (key: string, value: File | Attachment_ClaimsDbModel) => void;
  clearTypeSpecificAttachments: () => void;
  /**
   * backButtonClicked is used to determine if the back button was clicked to give context to the previous step in case it needs it
   * currently only used in the TypeSpecificAttachments component to determine if it should be going back or forward in the stepper whenever the current claim doesn't have any type specific attachments
   */
  backButtonClicked: boolean;
  setBackButtonClicked: (backButtonClicked: boolean) => void;
  cleanUp: () => void;
};

export const useClaimCreationStore = create<ClaimCreationStore>()((set, get) => ({
  claimId: undefined,
  setClaimId: (claimId: string) => set({ claimId }),
  activeStep: undefined,
  setActiveStep: (activeStep: number) => set({ activeStep }),
  creationStep: null,
  setCreationStep: (creationStep: number) => {
    const activeStep = getActiveStep(creationStep);
    set({ creationStep });
    set({ activeStep });
  },
  claimType: null,
  setClaimType: (claimType: ClaimTypeAutocompleteOption | null) => set({ claimType }),
  lossDescription: "",
  setLossDescription: (lossDescription: string) => set({ lossDescription }),
  formQuestionResponses: {},
  setFormQuestionResponses: (key: string, value: string | null) => {
    const formQuestionResponses = { ...get().formQuestionResponses };
    formQuestionResponses[key] = value;
    set({ formQuestionResponses });
  },
  setInitialFormQuestionResponses: (formQuestionDefs, forQuestionResponses) => {
    const formQuestionResponses: { [key: string]: string | null } = {};
    for (const question of formQuestionDefs) {
      formQuestionResponses[question.id] = null;
    }
    for (const response of forQuestionResponses) {
      if (response.formQuestionDefId in formQuestionResponses) {
        formQuestionResponses[response.formQuestionDefId] = response.response;
      }
    }
    set({ formQuestionResponses });
  },
  typeSpecificAttachments: {},
  setTypeSpecificAttachments(key: string, value: (File | Attachment_ClaimsDbModel)[]) {
    const typeSpecificAttachments = {
      ...get().typeSpecificAttachments,
    };
    const oldValue = typeSpecificAttachments[key];
    const newValue = oldValue ? [...oldValue, ...value] : [...value];
    typeSpecificAttachments[key] = newValue;
    set({ typeSpecificAttachments });
  },
  removeTypeSpecificAttachment(key: string, value: File | Attachment_ClaimsDbModel) {
    const typeSpecificAttachments = {
      ...get().typeSpecificAttachments,
    };
    const oldValue = typeSpecificAttachments[key];
    if (oldValue) {
      let newValue;
      if (value instanceof File) {
        newValue = oldValue.filter((f) => !(f instanceof File) || (f.name !== value.name && f.lastModified !== value.lastModified));
      } else {
        newValue = oldValue.filter((f) => f instanceof File || f.id !== (value as Attachment_ClaimsDbModel).id);
      }
      typeSpecificAttachments[key] = newValue;
      set({ typeSpecificAttachments });
    }
  },
  clearTypeSpecificAttachments() {
    set({ typeSpecificAttachments: {} });
  },
  backButtonClicked: false,
  setBackButtonClicked: (backButtonClicked: boolean) => set({ backButtonClicked }),
  cleanUp: () =>
    set({
      claimId: undefined,
      activeStep: undefined,
      creationStep: null,
      claimType: null,
      lossDescription: "",
      formQuestionResponses: {},
      typeSpecificAttachments: {},
      backButtonClicked: false,
    }),
}));

// helper function that will be called when updating the creationStep to correctly set the activeStep which is used to display the correct step in the stepper
const getActiveStep = (creationStep: number) => {
  if (creationStep <= 1) {
    return 0;
  } else if (creationStep <= 7) {
    return 1;
  } else {
    return 2;
  }
};
