import { SafeleasePrivatePolicy } from "@safelease/service-utilities";
import dayjs from "dayjs";

export type PrivatePolicyStatusGroup = "active" | "submitted" | "expired" | "tenantEnrollOverride";
export type PrivatePolicyReviewStatus = "accepted" | "rejected" | "tenantEnrollOverride" | "inReview";
export type PrivatePolicyStatus = "accepted" | "expiringSoon" | "expired" | "inReview" | "rejected" | "tenantEnrollOverride";

export const getCalculatedPrivatePolicyStatus = (
  privatePolicy: SafeleasePrivatePolicy,
): {
  statusGroup: PrivatePolicyStatusGroup;
  reviewStatus: PrivatePolicyReviewStatus;
  status: PrivatePolicyStatus;
  isExpired: boolean;
  isRecentlyRejected: boolean;
  isEnrollmentEnabled: boolean;
} => {
  const diffInDays = dayjs(privatePolicy.expiration).diff(dayjs(), "day");

  const reviewStatus = ["accepted"].includes(privatePolicy.validationStatus)
    ? "accepted"
    : ["rejected", "pendingRejected"].includes(privatePolicy.validationStatus)
      ? "rejected"
      : ["tenantEnrollOverride"].includes(privatePolicy.validationStatus)
        ? "tenantEnrollOverride"
        : "inReview";

  const statusGroup =
    reviewStatus === "tenantEnrollOverride"
      ? "tenantEnrollOverride"
      : reviewStatus !== "accepted"
        ? "submitted"
        : diffInDays > 0
          ? "active"
          : "expired";

  const status =
    reviewStatus === "rejected"
      ? "rejected"
      : reviewStatus === "inReview"
        ? "inReview"
        : reviewStatus === "tenantEnrollOverride"
          ? "tenantEnrollOverride"
          : reviewStatus === "accepted" && diffInDays > 30
            ? "accepted"
            : reviewStatus === "accepted" && diffInDays < 0
              ? "expired"
              : "expiringSoon";

  const isRecentlyRejected =
    reviewStatus === "rejected" &&
    ((dayjs().diff(dayjs(privatePolicy.createdAt), "day") < 60 && dayjs().diff(dayjs(privatePolicy.rejectedAt), "day") < 14) ||
      (dayjs().diff(dayjs(privatePolicy.createdAt), "day") >= 60 && dayjs().diff(dayjs(privatePolicy.rejectedAt), "day") < 3));

  return {
    statusGroup,
    reviewStatus,
    status,
    isExpired: diffInDays < 0,
    isRecentlyRejected,
    isEnrollmentEnabled: ["active", "submitted"].includes(statusGroup) && !(reviewStatus === "rejected" && !isRecentlyRejected),
  };
};
