import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { Box, Tooltip } from "@mui/material";

type HelpIconProps = {
  helpText?: string;
};

function HelpIcon({ helpText = "" }: HelpIconProps) {
  return (
    <Tooltip title={helpText}>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <HelpOutlineIcon sx={{ color: "grey", cursor: "pointer", ml: ".25rem" }} />
      </Box>
    </Tooltip>
  );
}

export { HelpIcon };
