import { Box, Stack, Typography } from "@mui/material";
import { FileUploadDropzone } from "../../../../shared/FileUploadDropzone";
import { HelpIcon } from "../HelpIcon";
import { Attachment_ClaimsDbModel } from "@safelease/service-utilities";

type UploadAttachmentFormElementProps = {
  handleFileInputChange: (files: File[]) => void;
  title: string;
  subTitle: string;
  handleRemoveFile: (file: File | Attachment_ClaimsDbModel) => void;
  uploadedFiles: (File | Attachment_ClaimsDbModel)[];
};

function UploadAttachmentFormElement({
  handleFileInputChange,
  title,
  subTitle,
  handleRemoveFile,
  uploadedFiles,
}: UploadAttachmentFormElementProps) {
  return (
    <Box
      sx={{
        flex: 1,
        display: "flex",
        flexDirection: "column",
        padding: "15px",
        borderRadius: "5px",
      }}
    >
      <Stack direction="row">
        <Typography variant="h6">{title}</Typography>
        <HelpIcon helpText={title + " so we can expdite your claim."} />
      </Stack>
      <Typography sx={{ color: "grey", fontSize: ".9rem", fontWeight: 400, m: "1rem 0 1rem 0" }}>{subTitle}</Typography>
      <FileUploadDropzone
        deleteFile={handleRemoveFile}
        handleNewFiles={(files) => {
          handleFileInputChange(files);
        }}
        uploadedFiles={uploadedFiles}
      />
    </Box>
  );
}

export { UploadAttachmentFormElement };
