import { Box, Stack, Typography } from "@mui/material";
import { SafeLeaseLetterAvatar } from "@safelease/components";
import { GetClaimMessageResponse } from "@safelease/service-utilities";
import dayjs from "dayjs";
import { TenantActionMessage } from "./TenantActionMessage";
import advancedFormat from "dayjs/plugin/advancedFormat";

type MessageProps = {
  messageData: GetClaimMessageResponse;
};

function formatMessageTime(createdAt: string) {
  dayjs.extend(advancedFormat);
  const now = dayjs();
  const createdAtDate = dayjs(createdAt);
  const messageSentToday: boolean = createdAtDate.isSame(now, "day");
  const messageSentYesterday: boolean = createdAtDate.isSame(now.subtract(1, "day"), "day");

  if (messageSentToday) {
    return `Today, ${createdAtDate.format("h:mm A")}`;
  } else if (messageSentYesterday) {
    return `Yesterday, ${createdAtDate.format("h:mm A")}`;
  } else {
    return createdAtDate.format("MMM Do, h:mm A");
  }
}

const MessageContent = ({ messageData }: { messageData: GetClaimMessageResponse }) => (
  <Box
    sx={{
      display: "flex",
      justifyContent: messageData.isTenantMessage ? "flex-end" : "flex-start",
    }}
  >
    <Typography
      sx={{
        fontSize: 14,
        fontWeight: 500,
        bgcolor: messageData.isTenantMessage ? "#6E82A9" : "#EBEFF7",
        color: messageData.isTenantMessage ? "white" : "black",
        padding: 2,
        borderRadius: 2,
        whiteSpace: "pre-wrap",
        maxWidth: { xs: "80vw", sm: 800 },
      }}
    >
      {messageData.content}
    </Typography>
  </Box>
);

const AvatarStyle = ({ messageData }: { messageData: GetClaimMessageResponse }) => (
  <Stack
    direction={messageData.isTenantMessage ? "row" : "row-reverse"}
    sx={{
      alignItems: "center",
      spacing: 1,
      width: "100%",
      maxWidth: 800,
      justifyContent: "space-between",
      paddingTop: 1,
    }}
  >
    <Typography fontSize={14}>{formatMessageTime(messageData.createdAt)}</Typography>
    <Stack direction="row" spacing={1}>
      <SafeLeaseLetterAvatar
        sx={{ height: 20, width: 20, fontSize: 10 }}
        name={`${messageData.userFirstName} ${messageData.userLastName}`}
      />
      <Typography fontSize={14}>{`${messageData.userFirstName} `}</Typography>
    </Stack>
  </Stack>
);

function Message({ messageData }: MessageProps) {
  const { isTenantMessage, tenantAction } = messageData;

  return (
    <Stack spacing={1.5} sx={{ alignItems: isTenantMessage ? "flex-end" : "flex-start" }}>
      <Stack>
        {tenantAction ? <TenantActionMessage messageData={messageData} /> : <MessageContent messageData={messageData} />}
        <AvatarStyle messageData={messageData} />
      </Stack>
    </Stack>
  );
}

export { Message };
