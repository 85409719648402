import { Box, Button, Container, Grid, TextField, Typography } from "@mui/material";
import { forwardRef, useState } from "react";
import { LineItemAttachment } from "./LineItemAttachment";
import { NumericFormat } from "react-number-format";
import { Controller, FieldArrayWithId, UseFieldArrayAppend, UseFieldArrayRemove, useFormContext } from "react-hook-form";
import { enqueueSnackbar } from "notistack";
import { LineItemFormData } from "./LineItemTypes";
import { ClaimsApi } from "../../../../utils/apiInstances/ClaimsApiInstance.ts";

type LineItemProps = {
  remove: UseFieldArrayRemove;
  append: UseFieldArrayAppend<LineItemFormData, "lineItems">;
  index: number;
  field: FieldArrayWithId<LineItemFormData, "lineItems", "id">;
  disableRemoveButton: boolean; // if there is only one LineItem being rendered, disable the remove button
};

function LineItem({ remove, index, field, disableRemoveButton }: LineItemProps) {
  const methods = useFormContext();
  const [showOneItemNameText, setShowOneItemNameText] = useState(false);

  const validateItemName = (value: any) => {
    if (!value) {
      enqueueSnackbar("Item name is required", { variant: "error" });
      return "Item name is required";
    }
    return true;
  };

  const onRemove = async () => {
    if (field.lineItemId) {
      try {
        await ClaimsApi.archiveLineItem(field.lineItemId);
      } catch (error) {
        enqueueSnackbar("Something went wrong when removing the line item, please try again.", {
          variant: "error",
        });
      }
    }
    remove(index);
  };

  return (
    <Container>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography sx={{ fontSize: ".8rem" }}>Item Name</Typography>
          <Controller
            name={`lineItems.${index}.name`}
            control={methods.control}
            rules={{
              validate: validateItemName,
            }}
            render={({ field, fieldState }) => (
              <TextField
                sx={{ backgroundColor: "white", borderRadius: "5px", mb: 1, mt: 1 }}
                {...field}
                placeholder="Item name"
                fullWidth
                error={!!fieldState.error}
                size="small"
                onFocus={() => setShowOneItemNameText(true)}
                onBlur={() => setShowOneItemNameText(false)}
                inputProps={{ maxLength: 255 }}
              />
            )}
          />
          {showOneItemNameText && (
            <Typography sx={{ fontSize: ".7rem", fontWeight: 400, color: "red.main" }}>
              Please ensure you include only one item in the item name field, if you need to add additional items please utilize the add
              item button below
            </Typography>
          )}
        </Grid>

        <Grid item xs={12} md={6}>
          <Typography sx={{ fontSize: ".8rem" }}>Item make & model</Typography>
          <Controller
            name={`lineItems.${index}.make`}
            control={methods.control}
            render={({ field }) => (
              <TextField
                sx={{ backgroundColor: "white", borderRadius: "5px", mb: 1, mt: 1 }}
                {...field}
                placeholder="Item make & model (if applicable)"
                fullWidth
                size="small"
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography sx={{ fontSize: ".8rem" }}>Item serial number</Typography>
          <Controller
            name={`lineItems.${index}.serialNumber`}
            control={methods.control}
            render={({ field }) => (
              <TextField
                sx={{ backgroundColor: "white", borderRadius: "5px", mb: 1, mt: 1 }}
                {...field}
                placeholder="Serial number (if applicable)"
                fullWidth
                size="small"
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography sx={{ fontSize: ".8rem" }}>Claim amount</Typography>
          <Controller
            name={`lineItems.${index}.requestedAmount`}
            control={methods.control}
            render={({ field }) => <NumericFormatWrapper {...field} />}
          />
        </Grid>
        <Grid item xs={12}>
          <LineItemAttachment
            index={index}
            fieldName="proofOfOwnershipFiles"
            field={field}
            mainLabel="Please upload"
            strongLabel="proof of ownership"
            subLabel="We will need proof that you own the item, this is typically done by showing a picture of the item in your possession in the past, owners manuals, pictures of accessories or other supporting documentation."
          />
        </Grid>
        <Grid item xs={12}>
          <LineItemAttachment
            index={index}
            fieldName="proofOfPurchaseFiles"
            field={field}
            mainLabel="Proof of"
            strongLabel="purchase"
            subLabel="We will need proof that you own the item, this is typically done by showing credit card statements, invoices, online transactions that prove you are the owner."
          />
        </Grid>
        {!disableRemoveButton && (
          <Grid item xs={12}>
            <Box>
              <Button variant="text" onClick={onRemove}>
                - Remove item
              </Button>
            </Box>
          </Grid>
        )}
      </Grid>
    </Container>
  );
}

const NumericFormatWrapper = forwardRef((props, ref) => {
  return (
    <NumericFormat
      {...props}
      allowNegative={false}
      fixedDecimalScale
      thousandSeparator=","
      prefix="$"
      decimalScale={2}
      fullWidth
      sx={{ backgroundColor: "white", borderRadius: "5px", mb: 1, mt: 1 }}
      placeholder="Claim amount"
      customInput={TextField}
      getInputRef={ref}
      size="small"
    />
  );
});
export { LineItem };
