import { SafeLeaseChip } from "@safelease/components";
import { ClaimStatus, ClosedReason } from "@safelease/service-utilities/enums";
import { TenantClaimListItem } from "@safelease/service-utilities";

type ClaimStatusChipProps = {
  claim: TenantClaimListItem;
};

const redSxProps = {
  bgcolor: "rgba(233, 100, 95, 0.2)",
  color: "rgba(233, 100, 95, 1)",
};

const greenSxProps = {
  bgcolor: "rgba(37, 155, 36, 0.1)",
  color: "rgba(37, 155, 36, 1)",
};

const yellowSxProps = {
  bgcolor: "rgba(239, 184, 6, 0.3)",
  color: "rgba(3, 30, 48, 1)",
};

const greySxProps = {
  bgcolor: "rgba(110, 130, 169, 0.2)",
  color: "rgba(3, 30, 48, 1)",
};

const blueSxProps = {
  bgcolor: "rgba(203, 223, 246, 1)",
  color: "rgba(3, 30, 48, 1)",
};

function ClaimStatusChip({ claim }: ClaimStatusChipProps) {
  const chipStyle = getChipStyleForClaim(claim);
  return <SafeLeaseChip label={chipStyle.label} sx={{ paddingY: 2, ...chipStyle.sx }} />;
}

const getChipStyleForClaim = (claim: TenantClaimListItem) => {
  if (claim.closedAt) {
    return getStyleForClosedClaim(claim.closedReason as ClosedReason);
  } else {
    if (claim.status === ClaimStatus.TenantActionNeeded || claim.hasPendingTenantActions) {
      return {
        sx: yellowSxProps,
        label: "Action required",
      };
    }

    return getStyleForOtherStatuses(claim.status as ClaimStatus);
  }
};

const getStyleForClosedClaim = (closedReason: ClosedReason) => {
  switch (closedReason) {
    case ClosedReason.Denied:
      return { sx: redSxProps, label: "Closed - Denied" };
    case ClosedReason.Settled:
      return { sx: greenSxProps, label: "Closed - Settled" };
    case ClosedReason.WithdrawnByTenant:
      return { sx: redSxProps, label: "Closed - Withdrawn" };
    case ClosedReason.Duplicate:
      return { sx: redSxProps, label: "Closed - Duplicate" };
    case ClosedReason.Incomplete:
      return { sx: redSxProps, label: "Closed - Incomplete" };
    default:
      return {
        sx: greySxProps,
        label: "N/A",
      };
  }
};

const getStyleForOtherStatuses = (status: ClaimStatus) => {
  switch (status) {
    case ClaimStatus.Incomplete:
      return { sx: yellowSxProps, label: "Incomplete Form" };
    case ClaimStatus.NewClaim:
      return { sx: blueSxProps, label: "Under review" };
    case ClaimStatus.NeedsReview:
      return { sx: blueSxProps, label: "Under review" };
    case ClaimStatus.PendingSettlement:
      return { sx: greySxProps, label: "Pending settlement" };

    default:
      return {
        sx: greySxProps,
        label: "N/A",
      };
  }
};

export { ClaimStatusChip };
