import { useState } from "react";
import { useTenantActionModalStore } from "../useTenantActionModalStore";
import { SafeLeaseButton } from "@safelease/components";
import { Stack } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { Dayjs } from "dayjs";

type DateResponseSubmissionSectionProps = {
  handleResponseSubmission: (answer: string) => Promise<void>;
  requestLoading: boolean;
};

function DateResponseSubmissionSection({ handleResponseSubmission, requestLoading }: DateResponseSubmissionSectionProps) {
  const resetStore = useTenantActionModalStore((state) => state.resetStore);
  const [userInputDate, setUserInputDate] = useState<Dayjs | null>(null);

  return (
    <>
      <DatePicker
        value={userInputDate}
        onChange={(newDate: Dayjs | null) => {
          setUserInputDate(newDate);
        }}
      />
      <Stack direction="row" justifyContent="flex-end" spacing={2}>
        <SafeLeaseButton variant="outlined" color="navy" onClick={() => resetStore()}>
          Cancel
        </SafeLeaseButton>
        <SafeLeaseButton
          loading={requestLoading}
          disabled={!userInputDate?.isValid()}
          variant="contained"
          color="navy"
          onClick={() => handleResponseSubmission(userInputDate?.format("MM/DD/YYYY")!)} // added the ! to let typescript know that userInputDate is not null we know this because the button is disabled if the userInputDate is not valid
        >
          Submit
        </SafeLeaseButton>
      </Stack>
    </>
  );
}

export { DateResponseSubmissionSection };
