import { Box, Stack, TextField } from "@mui/material";
import { SafeLeaseButton } from "@safelease/components";
import { useState } from "react";

type TextFieldWithSubmissionButtonsProps = {
  onSubmit: (content: string) => void;
  loading?: boolean;
  buttonLabel: string;
  disabled?: boolean;
};

function TextFieldWithSubmissionButtons({ onSubmit, loading, buttonLabel, disabled }: TextFieldWithSubmissionButtonsProps) {
  const [text, setText] = useState<string>("");

  return (
    <Stack>
      <TextField
        inputProps={{
          maxLength: 15000,
        }}
        placeholder="Type something..."
        multiline
        maxRows={15}
        value={text}
        onChange={(e) => setText(e.target.value)}
        sx={{ bgcolor: "white" }}
        disabled={disabled}
      />
      <Box sx={{ display: "flex", justifyContent: "flex-end", paddingY: 1 }}>
        <Stack direction="row">
          <SafeLeaseButton
            disabled={disabled}
            loading={loading}
            variant="contained"
            onClick={() => {
              onSubmit(text);
              setText("");
            }}
            sx={{ bgcolor: "#031E30" }}
          >
            {buttonLabel}
          </SafeLeaseButton>
        </Stack>
      </Box>
    </Stack>
  );
}

export { TextFieldWithSubmissionButtons };
