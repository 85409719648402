import { Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ClaimStatusChip } from "../../../shared/ClaimStatusChip";
import { SafeLeaseCard } from "@safelease/components";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { formatCurrency, shortUuid } from "@safelease/components/src/utils/formatters";
import { FolderOutlined, LocationOnOutlined, PaymentsOutlined, VpnKeyOutlined } from "@mui/icons-material";
import { cloneElement, ReactElement } from "react";
import { TenantClaimListItem } from "@safelease/service-utilities";

type ClaimDetailsCardProps = {
  claim: TenantClaimListItem;
};

function ClaimDetailsCard({ claim }: ClaimDetailsCardProps) {
  const navigate = useNavigate();

  const claimedLoss = formatCurrency(claim.requestedAmount / 100);

  const handleClaimClicked = () => {
    navigate(`/claim/${claim.id}`);
  };
  return (
    <SafeLeaseCard onClick={handleClaimClicked} clickable sx={{ boxShadow: 0 }}>
      <Stack width={300} padding={2} spacing={1}>
        <Stack direction="row" justifyContent="space-between">
          <ClaimStatusChip claim={claim} />
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography sx={{ fontSize: 12, fontWeight: 600 }}>ID: {shortUuid(claim.id)}</Typography>
            <ChevronRightIcon />
          </Stack>
        </Stack>
        <TextWithStartIcon icon={<PaymentsOutlined />} text={claimedLoss} />
        <TextWithStartIcon icon={<VpnKeyOutlined />} text={`Unit ${claim.unitName}`} />
        <TextWithStartIcon icon={<FolderOutlined />} text={claim.claimTypeName} />
        {claim.facilityAddress && <TextWithStartIcon icon={<LocationOnOutlined />} text={claim.facilityAddress} />}
      </Stack>
    </SafeLeaseCard>
  );
}

const TextWithStartIcon = ({ icon, text }: { icon: ReactElement; text: string }) => (
  <Stack direction="row" alignItems="center" spacing={0.5}>
    {cloneElement(icon, { sx: { fontSize: 16 } })}
    <Typography noWrap sx={{ fontWeight: 600, fontSize: 12, overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
      {text}
    </Typography>
  </Stack>
);

export { ClaimDetailsCard };
