import { Claim_ClaimsDbModel } from "@safelease/service-utilities";
import { QueryObserverResult, RefetchOptions } from "@tanstack/react-query";
import { create } from "zustand";

type SubmittedClaimDetailPageStore = {
  claim: Claim_ClaimsDbModel | null;
  setClaim: (claim: Claim_ClaimsDbModel | null) => void;
  refetchClaim: (options?: RefetchOptions | undefined) => Promise<QueryObserverResult<Claim_ClaimsDbModel, Error> | void>; // this will be tied to the refetch function from react-query for the selectedClaimQuery
  setRefetchClaim: (
    refetchClaim: (options?: RefetchOptions | undefined) => Promise<QueryObserverResult<Claim_ClaimsDbModel, Error> | void>,
  ) => void;
  isActionNeeded: boolean;
  setIsActionNeeded: (isActionNeeded: boolean) => void;
};

export const useSubmittedClaimDetailPageStore = create<SubmittedClaimDetailPageStore>()((set) => ({
  claim: null,
  setClaim: (claim) => {
    set({ claim });
  },
  refetchClaim: async () => {},
  setRefetchClaim: (refetchClaim) => {
    set({ refetchClaim });
  },
  isActionNeeded: false,
  setIsActionNeeded: (isActionNeeded) => {
    set({ isActionNeeded });
  },
}));
