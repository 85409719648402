import { Box, Stack, Tooltip, Typography } from "@mui/material";
import { TodoItemList } from "./ToDoItemList";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { useTodoItemListStore } from "./ToDoItemList/useTodoItemListStore";

type ToDoItemsContainerProps = {};

function ToDoItemsContainer({}: ToDoItemsContainerProps) {
  const tenantActionLength = useTodoItemListStore((state) => state.tenantActionLength);

  return (
    <Stack
      sx={{
        minWidth: 400,
        width: 400,
        paddingRight: "15px",
        height: "100%",
        flex: 1,
        overflow: "auto",
        "&::-webkit-scrollbar": {
          display: "none",
        },
        msOverflowStyle: "none",
        scrollbarWidth: "none",
      }}
      spacing={2}
      display={{ xs: "none", sm: tenantActionLength > 0 ? "flex" : "none" }}
    >
      <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
        <Typography>Tasks</Typography>
        <Tooltip title="Please complete the tasks listed here to expedite the claims process">
          <HelpOutlineIcon sx={{ color: "grey.500", fontSize: 18, marginRight: 0.5 }} />
        </Tooltip>
      </Box>
      <TodoItemList />
    </Stack>
  );
}

export default ToDoItemsContainer;
