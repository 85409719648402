import { Stack } from "@mui/material";
import { GetClaimMessageResponse } from "@safelease/service-utilities";
import { Message } from "./Message";
import { useEffect, useRef, useState } from "react";

type MessageListProps = {
  list: GetClaimMessageResponse[];
};

function MessageList({ list }: MessageListProps) {
  const messagesEndRef = useRef<null | HTMLDivElement>(null);
  // State to track if it's the first render
  const [isInitialRender, setIsInitialRender] = useState(true);

  useEffect(() => {
    const scrollToBottom = () => {
      setTimeout(() => {
        if (messagesEndRef.current) {
          if (isInitialRender) {
            // For initial render, jump to bottom without animation
            messagesEndRef.current.scrollIntoView(false);
            setIsInitialRender(false); // Mark initial render as complete
          } else {
            // For subsequent renders, use smooth scrolling
            messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
          }
        }
      }, 100); // Delaying the scroll a bit
    };
    scrollToBottom();
  }, [list, isInitialRender]); // Dependency array includes list, so effect runs on list update

  return (
    <Stack sx={{ flex: 1, overflow: "auto", wordWrap: "break-word" }} spacing={3}>
      {list.map((message) => {
        return <Message key={message.id} messageData={message} />;
      })}
      <div ref={messagesEndRef} />
    </Stack>
  );
}

export { MessageList };
