import { useState } from "react";
import { SettlementOfferTodoModal } from "./SettlementOfferTodoModal";
import { GetTenantActionsResponse } from "@safelease/service-utilities";
import { Stack, Typography } from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

type SettlementOfferTodoItemProps = {
  tenantAction: GetTenantActionsResponse;
};

function SettlementOfferTodoItem({ tenantAction }: SettlementOfferTodoItemProps) {
  const [settlementOfferTodoModalOpen, setSettlementOfferTodoModalOpen] = useState<boolean>(false);

  return (
    <>
      <Stack
        onClick={() => {
          setSettlementOfferTodoModalOpen(true);
        }}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        border={"1px solid #E5E5E5"}
        sx={{
          margin: 0.5,
          padding: 2,
          borderRadius: 2,
          bgcolor: "rgba(100, 200, 100, 0.1)",
          "&:hover": {
            cursor: "pointer",
            backgroundColor: "rgba(0, 0, 0, 0.04)",
          },
        }}
      >
        <Stack direction="row" alignItems={"center"} spacing={1} borderLeft="4px solid green">
          <Typography
            sx={{
              fontSize: 14,
              fontWeight: 500,
              paddingLeft: 2,
            }}
          >
            <strong> Review your settlement offer </strong>
          </Typography>
        </Stack>
        <ChevronRightIcon sx={{ color: "rgba(0, 0, 0, 0.56)", fontSize: 14 }} />
      </Stack>
      <SettlementOfferTodoModal
        modalOpen={settlementOfferTodoModalOpen}
        setModalOpen={setSettlementOfferTodoModalOpen}
        tenantAction={tenantAction}
      />
    </>
  );
}

export { SettlementOfferTodoItem };
