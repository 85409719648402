import { useState } from "react";
import { useTenantActionModalStore } from "../useTenantActionModalStore";
import { SafeLeaseButton } from "@safelease/components";
import { FormControlLabel, Radio, RadioGroup, Stack } from "@mui/material";

type BooleanResponseSubmissionSectionProps = {
  handleResponseSubmission: (answer: string) => Promise<void>;
  requestLoading: boolean;
};

function BooleanResponseSubmissionSection({ handleResponseSubmission, requestLoading }: BooleanResponseSubmissionSectionProps) {
  const { resetStore } = useTenantActionModalStore();
  const [userInputText, setUserInputText] = useState<string>("");

  return (
    <>
      <RadioGroup
        row
        onChange={(e) => {
          setUserInputText(e.target.value);
        }}
        value={userInputText}
      >
        <FormControlLabel
          value="Yes"
          control={
            <Radio
              sx={{
                "&.Mui-checked": {
                  color: "#031E30",
                },
              }}
            />
          }
          label="Yes"
        />
        <FormControlLabel
          value="No"
          control={
            <Radio
              sx={{
                "&.Mui-checked": {
                  color: "#031E30",
                },
              }}
            />
          }
          label="No"
        />
      </RadioGroup>
      <Stack direction="row" justifyContent="flex-end" spacing={2}>
        <SafeLeaseButton variant="outlined" color="navy" onClick={() => resetStore()}>
          Cancel
        </SafeLeaseButton>
        <SafeLeaseButton
          loading={requestLoading}
          disabled={!userInputText.trim()}
          variant="contained"
          color="navy"
          onClick={() => handleResponseSubmission(userInputText)}
        >
          Submit
        </SafeLeaseButton>
      </Stack>
    </>
  );
}

export { BooleanResponseSubmissionSection };
