import axios from "axios";

export const putFileToS3PresignedUrl = async (file: File, uploadUrl: string) => {
  const response = await axios.put(uploadUrl, file, {
    headers: {
      "Content-Type": file.type,
    },
  });
  return response;
};
