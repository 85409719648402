import { Box, Stack, Typography } from "@mui/material";
import { Controller, ControllerRenderProps, FieldArrayWithId, FieldValues, useFormContext } from "react-hook-form";
import { FileUploadDropzone } from "../../../../shared/FileUploadDropzone";
import { LineItemFormData } from "./LineItemTypes";
import { Attachment_ClaimsDbModel } from "@safelease/service-utilities";
import { ClaimsApi } from "../../../../utils/apiInstances/ClaimsApiInstance.ts";
import { enqueueSnackbar } from "notistack";
import * as Sentry from "@sentry/react";

type LineItemAttachmentProps = {
  index: number;
  fieldName: "proofOfOwnershipFiles" | "proofOfPurchaseFiles";
  field: FieldArrayWithId<LineItemFormData, "lineItems", "id">;
  mainLabel: string;
  strongLabel?: string;
  subLabel: string;
};

function LineItemAttachment({ index, fieldName, mainLabel, subLabel, strongLabel }: LineItemAttachmentProps) {
  const methods = useFormContext();

  const handleChange = (
    files: File[],
    field: ControllerRenderProps<FieldValues, `lineItems.${number}.proofOfOwnershipFiles` | `lineItems.${number}.proofOfPurchaseFiles`>,
  ) => {
    const currentFiles = methods.getValues(`lineItems.${index}.${fieldName}`);

    if (!currentFiles) {
      // We have had sentry errors where the currentFiles is undefined, this is a temporary fix to capture more data to debug the issue
      Sentry.captureMessage("hook form error message to capture debugging data", {
        level: "error",
        extra: {
          index,
          fieldName,
          files,
          currentFiles: currentFiles ?? "currentFiles is undefined",
          methods: methods ?? "methods is undefined",
        },
      });
    }

    const allFiles = [...(currentFiles || [])]; // make a new list that is a copy of the current files in state
    for (const file of files || []) {
      allFiles.push(file);
    }
    field.onChange(allFiles);
  };

  async function handleRemoveFile(
    file: File | Attachment_ClaimsDbModel,
    field: ControllerRenderProps<FieldValues, `lineItems.${number}.proofOfOwnershipFiles` | `lineItems.${number}.proofOfPurchaseFiles`>,
  ) {
    const currentFiles = methods.getValues(`lineItems.${index}.${fieldName}`);

    if (!currentFiles) {
      // We have had sentry errors where the currentFiles is undefined, this is a temporary fix to capture more data to debug the issue
      Sentry.captureMessage("hook form error message to capture debugging data", {
        level: "error",
        extra: {
          index,
          fieldName,
          file,
          currentFiles,
        },
      });
    }

    const allFiles = [...(currentFiles || [])]; // make a new list that is a copy of the current files in state
    let newFiles: (File | Attachment_ClaimsDbModel)[] = [];
    if (!(file instanceof File)) {
      try {
        await ClaimsApi.archiveDocument(file.id);
      } catch (error) {
        enqueueSnackbar("Something went wrong when removing the file, please try again.", {
          variant: "error",
        });
      }
      newFiles = allFiles.filter((f) => f.id !== file.id);
    } else {
      newFiles = allFiles.filter((f) => f.name !== file.name && f.lastModified !== file.lastModified);
    }
    field.onChange(newFiles);
  }

  return (
    <Stack spacing={2}>
      <Stack direction="row" spacing={2}>
        <Typography sx={{}}>
          {mainLabel} <strong style={{ fontWeight: "bold" }}>{strongLabel}</strong>
        </Typography>
      </Stack>
      <Box sx={{ fontSize: ".8rem", color: "grey.500" }}>{subLabel}</Box>
      <Controller
        name={`lineItems.${index}.${fieldName}`}
        control={methods.control}
        render={({ field }) => (
          <FileUploadDropzone
            containerProps={{
              border: "1px dashed rgba(3, 30, 48, 0.2)",
              borderRadius: "5px",
              bgcolor: "white",
            }}
            deleteFile={(file) => handleRemoveFile(file, field)}
            uploadedFiles={methods.watch(`lineItems.${index}.${fieldName}`)}
            handleNewFiles={(files) => {
              handleChange(files, field);
            }}
          />
        )}
      />
    </Stack>
  );
}

export { LineItemAttachment };
