import { Typography, Stack } from "@mui/material";
import { penniesToDollars } from "../../../../../utils/formatters";
import { LineItem_ClaimsDbModel } from "@safelease/service-utilities";
import ErrorIcon from "@mui/icons-material/Error";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import { DocumentCard } from "../DocumentCard";

type LineItemsSummaryProps = {
  lineItems: LineItem_ClaimsDbModel[];
};

function LineItemsSummary({ lineItems }: LineItemsSummaryProps) {
  return (
    <>
      {lineItems.map((lineItem) => {
        const proofOfOwnershipFiles = lineItem.proofOfOwnershipFiles?.filter((file) => file.attachment && !file.attachment.deletedAt);
        const proofOfPurchaseFiles = lineItem.proofOfPurchaseFiles?.filter((file) => file.attachment && !file.attachment.deletedAt);
        return (
          <Stack
            key={lineItem.id}
            sx={{
              border: "1px solid rgb(229, 229, 229, 0.9)",
              mb: 2,
              minWidth: 350,
            }}
          >
            <Stack>
              <Stack direction="column">
                {/* Title with error or checkmark Icon w/ claim ammount */}

                <Typography sx={{ m: 1, fontSize: "1.25rem", fontWeight: 700, display: "flex", alignItems: "center" }}>
                  {proofOfPurchaseFiles?.length && proofOfOwnershipFiles?.length ? (
                    <CheckCircleOutlineOutlinedIcon sx={{ color: "green.main", mr: 1 }} />
                  ) : (
                    <ErrorIcon sx={{ color: "red.main", mr: 1 }} />
                  )}
                  {lineItem.name}
                </Typography>

                <Typography sx={{ ml: 1, fontSize: ".75rem" }}>Claim amount: {penniesToDollars(lineItem.requestedAmount)}</Typography>

                {/* *** Commenting out this section until we can create the logic to go back to creation step and edit */}
                {/* <Button

                  variant="contained"
                  sx={{ bgcolor: "transparent", color: "black", border: "1px solid black", width: "50%", height: "2rem", mt: "2vh" }}
                >
                  Edit
                </Button> */}

                {/* ****Provided Files Section**** */}

                <Stack sx={{ p: 1 }}>
                  {proofOfOwnershipFiles?.length || proofOfPurchaseFiles?.length ? (
                    <Typography sx={{ fontSize: ".8rem", fontWeight: 600 }}>Provided Documents</Typography>
                  ) : null}

                  <Stack direction={{ xs: "column", md: "row" }} useFlexGap flexWrap={"wrap"}>
                    {proofOfOwnershipFiles?.map((file) => (
                      <DocumentCard key={`ownership-${file.id}`} docTitle={file?.attachment?.fileName} isProvided={true} />
                    ))}
                    {proofOfPurchaseFiles?.map((file) => (
                      <DocumentCard key={`purchase-${file.id}`} docTitle={file?.attachment?.fileName} isProvided={true} />
                    ))}
                  </Stack>
                </Stack>
              </Stack>

              {/* **** Missing Documents Section **** */}

              <Stack sx={{ p: 1 }}>
                {!proofOfPurchaseFiles?.length || !proofOfOwnershipFiles?.length ? (
                  <Typography sx={{ fontSize: ".8rem", fontWeight: 600 }}>Missing Documents</Typography>
                ) : null}

                <Stack direction={{ xs: "column", md: "row" }}>
                  {!proofOfOwnershipFiles?.length && <DocumentCard docTitle="Proof of Ownership" isProvided={false} />}
                  {!proofOfPurchaseFiles?.length && <DocumentCard docTitle="Proof of Purchase" isProvided={false} />}
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        );
      })}
    </>
  );
}
export { LineItemsSummary };
