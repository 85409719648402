import { Box, Card, LinearProgress, Stack, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { UnitSelectionModalStep, useUnitSelectionModalStore } from "../claim-portal/UnitSelectionModal/useUnitSelectionModalStore.ts";
import { LocationSearchStep } from "../claim-portal/UnitSelectionModal/LocationSearchStep";
import { EmailUnitLookupStep } from "../claim-portal/UnitSelectionModal/EmailUnitLookupStep";
import { UnitSelectionStep, UnitSelectionVerificationData } from "../claim-portal/UnitSelectionModal/UnitSelectionStep";
import { TenantNameLookupStep } from "../claim-portal/UnitSelectionModal/TenantNameLookupStep";
import { SkipLocationAndUnitMatchingModal } from "./SkipLocationAndUnitMatchingModal.tsx";
import { LocationAndUnitMatchingData } from "./PrivatePolicyForm.tsx";

type LocationAndUnitMatchingComponentProps = {
  onCompleteLocationAndUnitMatching: (data: LocationAndUnitMatchingData) => void;
};

function LocationAndUnitMatchingComponent({ onCompleteLocationAndUnitMatching }: LocationAndUnitMatchingComponentProps) {
  const { currentStep, progress, resetStore, verifySkipModalOpen } = useUnitSelectionModalStore((state) => ({
    currentStep: state.currentStep,
    progress: state.progress,
    resetStore: state.resetStore,
    verifySkipModalOpen: state.verifySkipModalOpen,
  }));

  const onCompleteUnitSelection = (data: UnitSelectionVerificationData) => {
    const unitName = data.leaseMatchingData?.find((leaseData) => leaseData.Unit.id === data.selectedUnitId)?.Unit.name;
    onCompleteLocationAndUnitMatching({
      locationId: data.selectedLocation?.locationId || null,
      address: data.selectedLocation?.address || null,
      city: data.selectedLocation?.city || null,
      state: data.selectedLocation?.state || null,
      matchType: "unitName",
      unitName: unitName || null,
      moveInDate: null,
      tenantPolicyExpirationRemindersEnabled: data.selectedLocation?.tenantPolicyExpirationRemindersEnabled || false,
    });
  };

  const getCurrentComponentContent = () => {
    switch (currentStep) {
      case UnitSelectionModalStep.FACILITY_LOCATION_SEARCH:
        return <LocationSearchStep doNotAllowSkip={true} />;
      case UnitSelectionModalStep.EMAIL_UNIT_LOOKUP:
        return <EmailUnitLookupStep />;
      case UnitSelectionModalStep.TENANT_NAME_LOOKUP:
        return <TenantNameLookupStep />;
      case UnitSelectionModalStep.UNIT_SELECTION:
        return <UnitSelectionStep onCompleteVerificationButtonClickedOverride={onCompleteUnitSelection} allowSkipUnitSelection={true} />;
    }
  };

  useEffect(() => {
    return () => {
      // anytime the component is closed it will become unmounted and this function will fire. This is to reset the store so that the next time the component is opened it will be in the initial state
      resetStore();
    };
  }, [resetStore]);

  return (
    <Card sx={{ borderRadius: 1 }}>
      <Stack spacing={1} sx={{ padding: 2 }}>
        <LinearProgress
          variant="determinate"
          value={progress}
          sx={{
            "& .MuiLinearProgress-bar": {
              backgroundColor: "navy.main",
            },
            backgroundColor: "#EBEFF7",
          }}
        />
        <Box sx={{ display: "flex", overflow: "auto", paddingY: 2, width: "100%" }}>{getCurrentComponentContent()}</Box>
        <Box sx={{ display: "flex", justifyContent: "center", paddingBottom: 2 }}>
          <Typography sx={{ fontSize: 14, fontWeight: 500 }}>Email support@safelease.com</Typography>
        </Box>
        {verifySkipModalOpen && <SkipLocationAndUnitMatchingModal onCompleteLocationAndUnitMatching={onCompleteLocationAndUnitMatching} />}
      </Stack>
    </Card>
  );
}

export { LocationAndUnitMatchingComponent };
