import { Box, Stack, Typography } from "@mui/material";
import { SafeLeaseButton } from "@safelease/components";
import { GetClaimMessageResponse } from "@safelease/service-utilities";
import { useTenantActionModalStore } from "../../../../TenantActionModal/useTenantActionModalStore";
import { TenantActionTypes } from "@safelease/service-utilities/enums";
import { ListOfUploadedFiles } from "../UploadFileMessage/ListOfUploadedFiles";

type AdHocRequestMessageProps = {
  messageData: GetClaimMessageResponse;
};

const MessageContent = ({ message }: { message: string }) => (
  <Typography sx={{ fontSize: 14, fontWeight: 500, whiteSpace: "pre-wrap", textAlign: "left", padding: 1 }}>{message}</Typography>
);

const RespondButton = ({ onClick }: { onClick: () => void }) => (
  <SafeLeaseButton variant="contained" size="small" sx={{ bgcolor: "#031E30", width: 200 }} onClick={onClick}>
    Respond
  </SafeLeaseButton>
);

function AdHocRequestMessage({ messageData }: AdHocRequestMessageProps) {
  const { setTenantActionModalOpen, setModalContentData } = useTenantActionModalStore();
  const handleRespondClick = () => {
    setModalContentData(messageData.tenantAction);
    setTenantActionModalOpen(true);
  };

  return (
    <Stack spacing={2} sx={{ bgcolor: "#EBEFF7", borderRadius: 2, padding: 2, maxWidth: { xs: "80vw", sm: 800 } }}>
      <MessageContent message={messageData.tenantAction?.metadata?.[TenantActionTypes.AD_HOC_REQUEST]?.requestMessage || ""} />

      {messageData.tenantAction?.completed ? (
        <>
          {messageData.tenantAction?.metadata?.[TenantActionTypes.AD_HOC_REQUEST]?.textResponse && (
            <Box sx={{ bgcolor: "white", borderRadius: 2, padding: 2 }}>
              <MessageContent message={messageData.tenantAction?.metadata?.[TenantActionTypes.AD_HOC_REQUEST]?.textResponse || ""} />
            </Box>
          )}
          {messageData.tenantAction.uploadedAttachments && messageData.tenantAction.uploadedAttachments.length > 0 && (
            <ListOfUploadedFiles messageData={messageData} />
          )}
        </>
      ) : (
        <RespondButton onClick={handleRespondClick} />
      )}
    </Stack>
  );
}

export { AdHocRequestMessage };
