import { FormControlLabel, Radio, RadioGroup, Typography, Stack } from "@mui/material";
import { useClaimCreationStore } from "./useClaimCreationStore";
import { useState } from "react";
import CancelIcon from "@mui/icons-material/Cancel";
import { ActionButtonsFooter } from "./ActionButtonsFooter";
import { HelpIcon } from "./HelpIcon";

type EligibilityCheckProps = {};

function EligibilityCheck({}: EligibilityCheckProps) {
  const [hasSafeleasePlan, setHasSafeleasePlan] = useState<string | null>(null);
  const creationStep = useClaimCreationStore((state) => state.creationStep);
  const setCreationStep = useClaimCreationStore((state) => state.setCreationStep);

  return (
    <>
      <Stack flex={1} margin={"1rem"}>
        <Stack direction="row">
          <Typography sx={{ fontWeight: 500, fontSize: "1.1rem" }}>Confirm you have a SafeLease plan</Typography>
          <HelpIcon helpText="You must have an active SafeLease plan to submit a claim." />
        </Stack>
        <Typography sx={{ fontSize: ".75rem", mt: "1rem" }}>Do you currently have a SafeLease plan?</Typography>
        <RadioGroup
          row
          value={hasSafeleasePlan}
          onChange={(e) => setHasSafeleasePlan(e.target.value)}
          sx={{
            mt: "0.5rem",
            ml: "0.5rem",
          }}
        >
          <FormControlLabel
            value="true"
            control={<Radio />}
            label="Yes"
            sx={{ border: "1px solid black", borderRadius: "7px", width: "6em", height: 40 }}
          />
          <FormControlLabel
            value="false"
            control={<Radio />}
            label="No"
            sx={{ border: "1px solid black", borderRadius: "7px", width: "6em", height: 40, ml: "0.5rem" }}
          />
        </RadioGroup>
        {hasSafeleasePlan === "false" && (
          <Stack direction="row" alignItems="center" marginTop="1rem">
            <CancelIcon sx={{ color: "red.main", fontSize: 45, marginRight: "10px" }} />
            <Typography sx={{ fontSize: 20 }}>
              Filing a claim is only available for tenants with SafeLease tenant protection plans.
            </Typography>
          </Stack>
        )}
      </Stack>
      <ActionButtonsFooter
        noBackButton
        disabled={hasSafeleasePlan !== "true"}
        onContinueButtonClick={() => {
          if (creationStep !== null) {
            setCreationStep(creationStep + 1);
          }
        }}
      />
    </>
  );
}

export { EligibilityCheck };
