import { Box, CircularProgress, IconButton, Modal, Paper, Stack, Theme, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";
import { useState } from "react";
import { Document, Page } from "react-pdf";
import "react-pdf/dist/esm/Page/TextLayer.css";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import { ExpandedPrivatePolicyModalControlPanel } from "./ExpandedPrivatePolicyModalControlPanel.tsx";
import { SafeleasePrivatePolicy } from "@safelease/service-utilities";
import { PrivatePolicyStatusChip } from "./PrivatePolicyStatusChip.tsx";
import { PrivatePolicyUnit } from "./PrivatePolicyUnit.tsx";
import { PrivatePolicyExpirationDate } from "./PrivatePolicyExpirationDate.tsx";
import { getCalculatedPrivatePolicyStatus } from "../utils/helpers/privatePolicy.ts";
import { AlertBanner } from "../shared/AlertBanner.tsx";

interface ExpandedPDFModalProps {
  handleClose: () => void;
  privatePolicy?: SafeleasePrivatePolicy;
  privatePolicyDocument?: {
    url: string;
    isPDF: boolean;
  } | null;
  archivePrivatePolicy: () => void;
  displayPrivatePolicyProtectionPlanCta: boolean;
  onPrivatePolicyProtectionPlanClicked: () => void;
}

export function ExpandedPrivatePolicyModal({
  privatePolicy,
  privatePolicyDocument,
  handleClose,
  archivePrivatePolicy,
  displayPrivatePolicyProtectionPlanCta,
  onPrivatePolicyProtectionPlanClicked,
}: ExpandedPDFModalProps) {
  // When a document loads successfully, set the number of pages and stop loading
  const onDocumentLoadSuccess = (numPages: number) => {
    setNumPages(numPages);
    setIsLoading(false);
  };

  // When a document fails to load, stop loading
  const onDocumentLoadFailure = () => {
    setIsLoading(false);
    setLoadFailed(true);
  };

  const file = privatePolicyDocument?.url ?? "";
  const isPDF = privatePolicyDocument?.isPDF ?? false;

  const [scale, setScale] = useState<number>(1.0);
  const [rotation, setRotation] = useState<number>(0);
  const [numPages, setNumPages] = useState<number | null>(null);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [isLoading, setIsLoading] = useState<boolean>(isPDF);
  const [loadFailed, setLoadFailed] = useState<boolean>(false);

  if (!privatePolicy) return null;

  const { reviewStatus, isEnrollmentEnabled } = getCalculatedPrivatePolicyStatus(privatePolicy);
  const isRejected = reviewStatus === "rejected";

  return (
    <Modal open={true} onClose={handleClose} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
      <Paper
        elevation={0}
        sx={{
          p: 2,
          width: 1200,
          height: "91vh",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ mb: 2, width: "100%" }}>
          <Stack direction="row" spacing={2} alignItems="center">
            <Typography variant="h5" fontWeight="500">
              Policy # {privatePolicy.policyNumber}
            </Typography>
            <PrivatePolicyStatusChip privatePolicy={privatePolicy} />
          </Stack>
          <Box sx={{ width: 40 }}>
            <IconButton onClick={handleClose}>
              <Close />
            </IconButton>
          </Box>
        </Stack>
        <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ mb: 2, width: "100%" }}>
          <Stack direction="row" spacing={2}>
            <PrivatePolicyUnit privatePolicy={privatePolicy} />
            <PrivatePolicyExpirationDate privatePolicy={privatePolicy} />
          </Stack>
        </Stack>
        <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ mb: 2, width: "100%" }}>
          {displayPrivatePolicyProtectionPlanCta && (
            <AlertBanner
              type={isRejected ? "error" : "warning"}
              message={
                isRejected ? (
                  <>
                    Your private policy for Unit {privatePolicy.unitName} got rejected. Please re-submit your policy to avoid automatic
                    enrollment in your facility’s insurance plans.{" "}
                  </>
                ) : (
                  <>
                    {`Your current deductible ${privatePolicy.policyDeductible >= 200000 ? "is" : "may be"} too high, therefore not covering your belongings. Supplement your existing private insurance
                    by covering your deductible with a SafeLease plan. `}
                    <u>
                      <strong>Enroll today</strong>
                    </u>
                  </>
                )
              }
              onClick={onPrivatePolicyProtectionPlanClicked}
            />
          )}
        </Stack>
        <Stack direction="row" sx={{ my: 1, width: "100%", borderBottom: "1px solid #e3e9f7" }} />
        <ExpandedPrivatePolicyModalControlPanel
          scale={scale}
          setScale={setScale}
          rotation={rotation}
          setRotation={setRotation}
          numPages={numPages ?? 1}
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
          file={file}
        />
        <Box
          sx={{
            mt: 2,
            border: "1px solid",
            borderRadius: 2,
            borderColor: (theme: Theme) => theme.palette.divider,
            overflow: "scroll",
            flex: 1,
            width: "100%",
          }}
        >
          {/* Loading state */}
          {isLoading && (
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress size={50} />
            </Box>
          )}

          {/* Display non-PDF */}
          {file && !isPDF && (
            <img
              src={file}
              style={{
                rotate: `${rotation}deg`,
                scale: `${scale}`,
                height: "100%",
                width: "100%",
                objectFit: "cover",
              }}
            />
          )}

          {/* Display PDF */}
          {file && isPDF && !loadFailed && (
            <Box
              sx={{
                transform: `rotate(${rotation}deg)`,
                display: "flex",
                justifyContent: "center",
                height: "100%",
              }}
            >
              <Document file={file} onLoadSuccess={({ numPages }) => onDocumentLoadSuccess(numPages)} onLoadError={onDocumentLoadFailure}>
                <Page pageNumber={pageNumber} scale={scale} />
              </Document>
            </Box>
          )}
        </Box>
        {isEnrollmentEnabled && (
          <Stack direction="row" justifyContent="center" sx={{ my: 2, width: "100%" }}>
            <Typography
              onClick={archivePrivatePolicy}
              sx={{
                color: "#e9645f",
                cursor: "pointer",
                // on hover make the red darker
                "&:hover": {
                  fontWeight: "600",
                },
              }}
            >
              Remove private policy
            </Typography>
          </Stack>
        )}
      </Paper>
    </Modal>
  );
}
