import { Box, Stack, Tooltip, Typography } from "@mui/material";
import { SafeleasePrivatePolicy } from "@safelease/service-utilities";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import dayjs from "dayjs";
import { getCalculatedPrivatePolicyStatus } from "../utils/helpers/privatePolicy.ts";

interface PrivatePolicyUnitProps {
  privatePolicy: SafeleasePrivatePolicy;
  fontSize?: number;
  hideTooltip?: boolean;
}

export function PrivatePolicyExpirationDate({ privatePolicy, fontSize = 18, hideTooltip = false }: PrivatePolicyUnitProps) {
  const { isExpired } = getCalculatedPrivatePolicyStatus(privatePolicy);

  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <Typography sx={{ fontWeight: 500, fontSize }}>
        {isExpired ? "Expired on" : "Valid until"} {dayjs(privatePolicy.expiration).format("MM/DD/YY")}
      </Typography>
      {!hideTooltip && (
        <Tooltip title={`The private policy ${!isExpired ? "has not yet" : "is"} expired`}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <ErrorOutlineIcon sx={{ color: "grey", cursor: "pointer", fontSize }} />
          </Box>
        </Tooltip>
      )}
    </Stack>
  );
}
