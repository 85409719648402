import { Box, Stack, Typography } from "@mui/material";
import safeleaseLogo from "../assets/safelease_logo.svg";
import { AvatarDropdown } from "../shared/AvatarDropdown";
import { useNavigate } from "react-router-dom";
import { signOut } from "aws-amplify/auth";

type NavbarProps = {};

function Navbar({}: NavbarProps) {
  const navigate = useNavigate();

  const logout = async () => {
    await signOut();
    navigate("/"); // need to make sure to go back to the login page or else we will show the 404 page not found when the user logs out from any page other than the home page
  };

  const dropdownOptions = [
    {
      label: "Logout",
      cb: logout,
    },
  ];

  return (
    <Box sx={{ width: "100%", display: "flex", justifyContent: "space-between" }} id="e2e-navbar">
      <Stack direction="row" alignItems="center" spacing={1}>
        <img style={{ width: "30px" }} src={safeleaseLogo} alt="Safelease logo" />
        <Typography sx={{ fontSize: 20, fontWeight: 500 }}>Tenant Portal</Typography>
      </Stack>
      <AvatarDropdown dropdownOptions={dropdownOptions} />
    </Box>
  );
}

export { Navbar };
