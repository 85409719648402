import { Typography, Stack } from "@mui/material";
import { useClaimCreationStore } from "../useClaimCreationStore";
import { Question } from "./Question";
import _ from "lodash";
import { useEffect } from "react";
import { FormQuestionDef_ClaimsDbModel, FormQuestionResponse_ClaimsDbModel } from "@safelease/service-utilities";

type FormQuestionsProps = {
  formQuestionDefs: FormQuestionDef_ClaimsDbModel[];
  initialFormQuestionResponses: FormQuestionResponse_ClaimsDbModel[];
};

function FormQuestions({ formQuestionDefs, initialFormQuestionResponses }: FormQuestionsProps) {
  const setFormQuestionResponses = useClaimCreationStore((state) => state.setFormQuestionResponses);
  const setInitialFormQuestionResponses = useClaimCreationStore((state) => state.setInitialFormQuestionResponses);
  const initialResponsesByQuestionId = _.keyBy(initialFormQuestionResponses, "formQuestionDefId");

  useEffect(() => {
    // set the initial state of the form question responses in the store
    setInitialFormQuestionResponses(formQuestionDefs, initialFormQuestionResponses);
  }, [initialFormQuestionResponses, formQuestionDefs, setInitialFormQuestionResponses]);

  return (
    <Stack spacing={2} overflow="auto" paddingX={1}>
      {formQuestionDefs.map((formQuestionDef) => {
        let initialValue = null;
        if (initialResponsesByQuestionId[formQuestionDef.id]) {
          initialValue = initialResponsesByQuestionId[formQuestionDef.id].response;
        } else if (formQuestionDef.questionType === "string") {
          initialValue = "";
        }
        return (
          <Stack key={formQuestionDef.id}>
            {formQuestionDef.isRequired && (
              <Typography
                sx={{
                  color: "red.main",
                  fontSize: 12,
                }}
              >
                * Required
              </Typography>
            )}

            <Typography sx={{ fontSize: ".9rem" }}>{formQuestionDef.question}</Typography>
            <Question
              initialValue={initialValue}
              questionType={formQuestionDef.questionType}
              setFormQuestionResponses={setFormQuestionResponses}
              formQuestionDefId={formQuestionDef.id}
            />
          </Stack>
        );
      })}
    </Stack>
  );
}

export { FormQuestions };
