import { Box, CircularProgress, Stack } from "@mui/material";
import { SafeLeaseCard } from "@safelease/components";
import { MessageList } from "./MessageList";
import { useQuery } from "@tanstack/react-query";
import { GetClaimMessageResponse } from "@safelease/service-utilities";
import { useSubmittedClaimDetailPageStore } from "../useSubmittedClaimDetailsPageStore";
import { enqueueSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useChatBoxStore } from "./useChatBoxStore";
import { ClaimsApi } from "../../../../utils/apiInstances/ClaimsApiInstance";
import { TextFieldWithSubmissionButtons } from "../../../../shared/TextFieldWithSubmissionButtons";
import { TodoItemList } from "../ToDoItemsContainer/ToDoItemList/TodoItemList";
import darkerBottomRightBackground from "../../../../assets/darkerBottomRightBackground.png";
import { useTodoItemListStore } from "../ToDoItemsContainer/ToDoItemList/useTodoItemListStore";
import { daysSince } from "../../../../utils/dateHelpers";

type ChatBoxProps = {};

function ChatBox({}: ChatBoxProps) {
  const claim = useSubmittedClaimDetailPageStore((state) => state.claim);
  const tenantActionLength = useTodoItemListStore((state) => state.tenantActionLength);
  const [sendingMessage, setSendingMessage] = useState<boolean>(false);
  const isClosed = Boolean(claim?.closedAt);
  const daysSinceClaimClosed = claim?.closedAt ? daysSince(claim.closedAt) : null;
  const messagesQuery = useQuery<GetClaimMessageResponse[]>({
    queryKey: ["getClaimMessages", claim?.id],
    queryFn: async () => await ClaimsApi.getClaimMessages(claim?.id as string),
    enabled: !!claim?.id,
  });

  // Store the refetch action for messagesQuery to be used in the UploadFileMessage component
  const setRefetchMessages = useChatBoxStore((state) => state.setRefetchMessages);
  useEffect(() => {
    setRefetchMessages(messagesQuery.refetch);

    // Optionally, reset on unmount if you don't need it anymore
    return () => setRefetchMessages(async () => {});
  }, [messagesQuery.refetch, setRefetchMessages]);

  const handleSendMessage = async (message: string) => {
    setSendingMessage(true);
    if (message.trim()) {
      try {
        await ClaimsApi.createClaimMessage({
          claimId: claim?.id as string,
          content: message.trim(),
        });
        await messagesQuery.refetch();
      } catch (error) {
        enqueueSnackbar("Error sending message", { variant: "error" });
      }
      setSendingMessage(false);
    }
  };

  return (
    <Stack width={"100%"}>
      <SafeLeaseCard
        sx={{
          flex: 1,
          boxShadow: 0,
          padding: { xs: 1, sm: 3 },
          display: "flex",
          flexDirection: "column",
          position: "relative",
        }}
      >
        {/* Background Image */}
        <Box
          component="img"
          src={darkerBottomRightBackground}
          alt="bottomRightBackgroundImage"
          sx={{
            position: "absolute",
            bottom: 0,
            right: 0,
            width: "50%",
            height: "50%",
            zIndex: 1,
            pointerEvents: "none",
          }}
        />

        {/* Content */}
        <Stack flex={1} spacing={2} overflow="auto" sx={{ position: "relative", zIndex: 2 }}>
          {!isClosed && (
            <Box sx={{ display: { xs: tenantActionLength > 0 ? "flex" : "none", sm: "none" }, mb: 2 }}>
              <TodoItemList />
            </Box>
          )}

          {messagesQuery.isLoading ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flex: 1,
              }}
            >
              <CircularProgress />
            </Box>
          ) : messagesQuery.isError ? (
            <div>Error... please try again</div>
          ) : (
            <>
              <MessageList list={messagesQuery.data || []} />
              <TextFieldWithSubmissionButtons
                loading={sendingMessage}
                onSubmit={handleSendMessage}
                buttonLabel="Send"
                disabled={daysSinceClaimClosed ? daysSinceClaimClosed > 30 : false}
              />
            </>
          )}
        </Stack>
      </SafeLeaseCard>
    </Stack>
  );
}

export { ChatBox };
