import { Box, Stack, SxProps, useMediaQuery } from "@mui/material";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import { Claim_ClaimsDbModel } from "@safelease/service-utilities";

type ChevronProgressBarProps = {
  claim: Claim_ClaimsDbModel;
  isActionNeeded?: boolean;
};
interface ProgressStepProps {
  sx?: SxProps;
  icon?: React.ReactNode;
  text: string;
}

const ProgressStep = ({ sx = {}, icon, text }: ProgressStepProps) => (
  <Box
    sx={{
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      ...sx,
    }}
  >
    {icon}
    {text}
  </Box>
);

function ChevronProgressBar({ claim, isActionNeeded }: ChevronProgressBarProps) {
  const isMobile = useMediaQuery("(max-width:600px)");
  const isClosed = Boolean(claim?.closedAt);

  return (
    <Stack direction="row" height={40} width="100%" justifyContent="center">
      <ProgressStep
        sx={{
          flex: 1,
          bgcolor: "grey.200",
          color: "grey.600",
          clipPath: "polygon(0 0, 95% 0, 100% 50%, 95% 100%, 0 100%, 0 0)",
        }}
        icon={isMobile ? null : <CheckCircleOutlinedIcon sx={{ fontSize: 18, marginRight: 1 }} />}
        text="Submission"
      />
      <ProgressStep
        sx={{
          flex: 1,
          bgcolor: isClosed ? "grey.200" : isActionNeeded ? "red.main" : "#031E30",
          color: isClosed ? "grey.600" : "white",
          clipPath: "polygon(0 0, 95% 0, 100% 50%, 95% 100%, 0 100%, 5% 50%)",
        }}
        text={isMobile ? (isActionNeeded ? "Action Req." : "In Review") : isActionNeeded ? "Action Required" : "In Review"}
        icon={isClosed && !isMobile ? <CheckCircleOutlinedIcon sx={{ fontSize: 18, marginRight: 1 }} /> : null}
      />
      <ProgressStep
        sx={{
          flex: 1,
          bgcolor: isClosed ? "#031E30" : "grey.200",
          color: isClosed ? "white" : "grey.600",
          clipPath: "polygon(0 0, 95% 0, 100% 50%, 95% 100%, 0 100%, 5% 50%)",
        }}
        text="Closed"
      />
    </Stack>
  );
}

export { ChevronProgressBar };
