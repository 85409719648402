import { GetTenantActionsResponse } from "@safelease/service-utilities";
import { create } from "zustand";

type TenantActionModalStore = {
  tenantActionModalOpen: boolean;
  setTenantActionModalOpen: (tenantActionModalOpen: boolean) => void;
  modalContentData: GetTenantActionsResponse | undefined;
  setModalContentData: (modalContentData: GetTenantActionsResponse | undefined) => void;
  resetStore: () => void;
};

export const useTenantActionModalStore = create<TenantActionModalStore>()((set) => ({
  tenantActionModalOpen: false,
  setTenantActionModalOpen: (tenantActionModalOpen) => {
    set({ tenantActionModalOpen });
  },
  modalContentData: undefined,
  setModalContentData: (modalContentData) => {
    set({ modalContentData });
  },
  resetStore: () => {
    set({ tenantActionModalOpen: false, modalContentData: undefined });
  },
}));
