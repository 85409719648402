import { Box, Stack, Typography } from "@mui/material";
import { SafeLeaseButton, SafeLeaseFormTextField } from "@safelease/components";
import { ForgotPasswordStages, useForgotPasswordPageStore } from "./useForgotPasswordPageStore";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { z } from "zod";
import { useState } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { enqueueSnackbar } from "notistack";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { FormContentType, useAuthenticationPageStore } from "../useAuthenticationPageStore";
import { resetPassword } from "aws-amplify/auth";

type EmailSubmitContentProps = {};

type ForgotPasswordFormValues = {
  email: string;
};

const validationSchema = z.object({
  email: z.string().min(1, "Email is required.").email(),
});

function EmailSubmitContent({}: EmailSubmitContentProps) {
  const [loading, setLoading] = useState<boolean>(false);
  const setCurrentFormContextType = useAuthenticationPageStore((state) => state.setCurrentFormContextType);
  const { setForgotPasswordStage, setEmail } = useForgotPasswordPageStore();

  const methods = useForm<ForgotPasswordFormValues>({
    defaultValues: {
      email: "",
    },
    resolver: zodResolver(validationSchema),
  });

  const onButtonClicked: SubmitHandler<ForgotPasswordFormValues> = async (data) => {
    setLoading(true);
    try {
      await resetPassword({ username: data.email });
      setForgotPasswordStage(ForgotPasswordStages.NEW_PASSWORD_SUBMIT);
      setEmail(data.email);
    } catch (error: any) {
      console.log(error);
      enqueueSnackbar(error?.message ?? "Something went wrong. Please try again later.", { variant: "error" });
    } finally {
      setLoading(false);
    }
  };

  return (
    <FormProvider {...methods}>
      <form style={{ height: "100%" }} onSubmit={methods.handleSubmit(onButtonClicked)}>
        <Stack justifyContent={{ sm: "space-between" }} height="100%" padding={{ xs: 2, sm: 0 }} spacing={{ xs: 3, sm: 0 }}>
          <Stack spacing={{ xs: 1, sm: 2 }}>
            <Box>
              <SafeLeaseButton
                startIcon={<ArrowBackIcon />}
                onClick={() => setCurrentFormContextType(FormContentType.LOGIN)}
                sx={{ "&:focus": { outline: "none" } }}
                color="navy"
                size="small"
              >
                Back to login
              </SafeLeaseButton>
            </Box>
            <Stack spacing={0.5}>
              <Typography sx={{ fontSize: 20, fontWeight: 500 }}>Forgot Password?</Typography>
              <Typography sx={{ fontSize: 14, color: "rgba(146, 146, 146, 1)" }}>No worries, we'll send you a reset code</Typography>
            </Stack>
            <SafeLeaseFormTextField
              label="Email"
              name="email"
              placeholder="Enter your email"
              helperText={methods.formState.errors.email?.message}
              error={!!methods.formState.errors.email?.message}
              labelProps={{
                sx: {
                  fontSize: 14,
                  fontWeight: 500,
                },
              }}
            />
          </Stack>
          <SafeLeaseButton type="submit" variant="contained" color="navy" sx={{ width: "100%" }} loading={loading}>
            Reset password
          </SafeLeaseButton>
        </Stack>
      </form>
    </FormProvider>
  );
}

export { EmailSubmitContent };
